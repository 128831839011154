import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import RegisterContact from "./Register";
import { useDisclosure } from "@chakra-ui/react";
import { LOGOUT } from "../../../Action/UserAction";
import { Contact_Query } from "../../../Model/DepartmentModel";
import { useCallback } from "react";

export default function AdminContact() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [ContactData, setContactData] = useState([]);
  const [ContactEdit, setContactEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ContactQuery, setContactQuery] = useState(Contact_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const {
    CONTACT_List_Loading,
    CONTACT_List_Error,
    total_Contacts,
    CONTACT_List,
  } = useSelector((state) => state.CONTACT_LIST_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const GET_Appointment = useCallback(() => {
    dispatch(LIST_CONTACT(ContactQuery));
  }, [dispatch, ContactQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setContactQuery({
      ...ContactQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setContactQuery({ ...ContactQuery, contact_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setContactQuery({ ...ContactQuery, contact_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setContactQuery({
        ...ContactQuery,
        contact_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (ID) => {
    setContactEdit(true);
  };

  const handleDeleteBranch = (ID) => {};

  useEffect(() => {
    setContactQuery((ContactQuery) => ({
      ...ContactQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    GET_Appointment();
  }, [ContactQuery, GET_Appointment]);

  useEffect(() => {
    if (CONTACT_List) {
      setContactData(CONTACT_List);
    } else {
      setContactData([]);
    }

    if (CONTACT_List_Error === "jwt expired") {
      dispatch(LOGOUT());
    }
  }, [CONTACT_List, CONTACT_List_Error]);

  return (
    <>
      <AdminPanelSidebar page={"CONTACT"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"CONTACT"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>Create CONTACT</button>
            </div>

            <RegisterContact
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email </th>
                      <th scope="col">Message</th>
                      <th scope="col">Query</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CONTACT_List_Loading ? <h2>Loading....</h2> : ""}

                    {CONTACT_List_Error && <h2>{CONTACT_List_Error}</h2>}

                    {ContactData?.map((el) => (
                      <tr key={el._id}>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row" className="table_container">
                          {el.Message}
                        </td>
                        <td scope="row">{el.QueryType} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Contacts ? total_Contacts / ContactQuery?.page_size : 0
              )}
              total_record={total_Contacts}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
