import {
  MEMBER_LIST_FAILED,
  MEMBER_LIST_REQ,
  MEMBER_LIST_SUCCESS,
  CREATE_MEMBER_FAILED,
  CREATE_MEMBER_REQ,
  CREATE_MEMBER_RESET,
  CREATE_MEMBER_SUCCESS,
  REMOVE_MEMBER_REQ,
  REMOVE_MEMBER_RESET,
  REMOVE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILED,
  UPDATE_MEMBER_REQ,
  UPDATE_MEMBER_RESET,
  UPDATE_MEMBER_SUCCESS,
  LOGIN_MEMBER_REQ,
  LOGIN_MEMBER_SUCCESS,
  LOGIN_MEMBER_FAILED,
  LOGIN_MEMBER_RESET,
} from "../Constant/MemberConstant";

import axiosConfig from "../Core/axiosConfig";

export const MEMBER_CREATE = (MEMBER_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_MEMBER_REQ });
    const { data } = await axiosConfig.post("/MEMBER", MEMBER_Data);
    dispatch({ type: CREATE_MEMBER_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_MEMBER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const MEMBER_LOGIN = (MEMBER_Data) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_MEMBER_REQ });
    const { data } = await axiosConfig.post("/member/memberlogin", MEMBER_Data);
    dispatch({ type: LOGIN_MEMBER_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: LOGIN_MEMBER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const MEMBER_LOGIN_RESET = () => (dispatch) => {
  dispatch({ type: LOGIN_MEMBER_RESET });
};

export const MEMBER_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_MEMBER_RESET });
};

export const LIST_MEMBER = (MemberQuery) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBER_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(
      `/Member?page_no=${MemberQuery.page_no}&page_size=${
        MemberQuery.page_size
      }&Member_name=${MemberQuery.name ? MemberQuery.name : ""}`,
      {
        headers: {
          Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
        },
      }
    );
    dispatch({ type: MEMBER_LIST_SUCCESS, payload: data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: MEMBER_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const UPDATE_MEMBER = (MEMBERData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_MEMBER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.put("/member", MEMBERData, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: UPDATE_MEMBER_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: UPDATE_MEMBER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const RESET_MEMBER_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_MEMBER_RESET });
};

export const DELETE_MEMBER = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_MEMBER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/MEMBER/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_MEMBER_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_MEMBER_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_MEMBER_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_MEMBER_RESET });
};
