import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import {
  UPDATE_USER,
  USERS_LISTS,
  USER_UPDATE_RESET,
} from "../../../Action/UserAction";
import AdminHeader from "../../../Components/AdminHeader";
import { useDisclosure, useToast } from "@chakra-ui/react";
import RegisterUser from "./RegisterUser";
import Pagination from "../../../Components/Pagination";

export default function User() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [UserData, setUserData] = useState([]);
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { User_List_Loading, User_List_Error, User_List } = useSelector(
    (state) => state.USERS_LISTS_REDUCER
  );

  const { Update_User_Loading, Update_User_Success, Update_User_Error } =
    useSelector((state) => state.UPDATE_USER_REDUCER);

  const dispatch = useDispatch();
  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };
  const handleAdminChange = (user_data) => {
    let user_update_data = user_data;
    user_update_data = { ...user_update_data, isAdmin: !user_data.isAdmin };
    dispatch(UPDATE_USER(user_update_data));
  };

  const Get_User_Lists = () => {
    dispatch(USERS_LISTS());
  };

  useEffect(() => {
    Get_User_Lists();
  }, [Update_User_Loading]);

  useEffect(() => {
    if (User_List) {
      setUserData(User_List);
    } else {
      setUserData([]);
    }
  }, [User_List, User_List_Error, User_List_Loading]);

  useEffect(() => {
    if (Update_User_Success) {
      // toast({
      //   title: "Success.",
      //   description: Update_User_Success,
      //   status: "success",
      //   duration: 3000,
      //   isClosable: true,
      // });
      onClose();
      dispatch(USER_UPDATE_RESET());
    }

    if (Update_User_Error) {
      // toast({
      //   title: "Failed.",
      //   description: Update_User_Error,
      //   status: "error",
      //   duration: 3000,
      //   isClosable: true,
      // });
      dispatch(USER_UPDATE_RESET());
    }
  }, [Update_User_Success, Update_User_Error]);

  return (
    <>
      {/* Side Panel */}
      <AdminPanelSidebar page={"Team"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        {/* Header Section*/}
        <AdminHeader
          page="USER"
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
        />

        {/* Home Section */}
        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>REGISTER USER</button>
            </div>

            <RegisterUser
              Get_User_Lists={Get_User_Lists}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Admin Status</th>
                      <th scope="col">Make Admin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {User_List_Loading ? <h2>Loading....</h2> : ""}
                    {UserData.map((el) => (
                      <tr key={el._id}>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.isAdmin ? "Yes" : "No"}</td>
                        <td scope="row">
                          <button
                            onClick={() => handleAdminChange(el)}
                            className="admin_btn">
                            {el.isAdmin ? "Deactive" : "Active"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="overview-boxes">
              <div className="box">
                <div className="right-side">
                  <div className="box-topic">Total Order</div>
                  <div className="number">40,876</div>
                  <div className="indicator">
                    <i className="bx bx-up-arrow-alt"></i>
                    <span className="text">Up from yesterday</span>
                  </div>
                </div>
                <i className="bx bx-cart-alt cart"></i>
              </div>
              <div className="box">
                <div className="right-side">
                  <div className="box-topic">Total Sales</div>
                  <div className="number">38,876</div>
                  <div className="indicator">
                    <i className="bx bx-up-arrow-alt"></i>
                    <span className="text">Up from yesterday</span>
                  </div>
                </div>
                <i className="bx bxs-cart-add cart two"></i>
              </div>
              <div className="box">
                <div className="right-side">
                  <div className="box-topic">Total Profit</div>
                  <div className="number">$12,876</div>
                  <div className="indicator">
                    <i className="bx bx-up-arrow-alt"></i>
                    <span className="text">Up from yesterday</span>
                  </div>
                </div>
                <i className="bx bx-cart cart three"></i>
              </div>
              <div className="box">
                <div className="right-side">
                  <div className="box-topic">Total Return</div>
                  <div className="number">11,086</div>
                  <div className="indicator">
                    <i className="bx bx-down-arrow-alt down"></i>
                    <span className="text">Down From Today</span>
                  </div>
                </div>
                <i className="bx bxs-cart-download cart four"></i>
              </div>
        </div>*/}

            {/*<div className="sales-boxes">
              <div className="recent-sales box">
                <div className="title">Recent Sales</div>
                <div className="sales-details">
                  <ul className="details">
                    <li className="topic">Date</li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                    <li>
                      <a href="#">02 Jan 2021</a>
                    </li>
                  </ul>
                  <ul className="details">
                    <li className="topic">Customer</li>
                    <li>
                      <a href="#">Alex Doe</a>
                    </li>
                    <li>
                      <a href="#">David Mart</a>
                    </li>
                    <li>
                      <a href="#">Roe Parter</a>
                    </li>
                    <li>
                      <a href="#">Diana Penty</a>
                    </li>
                    <li>
                      <a href="#">Martin Paw</a>
                    </li>
                    <li>
                      <a href="#">Doe Alex</a>
                    </li>
                    <li>
                      <a href="#">Aiana Lexa</a>
                    </li>
                    <li>
                      <a href="#">Rexel Mags</a>
                    </li>
                    <li>
                      <a href="#">Tiana Loths</a>
                    </li>
                  </ul>
                  <ul className="details">
                    <li className="topic">Sales</li>
                    <li>
                      <a href="#">Delivered</a>
                    </li>
                    <li>
                      <a href="#">Pending</a>
                    </li>
                    <li>
                      <a href="#">Returned</a>
                    </li>
                    <li>
                      <a href="#">Delivered</a>
                    </li>
                    <li>
                      <a href="#">Pending</a>
                    </li>
                    <li>
                      <a href="#">Returned</a>
                    </li>
                    <li>
                      <a href="#">Delivered</a>
                    </li>
                    <li>
                      <a href="#">Pending</a>
                    </li>
                    <li>
                      <a href="#">Delivered</a>
                    </li>
                  </ul>
                  <ul className="details">
                    <li className="topic">Total</li>
                    <li>
                      <a href="#">$204.98</a>
                    </li>
                    <li>
                      <a href="#">$24.55</a>
                    </li>
                    <li>
                      <a href="#">$25.88</a>
                    </li>
                    <li>
                      <a href="#">$170.66</a>
                    </li>
                    <li>
                      <a href="#">$56.56</a>
                    </li>
                    <li>
                      <a href="#">$44.95</a>
                    </li>
                    <li>
                      <a href="#">$67.33</a>
                    </li>
                    <li>
                      <a href="#">$23.53</a>
                    </li>
                    <li>
                      <a href="#">$46.52</a>
                    </li>
                  </ul>
                </div>
                <div className="button">
                  <a href="#">See All</a>
                </div>
              </div>
              <div className="top-sales box">
                <div className="title">Top Seling Product</div>
                <ul className="top-sales-details">
                  <li>
                    <a href="#">
                      <img src="images/sunglasses.jpg" alt="" />
                      <span className="product">Vuitton Sunglasses</span>
                    </a>
                    <span className="price">$1107</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/jeans.jpg" alt="" />
                      <span className="product">Hourglass Jeans </span>
                    </a>
                    <span className="price">$1567</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/nike-min.jpg" alt="" />
                      <span className="product">Nike Sport Shoe</span>
                    </a>
                    <span className="price">$1234</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/scarves.jpg" alt="" />
                      <span className="product">Hermes Silk Scarves.</span>
                    </a>
                    <span className="price">$2312</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/blueBag.jpg" alt="" />
                      <span className="product">Succi Ladies Bag</span>
                    </a>
                    <span className="price">$1456</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/bag.jpg" alt="" />
                      <span className="product">Gucci Womens's Bags</span>
                    </a>
                    <span className="price">$2345</span>
                  </li>

                  <li>
                    <a href="#">
                      <img src="images/addidas.jpg" alt="" />
                      <span className="product">Addidas Running Shoe</span>
                    </a>
                    <span className="price">$2345</span>
                  </li>
                  <li>
                    <a href="#">
                      <img src="images/shirt.jpg" alt="" />
                      <span className="product">Bilack Wear's Shirt</span>
                    </a>
                    <span className="price">$1245</span>
                  </li>
                </ul>
              </div>
      </div>*/}
            <Pagination />
          </div>
        }
      </section>{" "}
    </>
  );
}
