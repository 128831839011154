import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";

import { useDisclosure } from "@chakra-ui/react";
import {
  LIST_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from "../../../Action/AppointmentAction";
import { useCallback } from "react";
import { Appointment_Query } from "../../../Model/DepartmentModel";
import { LIST_REFERAL } from "../../../Action/ReferalAction";
import { useParams } from "react-router-dom";
import MemberAppointment from "./CreateMemberApponitment";

export default function MemberReferal() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [AppointmentData, setAppointmentData] = useState([]);
  const [AppointmentEdit, setAppointmentEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [AppointmentQuery, setAppointmentQuery] = useState(Appointment_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const {
    Referal_List_Loading,
    total_Referals,
    Referal_List_Error,
    Referal_List,
  } = useSelector((state) => state.REFERAL_LIST_REDUCER);
  const dispatch = useDispatch();
  const params = useParams();
  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const GET_Appointment = useCallback(() => {
    dispatch(LIST_REFERAL(params.memberId));
  }, [dispatch]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setAppointmentQuery({
      ...AppointmentQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setAppointmentQuery({ ...AppointmentQuery, Appointment_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setAppointmentQuery({ ...AppointmentQuery, Appointment_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setAppointmentQuery({
        ...AppointmentQuery,
        Appointment_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (ID) => {
    setAppointmentEdit(true);
  };

  const handleDeleteBranch = (ID) => {};

  useEffect(() => {
    setAppointmentQuery((AppointmentQuery) => ({
      ...AppointmentQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    GET_Appointment();
  }, [AppointmentQuery, GET_Appointment]);

  useEffect(() => {
    if (Referal_List) {
      setAppointmentData(Referal_List);
    } else {
      setAppointmentData([]);
    }

    if (Referal_List_Error) {
      setAlert({ Error: Referal_List_Error, validator: false });
    }
  }, [Referal_List, Referal_List_Error]);

  return (
    <>
      <AdminPanelSidebar
        page={"MEMBER REFERAL"}
        SidebarOpener={SidebarOpener}
      />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"REFERAL"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>CREATE APPOINTMENT</button>
            </div>

            <MemberAppointment
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  style={{
                    whiteSpace: "nowrap",
                    width: "90%",
                    overflow: "scroll",
                  }}
                  className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email </th>
                      <th scope="col">Age </th>
                      <th scope="col">Doctor</th>
                      <th scope="col">Test</th>
                      <th scope="col">Refer by</th>
                      <th scope="col">Time</th>
                      <th scope="col">PromoCode</th>
                      <th scope="col">Actual Price</th>
                      <th scope="col">Test Price</th>
                      <th scope="col">Message</th>
                      <th scope="col">Member Price</th>
                      <th scope="col"> Refer Confirm </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Referal_List_Loading ? <p>Loading....</p> : ""}
                    {Referal_List_Error && <h2>{Referal_List_Error}</h2>}
                    {AppointmentData?.map((el) => (
                      <tr key={el._id}>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.Age}</td>
                        <td scope="row">{el.Doctor?.Name}</td>
                        <td scope="row">
                          {el.Department?.map((el) => (
                            <span>
                              {el.TestName}
                              {" ,"}
                            </span>
                          ))}
                        </td>
                        <td scope="row">
                          {el.ReferId?.MemberId
                            ? el.ReferId?.MemberId
                            : "No Refer.."}{" "}
                        </td>
                        <td scope="row">{el.Time} </td>
                        <th scope="col">
                          {el.PromoCode ? el.PromoCode : "No PromoCode"}
                        </th>
                        <td scope="row">₹ {el.TotalPrice} </td>
                        <td scope="row">₹ {el.TestPrice} </td>
                        <td scope="row">{el.Message} </td>
                        <td
                          scope="row"
                          style={
                            el.ReferPayment
                              ? { color: "green" }
                              : { color: "red" }
                          }>
                          ₹ {el.isMember ? el.MemberPrice : "N/A"}{" "}
                        </td>
                        <td
                          scope="row"
                          onClick={() =>
                            dispatch(
                              UPDATE_APPOINTMENT({
                                ...el,
                                ReferPayment: !el.ReferPayment,
                              })
                            )
                          }>
                          <button
                            style={
                              el.ReferPayment
                                ? {
                                    padding: "10px",
                                    background: "red",
                                    borderRadius: "6px",
                                    color: "white",
                                  }
                                : {
                                    padding: "10px",
                                    background: "green",
                                    borderRadius: "6px",
                                    color: "white",
                                  }
                            }
                            disabled={!el.ReferId}>
                            {!el.ReferId
                              ? "No Refer"
                              : el.ReferPayment
                              ? "STOP PAYMENT"
                              : "CONFIRM PAYMENT"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Referals
                  ? total_Referals / AppointmentQuery?.page_size
                  : 0
              )}
              total_record={total_Referals}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
