export const CREATE_PAYOUT_REQ = "CREATE_PAYOUT_REQ";
export const CREATE_PAYOUT_SUCCESS = "CREATE_PAYOUT_SUCCESS";
export const CREATE_PAYOUT_FAILED = "CREATE_PAYOUT_FAILED";
export const CREATE_PAYOUT_RESET = "CREATE_PAYOUT_RESET";

export const UPDATE_PAYOUT_REQ = "UPDATE_PAYOUT_REQ";
export const UPDATE_PAYOUT_SUCCESS = "UPDATE_PAYOUT_SUCCESS";
export const UPDATE_PAYOUT_FAILED = "UPDATE_PAYOUT_FAILED";
export const UPDATE_PAYOUT_RESET = "UPDATE_PAYOUT_RESET";

export const PAYOUT_LIST_REQ = "PAYOUT_LIST_REQ";
export const PAYOUT_LIST_SUCCESS = "PAYOUT_LIST_SUCCESS";
export const PAYOUT_LIST_FAILED = "PAYOUT_LIST_FAILED";
export const PAYOUT_LIST_RESET = "PAYOUT_LIST_RESET";

export const SINGLE_PAYOUT_REQ = "SINGLE_PAYOUT_REQ";
export const SINGLE_PAYOUT_SUCCESS = "SINGLE_PAYOUT_SUCCESS";
export const SINGLE_PAYOUT_FAILED = "SINGLE_PAYOUT_FAILED";
export const SINGLE_PAYOUT_RESET = "SINGLE_PAYOUT_RESET";

export const REMOVE_PAYOUT_REQ = "REMOVE_PAYOUT_REQ";
export const REMOVE_PAYOUT_SUCCESS = "REMOVE_PAYOUT_SUCCESS";
export const REMOVE_PAYOUT_FAILED = "REMOVE_PAYOUT_FAILED";
export const REMOVE_PAYOUT_RESET = "REMOVE_PAYOUT_RESET";

export const LOGIN_PAYOUT_REQ = "LOGIN_PAYOUT_REQ";
export const LOGIN_PAYOUT_SUCCESS = "LOGIN_PAYOUT_SUCCESS";
export const LOGIN_PAYOUT_FAILED = "LOGIN_PAYOUT_FAILED";
export const LOGIN_PAYOUT_RESET = "LOGIN_PAYOUT_RESET";
