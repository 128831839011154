import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { REGISTER_USER, REGISTER_USER_RESET } from "../../../Action/UserAction";
import {
  DEPARTMENT_CREATE,
  DEPARTMENT_CREATE_RESET,
  RESET_DEPARTMENT_UPDATE,
  UPDATE_DEPARTMENT,
} from "../../../Action/DepartmentAction";
import { LIST_MEMBER } from "../../../Action/MemberAction";

export default function RegisterPayOut({
  Get_Department,
  isOpen,
  onOpen,
  onClose,
  DepartmentEdit,
  setDepartmentEdit,
}) {
  const [PayOutForm, setPayOutForm] = useState({
    MemberId: "",
    UpiId: "",
    AccountDet: "",
    PayOutStats: false,
    PayOutAmount: "",
  });
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const toast = useToast();

  const {
    Create_Department_Loading,
    Create_Department_Success,
    Create_Department_Error,
  } = useSelector((state) => state.DEPARTMENT_CREATE_REDUCER);
  const { Member_List_Loading, Member_List_Error, total_Members, Member_List } =
    useSelector((state) => state.MEMBER_LIST_REDUCER);
  const {
    Update_Department_Loading,
    Update_Department_Error,
    Update_Department_Success,
  } = useSelector((state) => state.DEPARTMENT_UPDATE_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setPayOutForm({
      ...PayOutForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(DEPARTMENT_CREATE(PayOutForm));
  };

  const handleDepartmentSubmit = (e) => {
    e.preventDefault();
    dispatch(UPDATE_DEPARTMENT(PayOutForm));
  };

  useEffect(() => {
    if (Create_Department_Success) {
      toast({
        title: "Success.",
        description: Create_Department_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Department();
      dispatch(DEPARTMENT_CREATE_RESET());
      setPayOutForm({
        TestName: "",
        TestPrice: "",
        TestDiscount: "",
        TestMemberDiscount: "",
      });
    }

    if (Create_Department_Error) {
      toast({
        title: "Failed.",
        description: Create_Department_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(DEPARTMENT_CREATE_RESET());
    }
  }, [Create_Department_Success, Create_Department_Error]);

  useEffect(() => {
    if (DepartmentEdit) {
      setPayOutForm({ ...DepartmentEdit });
    }
  }, [DepartmentEdit]);

  useEffect(() => {
    if (Update_Department_Success) {
      toast({
        title: "Success.",
        description: Update_Department_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Department();
      dispatch(RESET_DEPARTMENT_UPDATE());
      setDepartmentEdit({});
    }
    if (Update_Department_Error) {
      toast({
        title: "Failed.",
        description: Update_Department_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(RESET_DEPARTMENT_UPDATE());
    }
  }, [Update_Department_Error, Update_Department_Success]);

  useEffect(() => {
    dispatch(LIST_MEMBER({ page_no: "", page_size: "", Member_name: "" }));
  }, [dispatch]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();

          setDepartmentEdit({});
        }}>
        <ModalOverlay />

        <ModalContent>
          <span
            onClick={() => {
              onClose();

              setDepartmentEdit({});
            }}
            className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>{DepartmentEdit ? "EDIT PAYOUT" : "CREATE PAYOUT"}</h1>
                </div>
                <div className="form">
                  <form
                    onSubmit={
                      DepartmentEdit ? handleDepartmentSubmit : handleUserSubmit
                    }>
                    <select
                      style={{
                        width: "100%",
                        padding: "10px",
                        border: "1px solid grey",
                      }}
                      name="MemberId"
                      value={PayOutForm.MemberId}
                      onChange={handleFormChange}>
                      <option value={""}>select Member</option>
                      {Member_List?.map((el) => (
                        <option key={el._id} value={el._id}>
                          {el.MemberName + " " + el.MemberId}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      name="UpiId"
                      placeholder="Enter Upi Id"
                      value={PayOutForm.UpiId}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="text"
                      name="AccountDet"
                      placeholder="Enter Account Details "
                      value={PayOutForm.AccountDet}
                      onChange={handleFormChange}
                      id="AccountDet"
                      required
                    />

                    {DepartmentEdit ? (
                      <button type="submit">
                        {Update_Department_Loading ? "Loading..." : "UPDATE"}
                      </button>
                    ) : (
                      <button type="submit">
                        {Create_Department_Loading ? "Loading..." : "CREATE"}
                      </button>
                    )}
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
