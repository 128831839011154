// //   </section>

//       <section id="id-wrapper">
//         <div id="id-card" class="card">
//           <img class="logo" src="SMAN-Logo.png" width="70" />

//           <div class="container">
//             <p class="lead bold">STINGY MEN ASSOCIATION OF NIGERIA</p>

//             <p class="crimson">Motto: Let Me See What I Can Do</p>

//             <p class="bold">IDENTITY CARD</p>
//           </div>

//           <img class="stamp" src="stamp.jpg" />

//           <div class="box">
//             <img width="90" height="100" class="chip" src="chip.png" alt="" />

//             <div class="details">
//               <p>
//                 Name: <span class="mg-left" id="id-card-name">ADEBAYO ADE</span>
//               </p>

//               <p>Position: <span id="id-position"></span></p>

//               <p>Branch: <span id="id-branch"></span></p>

//               <p>ID No: <span id="id-no"></span></p>
//             </div>

//             <div class="mugshot">
//               <img
//                 id="id-card-mugshot"
//                 width="130"
//                 height="130"
//                 class="mugshot-img"
//                 src="empty-avatar.png"
//                 alt="id-image"
//               />

//               <img width="130" height="20" src="barcode1.png" />
//             </div>
//           </div>

//           <footer>
//             <p class="sky">OBTAINABLE FROM WWW.ADEBAYOADE.COM/SMAN</p>
//           </footer>
//         </div>
//       </section>

import { useEffect, useRef, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_LOCAL, REMOVE_LOCAL } from "../../Model/LocalData";
import html2canvas from "html2canvas";
import { MEMBER_LOGIN_RESET } from "../../Action/MemberAction";
import {
  APPOINTMENT_CREATE,
  APPOINTMENT_CREATE_RESET,
} from "../../Action/AppointmentAction";
import { LIST_DEPARTMENT } from "../../Action/DepartmentAction";
import { LIST_DOCTOR } from "../../Action/DoctorAction";
import ReferTable from "./ReferTable";
import Multiselect from "multiselect-react-dropdown";
import { useToast } from "@chakra-ui/react";
import {
  LIST_PAYOUT,
  PAYOUT_CREATE,
  PAYOUT_CREATE_RESET,
} from "../../Action/PayOutAction";

export default function MemberLoginPage() {
  const { Login_Member } = useSelector((state) => state.MEMBER_LOGIN_REDUCER);
  const [PayOutForm, setPayOutForm] = useState({
    MemberId: Login_Member._id,
    UpiId: "",
    AccountDet: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [AppointmentForm, setAppointmentForm] = useState({
    Name: "",
    Age: "",
    Doctor: "",
    Time: "",
    isMember: true,
    Phone: "",
    Department: [],
    ReferId: Login_Member._id,
    Message: "",
    PromoCode: "",
  });
  const [SelectTest, setSelectTest] = useState([]);
  const handleFormChange = (e) => {
    setAppointmentForm({ ...AppointmentForm, [e.target.name]: e.target.value });
  };
  const multiselectRefTracker = useRef();
  const toast = useToast();
  const {
    Referal_List_Loading,
    total_Referals,
    Referal_List_Error,
    Referal_List,
  } = useSelector((state) => state.REFERAL_LIST_REDUCER);

  const { PAYOUT_List_Loading, PAYOUT_List, PAYOUT_List_Error } = useSelector(
    (state) => state.PAYOUT_LIST_REDUCER
  );
  const { Doctor_List } = useSelector((state) => state.DOCTOR_LIST_REDUCER);
  const { Department_List } = useSelector(
    (state) => state.DEPARTMENT_LIST_REDUCER
  );
  const { Create_PAYOUT_Loading, Create_PAYOUT_Success, Create_PAYOUT_Error } =
    useSelector((state) => state.PAYOUT_CREATE_REDUCER);
  const {
    Create_APPOINTMENT_Loading,
    Create_APPOINTMENT_Success,
    Create_APPOINTMENT_Error,
  } = useSelector((state) => state.APPOINTMENT_CREATE_REDUCER);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (AppointmentForm.Name == "" || AppointmentForm.Phone === "") {
      toast({
        title: "Failed.",
        description: "Name & Phone number required to book!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      if (AppointmentForm.Doctor === "") {
        AppointmentForm.Doctor = null;
      }
      let TotalPrice = 0;
      if (SelectTest.length) {
        SelectTest.map((el) => (TotalPrice += TotalPrice + el?.TestPrice));
      }
      dispatch(
        APPOINTMENT_CREATE({
          ...AppointmentForm,
          Department: SelectTest,
          TotalPrice,
        })
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (Create_APPOINTMENT_Success) {
      toast({
        title: "Success",
        description: Create_APPOINTMENT_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch(APPOINTMENT_CREATE_RESET());
      setAppointmentForm({
        Name: "",
        Age: "",
        Doctor: "",
        Time: "",
        Phone: "",
        isMember: true,
        ReferId: Login_Member._id,
        Department: [],
        Message: "",
        PromoCode: "",
      });
    }

    if (Create_APPOINTMENT_Error) {
      toast({
        title: "Failed.",
        description: Create_APPOINTMENT_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(APPOINTMENT_CREATE_RESET());
    }
  }, [Create_APPOINTMENT_Success, Create_APPOINTMENT_Error]);

  const handlePayOut = (e) => {
    e.preventDefault();
    if (
      (PayOutForm.AccountDet === "" || PayOutForm.AccountDet === null) &&
      (PayOutForm.UpiId === "" || PayOutForm.UpiId === null)
    ) {
      toast({
        title: "Failed.",
        description: "Account details or Upi id required !",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      let Price = 0;
      Referal_List.forEach((el) => (Price += el?.MemberPrice));
      if (!Referal_List) {
        toast({
          title: "Failed.",
          description: "You Have not Refer to claim amount add refer !",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (Price >= 100) {
        dispatch(
          PAYOUT_CREATE({
            ...PayOutForm,
            PayOutStatus: false,
            PayOutAmount: Price,
          })
        );
      } else {
        toast({
          title: "Failed.",
          description: "Account Minimum 100 balance required to withdraw !",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };
  useEffect(() => {
    dispatch(LIST_DOCTOR({ page_no: "", page_size: "", Doctor_name: "" }));
    dispatch(
      LIST_DEPARTMENT({ page_no: "", page_size: "", Department_name: "" })
    );
    dispatch(LIST_PAYOUT(Login_Member._id));
  }, [dispatch]);

  function setUpDownloadPageAsImage() {
    const MyCard = document.getElementById("download-page-as-image");
    html2canvas(MyCard).then(function (canvas) {
      console.log(canvas);
      simulateDownloadImageClick(canvas.toDataURL(), `photon-health-card.png`);
    });
  }

  function simulateDownloadImageClick(uri, filename) {
    var link = document.createElement("a");
    if (typeof link.download !== "string") {
      window.open(uri);
    } else {
      link.href = uri;
      link.download = filename;
      accountForFirefox(clickLink, link);
    }
  }

  function clickLink(link) {
    link.click();
  }

  function accountForFirefox(click) {
    // wrapper function
    let link = arguments[1];
    document.body.appendChild(link);
    click(link);
    document.body.removeChild(link);
  }

  useEffect(() => {
    if (Create_PAYOUT_Success) {
      toast({
        title: "Success.",
        description: Create_PAYOUT_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(PAYOUT_CREATE_RESET());
        setPayOutForm({
          MemberId: Login_Member._id,
          UpiId: "",
          AccountDet: "",
        });
      }, 4000);
    }

    if (Create_PAYOUT_Error) {
      toast({
        title: "Error.",
        description: Create_PAYOUT_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(PAYOUT_CREATE_RESET());
      }, 4000);
    }
  }, [Create_PAYOUT_Success, Create_PAYOUT_Error]);

  return (
    <>
      <Header />
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white"> Avail your offer</span>
                <h1 className="text-capitalize mb-5 text-lg">MEMBER </h1>

                {/*<!-- <ul className="list-inline breadcumb-nav">
            <li className="list-inline-item"><a href="index.html" className="text-white">Home</a></li>
            <li className="list-inline-item"><span className="text-white">/</span></li>
            <li className="list-inline-item"><a href="#" className="text-white-50">Contact Us</a></li>
          </ul> -->*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- contact form start -->*/}

      <section id="id-wrapper">
        <button
          style={{
            borderRadius: "2px",
            padding: "5px 10px",
            backgroundColor: "red",
            color: "white",
            margin: "20px",
          }}
          onClick={() => {
            REMOVE_LOCAL("member");
            dispatch(MEMBER_LOGIN_RESET());
            setTimeout(() => {
              navigate("/member");
            }, 1000);
          }}>
          Logout
        </button>

        <button
          style={{
            borderRadius: "2px",
            padding: "5px 10px",
            backgroundColor: "green",
            color: "white",
            margin: "20px",
          }}
          onClick={setUpDownloadPageAsImage}>
          DOWNLOAD HEALTH CARD
        </button>

        <div id="download-page-as-image" className="Id_Card">
          <div className="card_Id">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <img
                style={{ height: "50px" }}
                src="../assets/Logo.png"
                alt="logo"
              />
              <h6>PHOTON DIAGNOSTICS AND RESEARCH CENTER</h6>
            </div>
            <p style={{ textAlign: "center", margin: "0", padding: "0" }}>
              Truly Trusted Tests And Treatment
            </p>
            <p style={{ textAlign: "center" }}>PHOTON HEALTH CARD</p>

            <div style={{ marginTop: "20px" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  marginLeft: "10px",
                  fontSize: "24px",
                  marginBottom: "10px",
                }}>
                <span>
                  <FaUserCircle />
                </span>
                <span>{Login_Member?.MemberName}</span>
              </div>

              <p
                style={{
                  padding: "0",
                  margin: "0",
                  marginLeft: "20px",
                }}>
                Phone: {Login_Member?.Phone}
              </p>
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  marginLeft: "21px",
                }}>
                ID NO: {Login_Member?.MemberId}
              </p>
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  marginLeft: "21px",
                }}>
                Member Exp Date: {Login_Member?.MemberExpiryDate}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0",
                padding: "0",
                color: "white",
                marginTop: "20px",
                fontSize: "12px",
              }}>
              <span>
                ID CARD VALID IN{" "}
                <Link to="/member"> www.photondiagnostics.in</Link>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "auto",
          gap: "20px",
          marginTop: "20px",
        }}>
        <div>
          <input
            onChange={(e) =>
              setPayOutForm({ ...PayOutForm, AccountDet: e.target.value })
            }
            style={{
              border: "0.1px solid grey",
              borderRadius: "5px",
              padding: "3px",
              marginRight: "10px",
            }}
            type="text"
            placeholder="Account details"
          />
          <span style={{ padding: "0px 20px" }}>OR</span>
          <input
            onChange={(e) =>
              setPayOutForm({ ...PayOutForm, UpiId: e.target.value })
            }
            style={{
              border: "0.1px solid grey",
              borderRadius: "5px",
              padding: "3px",
            }}
            type="text"
            placeholder="UPI ID"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}>
            {" "}
            <a
              class="btn btn-main btn-round-full"
              disabled={Create_PAYOUT_Loading}
              onClick={handlePayOut}>
              {Create_PAYOUT_Loading ? "LOADING... " : " Withraw Amount"}
              <i class="icofont-simple-right ml-2"></i>
            </a>{" "}
          </div>
        </div>
      </section>

      <section>
        <div class="container">
          <ReferTable
            Referal_List_Loading={Referal_List_Loading}
            total_Referals={total_Referals}
            Referal_List_Error={Referal_List_Error}
            Referal_List={Referal_List}
            memberId={Login_Member._id}
            PAYOUT_List={PAYOUT_List}
          />
        </div>
      </section>
      <section
        style={{ display: "flex", padding: "10px", justifyContent: "center" }}>
        <div class="container">
          <div class="row">
            <div class="">
              <div class="appoinment-wrap mt-5 mt-lg-0 pl-lg-5">
                <h2
                  class="mb-2 title-color"
                  style={{ textAlign: "center", marginTop: "40px" }}>
                  Refer a Patient to Our Clinic
                </h2>
                <p class="mb-4">
                  Welcome to our online appointment booking system! We strive to
                  make scheduling your visit as convenient as possible. Whether
                  you need a routine check-up, specialized medical consultation,
                  or any of our other services, booking an appointment has never
                  been easier.
                </p>
                <form id="#" class="appoinment-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <Multiselect
                          options={Department_List}
                          placeholder="Select Test"
                          displayValue="TestName"
                          multiselectRefTracker={multiselectRefTracker}
                          onSelect={(e) => {
                            setSelectTest(e);
                          }}
                          onRemove={(e) => {
                            setSelectTest(e);
                          }}
                          showCheckbox={true}
                          // selectedValues={PreSeSelectedCampuses}
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <select
                          class="form-control"
                          name="Doctor"
                          value={AppointmentForm.Doctor}
                          onChange={handleFormChange}
                          id="exampleFormControlSelect2">
                          <option>Select Doctors</option>
                          {Doctor_List?.map((el) => (
                            <option value={el._id} key={el._id}>
                              {el.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Name"
                          value={AppointmentForm.Name}
                          onChange={handleFormChange}
                          id="name"
                          type="text"
                          class="form-control"
                          placeholder="Full Name"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          type="datetime-local"
                          class="form-control"
                          name="Time"
                          placeholder="select date and time"
                          value={AppointmentForm.Time}
                          onChange={handleFormChange}
                          id="date"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Age"
                          value={AppointmentForm.Age}
                          onChange={handleFormChange}
                          id="time"
                          type="text"
                          class="form-control"
                          placeholder="age"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Phone"
                          value={AppointmentForm.Phone}
                          onChange={handleFormChange}
                          id="phone"
                          type="Number"
                          class="form-control"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="PromoCode"
                          value={AppointmentForm.PromoCode}
                          onChange={handleFormChange}
                          id="phone"
                          type="text"
                          class="form-control"
                          placeholder="Promo Code"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group-2 mb-4">
                    <textarea
                      name="Message"
                      value={AppointmentForm.Message}
                      onChange={handleFormChange}
                      id="message"
                      class="form-control"
                      rows="6"
                      placeholder="Your Message"></textarea>
                  </div>

                  <a
                    class="btn btn-main btn-round-full"
                    onClick={handleFormSubmit}>
                    {Create_APPOINTMENT_Loading
                      ? "Loading..."
                      : "Make Appointment"}
                    <i class="icofont-simple-right ml-2"></i>
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
