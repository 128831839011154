export const Register_User_Model = {
  Name: "",
  Email: "",
  Phone: "",
  Password: "",
};

export const Update_User_Model = {
  Name: "",
  Email: "",
  Phone: "",
};

export const Register_Movie_Model = {
  MovieName: "",
  MovieUrl: "",
  Poster: "",
  MovieHeading: "",
  Year: "",
  Rating: "",
  Desc: [],
  Key: "",
  Trailer: "",
};
