import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LIST_DEPARTMENT } from "../../Action/DepartmentAction";
export default function Offer() {
  const { Department_List_Loading, Department_List, Department_List_Error } =
    useSelector((state) => state.DEPARTMENT_LIST_REDUCER);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      LIST_DEPARTMENT({ page_no: "", page_size: "", Department_name: "" })
    );
  }, [dispatch]);

  console.log(Department_List_Error);

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <>
      <Header />
      <section className="Offer_Tale PriceTable">
        {Department_List_Error && <h1>No Offer available....</h1>}

        {Department_List_Loading && (
          <h1 style={{ textAlign: "center" }}>Offers Loading...</h1>
        )}

        {Department_List?.map((el) => (
          <div class="card" key={el._id}>
            <h6 class="type">Awesome</h6>
            <div class="price">
              <span>₹ Rs.</span>
              {el.TestPrice}
            </div>
            <h5 class="plan">plan</h5>
            <ul class="details">
              <li>FREE Stickers</li>
              <li>FREE Delivery</li>
              <li>24/7 support</li>
            </ul>
            <div class="buy-button">
              <h3 class="btn">{el.TestName}</h3>
            </div>
          </div>
        ))}

        {/*  <div class="card">
          <h6 class="type">Awesome</h6>
          <div class="price">
            <span>₹ Rs.</span>800
          </div>
          <h5 class="plan">plan</h5>
          <ul class="details">
            <li>FREE Stickers</li>
            <li>FREE Delivery</li>
            <li>24/7 support</li>
          </ul>
          <div class="buy-button">
            <h3 class="btn">Blood test</h3>
          </div>
        </div>
        <div class="card">
          <h6 class="type">Awesome</h6>
          <div class="price">
            <span>₹ Rs.</span>800
          </div>
          <h5 class="plan">plan</h5>
          <ul class="details">
            <li>FREE Stickers</li>
            <li>FREE Delivery</li>
            <li>24/7 support</li>
          </ul>
          <div class="buy-button">
            <h3 class="btn">X-Ray</h3>
          </div>
        </div>
        <div class="card">
          <h6 class="type">Awesome</h6>
          <div class="price">
            <span>₹ Rs.</span>800
          </div>
          <h5 class="plan">plan</h5>
          <ul class="details">
            <li>FREE Stickers</li>
            <li>FREE Delivery</li>
            <li>24/7 support</li>
          </ul>
          <div class="buy-button">
            <h3 class="btn">CT-Scan</h3>
          </div>
        </div>
        <div class="card">
          <h6 class="type">Awesome</h6>
          <div class="price">
            <span>₹ Rs.</span>800
          </div>
          <h5 class="plan">plan</h5>
          <ul class="details">
            <li>FREE Stickers</li>
            <li>FREE Delivery</li>
            <li>24/7 support</li>
          </ul>
          <div class="buy-button">
            <h3 class="btn">Thyroid Test</h3>
          </div>
        </div>
        <div class="card">
          <h6 class="type">Awesome</h6>
          <div class="price">
            <span>₹ Rs.</span>800
          </div>
          <h5 class="plan">plan</h5>
          <ul class="details">
            <li>FREE Stickers</li>
            <li>FREE Delivery</li>
            <li>24/7 support</li>
          </ul>
          <div class="buy-button">
            <h3 class="btn">Ecg Testing</h3>
          </div>
  </div>*/}
      </section>

      <Footer />
    </>
  );
}
