import {
  OFFER_LIST_FAILED,
  OFFER_LIST_REQ,
  OFFER_LIST_SUCCESS,
  CREATE_OFFER_FAILED,
  CREATE_OFFER_REQ,
  CREATE_OFFER_RESET,
  CREATE_OFFER_SUCCESS,
  REMOVE_OFFER_FAILED,
  REMOVE_OFFER_REQ,
  REMOVE_OFFER_RESET,
  REMOVE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILED,
  UPDATE_OFFER_REQ,
  UPDATE_OFFER_RESET,
  UPDATE_OFFER_SUCCESS,
} from "../Constant/OfferConstant";

export const OFFER_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case OFFER_LIST_REQ: {
      return { Offer_List_Loading: true };
    }

    case OFFER_LIST_SUCCESS: {
      return {
        Offer_List_Loading: false,
        Offer_List: payload.Result,
        total_Offers: payload.total_Offers,
      };
    }
    case OFFER_LIST_FAILED: {
      return {
        Offer_List_Loading: false,
        Offer_List_Error: payload,
      };
    }
    default:
      return state;
  }
};

export const OFFER_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_OFFER_REQ: {
      return { Create_Offer_Loading: true };
    }
    case CREATE_OFFER_SUCCESS: {
      return {
        Create_OFFER_Loading: false,
        Create_OFFER_Success: payload,
      };
    }

    case CREATE_OFFER_FAILED: {
      return {
        Create_OFFER_Loading: false,
        Create_OFFER_Error: payload,
      };
    }

    case CREATE_OFFER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const OFFER_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_OFFER_REQ: {
      return { Update_OFFER_Loading: true };
    }

    case UPDATE_OFFER_SUCCESS: {
      return {
        Update_OFFER_Loading: false,
        Update_OFFER_Success: payload,
      };
    }

    case UPDATE_OFFER_FAILED: {
      return {
        Update_OFFER_Loading: false,
        Update_OFFER_Error: payload,
      };
    }

    case UPDATE_OFFER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_OFFER_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_OFFER_REQ: {
      return { Remove_OFFER_Loading: true };
    }

    case REMOVE_OFFER_SUCCESS: {
      return {
        Remove_OFFER_Loading: false,
        Remove_OFFER_Success: payload,
      };
    }

    case REMOVE_OFFER_FAILED: {
      return {
        Remove_OFFER_Loading: false,
        Remove_OFFER_Error: payload,
      };
    }

    case REMOVE_OFFER_RESET: {
      return {};
    }

    default:
      return state;
  }
};
