import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

export default function TermsPolicy() {
  return (
    <>
      <div></div>
      <Header Page={"Services"} />
      <section class="page-title bg-1">
        <div class="overlay"></div>{" "}
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <h1 className="text-white">Terms and Condition</h1>

                {/*<!-- <ul className="list-inline breadcumb-nav">
          <li className="list-inline-item"><a href="index.html" className="text-white">Home</a></li>
          <li className="list-inline-item"><span className="text-white">/</span></li>
          <li className="list-inline-item"><a href="#" className="text-white-50">Contact Us</a></li>
        </ul> -->*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section doctors">
        <div class="container">
          <div>
            <h1>TERMS AND CONDITIONS </h1>
            <p> Last updated September 07, 2023 </p>
            <p>
              We are PHOTON DIAGNOSTICS AND RESEARCH CENTRE ("Company," "we,"
              "us," "our"), a company registered in India at assam, assam. We
              operate the website photondiagnostics.in (the "Site"), as well as
              any other related products and services that refer or link to
              these legal terms (the "Legal Terms") (collectively, the
              "Services"). You can contact us by phone at 8402030401, email at
              contact@photondiagnostics.in, or by mail to assam, assam, India.
              These Legal Terms constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ("you"),
              and PHOTON DIAGNOSTICS AND RESEARCH CENTRE, concerning your access
              to and use of the Services. You agree that by accessing the
              Services, you have read, understood, and agreed to be bound by all
              of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL
              TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES
              AND YOU MUST DISCONTINUE USE IMMEDIATELY. __________ The Services
              are intended for users who are at least 13 years of age. All users
              who are minors in the jurisdiction in which they reside (generally
              under the age of 18) must have the permission of, and be directly
              supervised by, their parent or guardian to use the Services. If
              you are a minor, you must have your parent or guardian read and
              agree to these Legal Terms prior to you using the Services. We
              recommend that you print a copy of these Legal Terms for your
              records.{" "}
            </p>
            <h1>1. OUR SERVICES </h1>
            <p>
              The information provided when using the Services is not intended
              for distribution to or use by any person or entity in any
              jurisdiction or country where such distribution or use would be
              contrary to law or regulation or which would subject us to any
              registration requirement within such jurisdiction or country.
              Accordingly, those persons who choose to access the Services from
              other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent
              local laws are applicable.{" "}
            </p>
            <h1>2. INTELLECTUAL PROPERTY RIGHTS </h1>
            <h6>Our intellectual property </h6>
            <p>
              We are the owner or the licensee of all intellectual property
              rights in our Services, including all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics in the Services (collectively, the
              "Content"), as well as the trademarks, service marks, and logos
              contained therein (the "Marks").
            </p>
            <p>
              Our Content and Marks are protected by copyright and trademark
              laws (and various other intellectual property rights and unfair
              competition laws) and treaties in the United States and around the
              world.
            </p>
            <p>
              {" "}
              The Content and Marks are provided in or through the Services "AS
              IS" for your personal, non-commercial use or internal business
              purpose only.
            </p>
            <h4>Your use of our Services </h4>
            <p>
              Subject to your compliance with these Legal Terms, including the
              "PROHIBITED ACTIVITIES" section below, we grant you a
              non-exclusive, non-transferable, revocable license to:{" "}
            </p>
            <ul>
              <li>access the Services; and</li>
              <li>
                download or print a copy of any portion of the Content to which
                you have properly gained access.
              </li>
            </ul>
            <p>
              solely for your personal, non-commercial use or internal business
              purpose.
            </p>
            <p>
              Except as set out in this section or elsewhere in our Legal Terms,
              no part of the Services and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </p>
            <p>
              If you wish to make any use of the Services, Content, or Marks
              other than as set out in this section or elsewhere in our Legal
              Terms, please address your request to:
              contact@photondiagnostics.in. If we ever grant you the permission
              to post, reproduce, or publicly display any part of our Services
              or Content, you must identify us as the owners or licensors of the
              Services, Content, or Marks and ensure that any copyright or
              proprietary notice appears or is visible on posting, reproducing,
              or displaying our Content.
            </p>
            <p>
              We reserve all rights not expressly granted to you in and to the
              Services, Content, and Marks.{" "}
            </p>
            <p>
              {" "}
              Any breach of these Intellectual Property Rights will constitute a
              material breach of our Legal Terms and your right to use our
              Services will terminate immediately.
            </p>
            <h5> Your submissions</h5>
            <p>
              Please review this section and the "PROHIBITED ACTIVITIES" section
              carefully prior to using our Services to understand the (a) rights
              you give us and (b) obligations you have when you post or upload
              any content through the Services.
            </p>
            <p>
              submissions: By directly sending us any question, comment,
              suggestion, idea, feedback, or other information about the
              Services ("Submissions"), you agree to assign to us all
              intellectual property rights in such Submission. You agree that we
              shall own this Submission and be entitled to its unrestricted use
              and dissemination for any lawful purpose, commercial or otherwise,
              without acknowledgment or compensation to you.
            </p>
            <p>
              You are responsible for what you post or upload: By sending us
              Submissions through any part of the Services you:
            </p>
            <ul>
              <li>
                confirm that you have read and agree with our "PROHIBITED
                ACTIVITIES" and will not post, send, publish, upload, or
                transmit through the Services any Submission that is illegal,
                harassing, hateful, harmful, defamatory, obscene, bullying,
                abusive, discriminatory, threatening to any person or group,
                sexually explicit, false, inaccurate, deceitful, or misleading;
              </li>
              <li>
                to the extent permissible by applicable law, waive any and all
                moral rights to any such Submission;
              </li>

              <li>
                warrant that any such Submission are original to you or that you
                have the necessary rights and licenses to submit such
                Submissions and that you have full authority to grant us the
                above-mentioned rights in relation to your Submissions; and
              </li>

              <li>
                warrant and represent that your Submissions do not constitute
                confidential information.
              </li>
            </ul>
            <p>
              You are solely responsible for your Submissions and you expressly
              agree to reimburse us for any and all losses that we may suffer
              because of your breach of (a) this section, (b) any third party’s
              intellectual property rights, or (c) applicable law.
            </p>
            <h1>3. USER REPRESENTATIONS</h1>
            <p>
              By using the Services, you represent and warrant that: (1) all
              registration information you submit will be true, accurate,
              current, and complete; (2) you will maintain the accuracy of such
              information and promptly update such registration information as
              necessary; (3) you have the legal capacity and you agree to comply
              with these Legal Terms; (4) you are not under the age of 13; (5)
              you are not a minor in the jurisdiction in which you reside, or if
              a minor, you have received parental permission to use the
              Services; (6) you will not access the Services through automated
              or non-human means, whether through a bot, script or otherwise;
              (7) you will not use the Services for any illegal or unauthorized
              purpose; and (8) your use of the Services will not violate any
              applicable law or regulation.{" "}
            </p>
            <p>
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Services (or any portion thereof).
            </p>
            <h1>4. USER REGISTRATION</h1>
            <p>
              You may not access or use the Services for any purpose other than
              that for which we make the Services available. The Services may
              not be used in connection with any commercial endeavors except
              those that are specifically endorsed or approved by us.
            </p>
            <h1>23. CONTACT US</h1>
            <p>
              In order to resolve a complaint regarding the Services or to
              receive further information regarding use of the Services, please
              contact us at:
            </p>
            <h6>PHOTON DIAGNOSTICS AND RESEARCH CENTRE</h6>
            <h6>india</h6>
            <h6>phone: 8402030401</h6>
            <h6> contact@photondiagnostics.in </h6>
          </div>{" "}
        </div>
      </section>

      <Footer />
    </>
  );
}
