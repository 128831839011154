import axios from "axios";
import {
  APPOINTMENT_LIST_FAILED,
  APPOINTMENT_LIST_REQ,
  APPOINTMENT_LIST_SUCCESS,
  CREATE_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_REQ,
  CREATE_APPOINTMENT_RESET,
  CREATE_APPOINTMENT_SUCCESS,
  REMOVE_APPOINTMENT_REQ,
  REMOVE_APPOINTMENT_RESET,
  REMOVE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_REQ,
  UPDATE_APPOINTMENT_RESET,
  UPDATE_APPOINTMENT_SUCCESS,
} from "../Constant/AppointmentConstant";

import axiosConfig from "../Core/axiosConfig";

export const APPOINTMENT_CREATE = (APPOINTMENT_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_APPOINTMENT_REQ });
    const { data } = await axiosConfig.post("/APPOINTMENT", APPOINTMENT_Data);
    dispatch({ type: CREATE_APPOINTMENT_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: CREATE_APPOINTMENT_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const APPOINTMENT_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_APPOINTMENT_RESET });
};

export const LIST_APPOINTMENT =
  (appointment_query) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPOINTMENT_LIST_REQ });
      let { LOGIN_USER_REDUCER } = getState();
      let { data } = await axiosConfig.get(
        `/appointment?page_no=${appointment_query.page_no}&Appointment_name=${appointment_query.Appointment_name}&page_size=${appointment_query.page_size}`,
        {
          headers: {
            Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
          },
        }
      );
      dispatch({ type: APPOINTMENT_LIST_SUCCESS, payload: data });
    } catch (ex) {
      console.log(ex);
      dispatch({
        type: APPOINTMENT_LIST_FAILED,
        payload: ex.response?.data?.Result
          ? ex.response?.data.Result
          : ex.message,
      });
    }
  };

export const UPDATE_APPOINTMENT =
  (AppointmentData, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_APPOINTMENT_REQ });
      let { LOGIN_USER_REDUCER } = getState();
      if (formData) {
        const response = await axios.post(
          `${process.env.REACT_APP_KEY}`,
          formData
        );
        let { data } = await axiosConfig.put(
          "/APPOINTMENT",
          { ...AppointmentData, Report: response?.data?.data?.display_url },
          {
            headers: {
              Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
            },
          }
        );
        dispatch({ type: UPDATE_APPOINTMENT_SUCCESS, payload: data.Result });
      } else {
        let { data } = await axiosConfig.put("/APPOINTMENT", AppointmentData, {
          headers: {
            Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
          },
        });
        dispatch({ type: UPDATE_APPOINTMENT_SUCCESS, payload: data.Result });
      }
    } catch (ex) {
      dispatch({
        type: UPDATE_APPOINTMENT_FAILED,
        payload: ex.response?.data?.Result
          ? ex.response?.data.Result
          : ex.message,
      });
    }
  };

export const RESET_APPOINTMENT_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_APPOINTMENT_RESET });
};

export const DELETE_APPOINTMENT = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_APPOINTMENT_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/APPOINTMENT/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_APPOINTMENT_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_APPOINTMENT_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_APPOINTMENT_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_APPOINTMENT_RESET });
};
