import {
  DEPARTMENT_LIST_FAILED,
  DEPARTMENT_LIST_REQ,
  DEPARTMENT_LIST_SUCCESS,
  CREATE_DEPARTMENT_FAILED,
  CREATE_DEPARTMENT_REQ,
  CREATE_DEPARTMENT_RESET,
  CREATE_DEPARTMENT_SUCCESS,
  REMOVE_DEPARTMENT_REQ,
  REMOVE_DEPARTMENT_RESET,
  REMOVE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILED,
  UPDATE_DEPARTMENT_REQ,
  UPDATE_DEPARTMENT_RESET,
  UPDATE_DEPARTMENT_SUCCESS,
} from "../Constant/DepartmentConstant";

import axiosConfig from "../Core/axiosConfig";

export const DEPARTMENT_CREATE = (DEPARTMENT_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DEPARTMENT_REQ });
    const { data } = await axiosConfig.post("/DEPARTMENT", DEPARTMENT_Data);
    dispatch({ type: CREATE_DEPARTMENT_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_DEPARTMENT_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DEPARTMENT_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_DEPARTMENT_RESET });
};

export const LIST_DEPARTMENT =
  (department_query) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEPARTMENT_LIST_REQ });
      let { LOGIN_USER_REDUCER } = getState();
      let { data } = await axiosConfig.get(
        `/department?page_no=${department_query.page_no}&Department_name=${department_query.Department_name}&page_size=${department_query.page_size}`,
        {
          headers: {
            Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
          },
        }
      );
      dispatch({ type: DEPARTMENT_LIST_SUCCESS, payload: data });
    } catch (ex) {
      console.log(ex);
      dispatch({
        type: DEPARTMENT_LIST_FAILED,
        payload: ex.response?.data?.Result
          ? ex.response?.data.Result
          : ex.message,
      });
    }
  };

export const UPDATE_DEPARTMENT =
  (DEPARTMENTData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_DEPARTMENT_REQ });
      let { LOGIN_USER_REDUCER } = getState();
      let { data } = await axiosConfig.put("/department", DEPARTMENTData, {
        headers: {
          Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
        },
      });
      console.log(data);
      dispatch({ type: UPDATE_DEPARTMENT_SUCCESS, payload: data.Result });
    } catch (ex) {
      console.log(ex);
      dispatch({
        type: UPDATE_DEPARTMENT_FAILED,
        payload: ex.response?.data?.Result
          ? ex.response?.data.Result
          : ex.message,
      });
    }
  };

export const RESET_DEPARTMENT_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_DEPARTMENT_RESET });
};

export const DELETE_DEPARTMENT = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_DEPARTMENT_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/DEPARTMENT/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_DEPARTMENT_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_DEPARTMENT_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_DEPARTMENT_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_DEPARTMENT_RESET });
};
