import {
  UPDATE_USER_FAILED,
  UPDATE_USER_REQ,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCESS,
  USER_LIST_FAILED,
  USER_LIST_REQ,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_REQ,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAILED,
  USER_REGISTER_REQ,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
} from "../Constant/UserLoginConstant";
import axiosConfig from "../Core/axiosConfig";
import { REMOVE_LOCAL, SET_LOCAL } from "../Model/LocalData";

export const LOGIN_USER = (Login_data) => async (dispatch) => {
  const today = new Date();
  let time = today.getTime();
  try {
    dispatch({ type: USER_LOGIN_REQ });
    const { data } = await axiosConfig.post("/user/login", Login_data);
    SET_LOCAL("_stTkn", data?.accesstoken);
    SET_LOCAL("_userInf", data?.Result);
    SET_LOCAL("_stExp", time);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: { data, Time: time } });
  } catch (ex) {
    dispatch({
      type: USER_LOGIN_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const LOGOUT = () => (dispatch) => {
  REMOVE_LOCAL("_stTkn");
  REMOVE_LOCAL("_userInf");
  REMOVE_LOCAL("_stExp");
  dispatch({ type: USER_LOGOUT });
};

export const USERS_LISTS = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    const { data } = await axiosConfig.get("/user", {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    if (data.Result === "string") {
      dispatch({ type: USER_LIST_FAILED, payload: data.Result });
    } else {
      dispatch({ type: USER_LIST_SUCCESS, payload: data.Result });
    }
  } catch (ex) {
    dispatch({
      type: USER_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const UPDATE_USER = (user_data) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_USER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    const { data } = await axiosConfig.put(`/user`, user_data, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: UPDATE_USER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const USER_UPDATE_RESET = () => (dispatch) => {
  dispatch({ type: UPDATE_USER_RESET });
};

export const REGISTER_USER = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_REGISTER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    const { data } = await axiosConfig.post("/user/register", user, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: USER_REGISTER_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: USER_REGISTER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const REGISTER_USER_RESET = () => (dispatch) => {
  dispatch({ type: USER_REGISTER_RESET });
};
