import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { REGISTER_USER, REGISTER_USER_RESET } from "../../../Action/UserAction";

export default function RegisterContact({ isOpen, onOpen, onClose }) {
  const [UserFormData, setUserFormData] = useState(Register_User_Model);
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const toast = useToast();

  const { User_Register_Loading, User_Register_Success, User_Register_Error } =
    useSelector((state) => state.REGISTER_USER_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setUserFormData({ ...UserFormData, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(REGISTER_USER(UserFormData));
  };

  useEffect(() => {
    if (User_Register_Success) {
      toast({
        title: "Account created.",
        description: User_Register_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch(REGISTER_USER_RESET());
      setUserFormData(Register_User_Model);
    }

    if (User_Register_Error) {
      toast({
        title: "Failed.",
        description: User_Register_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(REGISTER_USER_RESET());
    }
  }, [User_Register_Success, User_Register_Error]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <span onClick={onClose} className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>CREATE MOVIE</h1>
                </div>
                <div className="form">
                  <form onSubmit={handleUserSubmit}>
                    <input
                      type="text"
                      name="MovieName"
                      id="MovieName"
                      placeholder="Movie Name"
                      value={UserFormData.MovieName}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="Poster"
                      placeholder="Movie Poster Url"
                      value={UserFormData.Poster}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="text"
                      name="MovieHeading"
                      placeholder="Movie Heading"
                      value={UserFormData.MovieHeading}
                      onChange={handleFormChange}
                      id="MovieHeading"
                      required
                    />
                    <input
                      type="text"
                      name="Year"
                      placeholder="Released Year"
                      value={UserFormData.Year}
                      onChange={handleFormChange}
                      id="year"
                      required
                    />

                    <input
                      type="text"
                      name="Rating"
                      placeholder="Rating"
                      value={UserFormData.Rating}
                      onChange={handleFormChange}
                      id="Rating"
                      required
                    />
                    <input
                      type="text"
                      name="Trailer"
                      placeholder="Trailer url"
                      value={UserFormData.Trailer}
                      onChange={handleFormChange}
                      id="Trailer"
                      required
                    />
                    <input
                      type="text"
                      name="Key"
                      placeholder="Movie Key"
                      value={UserFormData.Key}
                      onChange={handleFormChange}
                      id="key"
                      required
                    />

                    <textarea
                      type="text"
                      name="Desc1"
                      placeholder="Description"
                      value={UserFormData.Desc1}
                      onChange={handleFormChange}
                      id="Desc1"
                      required
                    />
                    <textarea
                      type="text"
                      name="Desc2"
                      placeholder="Description"
                      value={UserFormData.Desc2}
                      onChange={handleFormChange}
                      id="Desc2"
                      required
                    />
                    <textarea
                      type="text"
                      name="Desc3"
                      placeholder="Description"
                      value={UserFormData.Desc3}
                      onChange={handleFormChange}
                      id="Desc3"
                      required
                    />
                    <textarea
                      type="text"
                      name="Desc4"
                      placeholder="Description"
                      value={UserFormData.Desc4}
                      onChange={handleFormChange}
                      id="Desc4"
                      required
                    />
                    <textarea
                      type="text"
                      name="Desc5"
                      placeholder="Description"
                      value={UserFormData.Desc5}
                      onChange={handleFormChange}
                      id="Desc5"
                      required
                    />
                    <button type="submit">
                      {User_Register_Loading ? "Loading..." : "CREATE"}
                    </button>
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
