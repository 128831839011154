import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import { Modal, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { REGISTER_USER, REGISTER_USER_RESET } from "../../../Action/UserAction";

export default function RegisterUser({
  isOpen,
  onOpen,
  onClose,
  Get_User_Lists,
}) {
  const [UserFormData, setUserFormData] = useState(Register_User_Model);
  const toast = useToast();

  const { User_Register_Loading, User_Register_Success, User_Register_Error } =
    useSelector((state) => state.REGISTER_USER_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setUserFormData({ ...UserFormData, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(REGISTER_USER(UserFormData));
  };

  useEffect(() => {
    if (User_Register_Success) {
      toast({
        title: "Account created.",
        description: User_Register_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_User_Lists();
      dispatch(REGISTER_USER_RESET());
      setUserFormData(Register_User_Model);
    }

    if (User_Register_Error) {
      toast({
        title: "Failed.",
        description: User_Register_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(REGISTER_USER_RESET());
    }
  }, [User_Register_Success, User_Register_Error]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <span onClick={onClose} className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>Register User</h1>
                </div>
                <div className="form">
                  <form onSubmit={handleUserSubmit}>
                    <input
                      type="text"
                      name="Name"
                      id="username"
                      placeholder="Name"
                      value={UserFormData.Name}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="email"
                      name="Email"
                      placeholder="Email"
                      value={UserFormData.Email}
                      onChange={handleFormChange}
                      id="email"
                      required
                    />
                    <input
                      type="phone"
                      name="Phone"
                      placeholder="Phone"
                      value={UserFormData.Phone}
                      onChange={handleFormChange}
                      id="phone"
                      required
                    />
                    <input
                      type="password"
                      name="Password"
                      placeholder="Password"
                      value={UserFormData.Password}
                      onChange={handleFormChange}
                      id="password"
                      required
                    />
                    <button type="submit">
                      {User_Register_Loading ? "Loading..." : "Register"}
                    </button>
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
