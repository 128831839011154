import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Meta from "../../Components/Meta";

export default function Doctor() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const MetaDes = {
    title: "Doctors - Photon Diagnostics and Research Center",
    desc: " We offer a diverse range of creative services, delivering excellence with utmost care. Our team is dedicated to providing top-notch solutions tailored to your needs, ensuring your utmost satisfaction.",
  };

  return (
    <>
      <Meta MetaDes={MetaDes} />
      <Header Page={"Doctors"} />
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">All Doctors</span>
                <h1 class="text-capitalize mb-5 text-lg">Specalized doctors</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- portfolio --> */}
      <section class="section doctors">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="section-title">
                <h2 data-aos="fade-down" data-aos-duration="300">
                  Doctors
                </h2>
                <div class="divider mx-auto my-4"></div>
                <p data-aos="fade-down" data-aos-duration="600">
                  We offer a diverse range of creative services, delivering
                  excellence with utmost care. Our team is dedicated to
                  providing top-notch solutions tailored to your needs, ensuring
                  your utmost satisfaction.
                </p>
              </div>
            </div>
          </div>

          <div class="col-12 text-center  mb-5">
            <div class="btn-group btn-group-toggle " data-toggle="buttons">
              <label class="btn active ">
                <input
                  type="radio"
                  name="shuffle-filter"
                  value="all"
                  checked="checked"
                />
                All Department
              </label>
            </div>
          </div>

          <div class="row shuffle-wrapper portfolio-gallery">
            <div
              class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
              data-groups='["cat1","cat2"]'
              data-aos="flip-left"
              data-aos-duration="300">
              <div class="position-relative doctor-inner-box">
                <div class="doctor-profile">
                  <div class="doctor-img">
                    <img
                      src="../assets/images/Doctor/Dt1.jpeg"
                      alt="doctor-image"
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content mt-3">
                  <h4 class="mb-0">
                    <a href="doctor-single.html">DR.SAHIDUL ISLAM</a>
                  </h4>
                  <p>MBBS,MD RADIODIAGNOSIS</p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
              data-groups='["cat2"]'
              data-aos="flip-right"
              data-aos-duration="300">
              <div class="position-relative doctor-inner-box">
                <div class="doctor-profile">
                  <div class="doctor-img">
                    <img
                      src="../assets/images/Doctor/Dt3.jpeg"
                      alt="doctor-image"
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content mt-3">
                  <h4 class="mb-0">
                    <a href="doctor-single.html">DR.ARIFUL ISLAM</a>
                  </h4>
                  <p>MBBS,MD MEDICINE</p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
              data-groups='["cat3"]'
              data-aos="flip-left">
              <div class="position-relative doctor-inner-box">
                <div class="doctor-profile">
                  <div class="doctor-img">
                    <img
                      src="../assets/images/Doctor/Dt2.jpeg"
                      alt="doctor-image"
                      class="img-fluid w-100"
                    />
                  </div>
                </div>
                <div class="content mt-3">
                  <h4 class="mb-0">
                    <a href="doctor-single.html">Dr Emdad Hussain</a>
                  </h4>
                  <p> MBBS, MD, F.Diab (Diabetes)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- /portfolio --> */}
      <section class="section cta-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="cta-content">
                <div class="divider mb-4"></div>
                <h2 class="mb-5 text-lg">
                  We are pleased to offer you the{" "}
                  <span class="title-color">chance to have the healthy</span>
                </h2>
                <Link to="/appointment" class="btn btn-main-2 btn-round-full">
                  Get appoinment<i class="icofont-simple-right  ml-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
