import {
  DOCTOR_LIST_FAILED,
  DOCTOR_LIST_REQ,
  DOCTOR_LIST_SUCCESS,
  CREATE_DOCTOR_FAILED,
  CREATE_DOCTOR_REQ,
  CREATE_DOCTOR_RESET,
  CREATE_DOCTOR_SUCCESS,
  REMOVE_DOCTOR_FAILED,
  REMOVE_DOCTOR_REQ,
  REMOVE_DOCTOR_RESET,
  REMOVE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAILED,
  UPDATE_DOCTOR_REQ,
  UPDATE_DOCTOR_RESET,
  UPDATE_DOCTOR_SUCCESS,
} from "../Constant/DoctorConstant";

export const DOCTOR_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case DOCTOR_LIST_REQ: {
      return { Doctor_List_Loading: true };
    }

    case DOCTOR_LIST_SUCCESS: {
      return {
        Doctor_List_Loading: false,
        Doctor_List: payload.Result,
        total_Doctors: payload.total_Doctors,
      };
    }
    case DOCTOR_LIST_FAILED: {
      return {
        Doctor_List_Loading: false,
        Doctor_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const DOCTOR_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_DOCTOR_REQ: {
      return { Create_Doctor_Loading: true };
    }
    case CREATE_DOCTOR_SUCCESS: {
      return {
        Create_Doctor_Loading: false,
        Create_Doctor_Success: payload,
      };
    }

    case CREATE_DOCTOR_FAILED: {
      return {
        Create_Doctor_Loading: false,
        Create_Doctor_Error: payload,
      };
    }

    case CREATE_DOCTOR_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const DOCTOR_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_DOCTOR_REQ: {
      return { Update_Doctor_Loading: true };
    }

    case UPDATE_DOCTOR_SUCCESS: {
      return {
        Update_Doctor_Loading: false,
        Update_Doctor_Success: payload,
      };
    }

    case UPDATE_DOCTOR_FAILED: {
      return {
        Update_Doctor_Loading: false,
        Update_Doctor_Error: payload,
      };
    }

    case UPDATE_DOCTOR_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_DOCTOR_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_DOCTOR_REQ: {
      return { Remove_Doctor_Loading: true };
    }

    case REMOVE_DOCTOR_SUCCESS: {
      return {
        Remove_Doctor_Loading: false,
        Remove_Doctor_Success: payload,
      };
    }

    case REMOVE_DOCTOR_FAILED: {
      return {
        Remove_Doctor_Loading: false,
        Remove_Doctor_Error: payload,
      };
    }

    case REMOVE_DOCTOR_RESET: {
      return {};
    }

    default:
      return state;
  }
};
