import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGIN_USER } from "../../../Action/UserAction";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [LoginData, setLoginData] = useState({ Email: "", Password: "" });
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const { User_Login_Loading, accessToken, User_Login_Error } = useSelector(
    (state) => state.LOGIN_USER_REDUCER
  );

  const handleFormChange = (e) => {
    setLoginData({ ...LoginData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      LoginData.Email === "" ||
      LoginData.Email === null ||
      LoginData.Password === "" ||
      LoginData.Password === null
    ) {
      toast({
        title: "Failed..",
        description: "Error - All fields required !",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      dispatch(LOGIN_USER(LoginData));
    }
  };

  useEffect(() => {
    if (User_Login_Error) {
      toast({
        title: "Failed..",
        description: User_Login_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (accessToken) {
      navigate("/admin/contact");
    }
  }, [accessToken, User_Login_Error]);

  return (
    <div class="Admin_Login">
      <section class="container">
        <div class="login-container">
          <div class="circle circle-one"></div>
          <div class="form-container">
            <img
              src="https://raw.githubusercontent.com/hicodersofficial/glassmorphism-login-form/master/assets/illustration.png"
              alt="illustration"
              class="illustration"
            />
            <h1 style={{ color: "white" }} class="opacity">
              LOGIN
            </h1>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="Email"
                value={LoginData.Email}
                onChange={handleFormChange}
                placeholder="EMAIL"
              />
              <input
                type="password"
                name="Password"
                onChange={handleFormChange}
                value={LoginData.Password}
                placeholder="PASSWORD"
              />
              <button class="opacity" type="submit">
                {User_Login_Loading ? "Loading.." : "SUBMIT"}
              </button>
            </form>
            <div class="register-forget opacity">
              <a href="">FORGOT PASSWORD</a>
            </div>
          </div>
          <div class="circle circle-two"></div>
        </div>
        <div class="theme-btn-container"></div>
      </section>
    </div>
  );
}
