export const UPDATE_REFERAL_REQ = "UPDATE_REFERAL_REQ";
export const UPDATE_REFERAL_SUCCESS = "UPDATE_REFERAL_REQ";
export const UPDATE_REFERAL_FAILED = "UPDATE_REFERAL_REQ";
export const UPDATE_REFERAL_RESET = "UPDATE_REFERAL_REQ";

export const REFERAL_LIST_REQ = "REFERAL_LIST_REQ";
export const REFERAL_LIST_SUCCESS = "REFERAL_LIST_SUCCESS";
export const REFERAL_LIST_FAILED = "REFERAL_LIST_FAILED";
export const REFERAL_LIST_RESET = "REFERAL_LIST_RESET";

export const SINGLE_REFERAL_REQ = "SINGLE_REFERAL_REQ";
export const SINGLE_REFERAL_SUCCESS = "SINGLE_REFERAL_SUCCESS";
export const SINGLE_REFERAL_FAILED = "SINGLE_REFERAL_FAILED";
export const SINGLE_REFERAL_RESET = "SINGLE_REFERAL_RESET";

export const REMOVE_REFERAL_REQ = "REMOVE_REFERAL_REQ";
export const REMOVE_REFERAL_SUCCESS = "REMOVE_REFERAL_SUCCESS";
export const REMOVE_REFERAL_FAILED = "REMOVE_REFERAL_FAILED";
export const REMOVE_REFERAL_RESET = "REMOVE_REFERAL_RESET";
