import Aos from "aos";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Offer from "./Offer";

export default function Footer() {
  useEffect(() => {
    Aos.init();
  });

  return (
    <footer class="footer section gray-bg">
      <div
        class="container"
        data-aos="fade-left"
        data-aos-easing="linear"
        data-aos-duration="1000">
        <div class="row">
          <div class="col-lg-4 mr-auto col-sm-6">
            <div class="widget mb-5 mb-lg-0">
              <div class="logo mb-4">
                <img
                  src="../assets/Logo.png"
                  alt=""
                  class="img-fluid"
                  style={{ height: "100px" }}
                />
              </div>
              <p>
                Photon Diagnostics and Research Center - Illuminating the Path
                to Health Advancements through Innovative Research and Precise
                Diagnostics.
              </p>

              <ul class="list-inline footer-socials mt-4">
                <li class="list-inline-item">
                  <a href="#">
                    <i class="icofont-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="icofont-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <i class="icofont-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 col-sm-6">
            <div class="widget mb-5 mb-lg-0">
              <h4 class="text-capitalize mb-3">Services</h4>
              <div class="divider mb-4"></div>

              <ul class="list-unstyled footer-menu lh-35">
                <li>
                  <a href="#!">X-ray </a>
                </li>
                <li>
                  <a href="#!">Ultrasound</a>
                </li>
                <li>
                  <a href="#!">Ecg Testing</a>
                </li>
                <li>
                  <a href="#!">CTscan</a>
                </li>
                <li>
                  <a href="#!">Medicine</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-6 col-sm-6">
            <div class="widget mb-5 mb-lg-0">
              <h4 class="text-capitalize mb-3">Support</h4>
              <div class="divider mb-4"></div>

              <ul class="list-unstyled footer-menu lh-35">
                <li>
                  <Link to="/terms-and-condition">Terms & Conditions</Link>
                </li>
                <li>
                  <a href="#!">Privacy Policy</a>
                </li>
                <li>
                  <Link to="/admin/login">Admin Support</Link>
                </li>
                <li>
                  <a href="#!">FAQuestions</a>
                </li>
                <li>
                  <a href="#!">Company Licence</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="widget widget-contact mb-5 mb-lg-0">
              <h4 class="text-capitalize mb-3">Get in Touch</h4>
              <div class="divider mb-4"></div>

              <div class="footer-contact-block mb-4">
                <div class="icon d-flex align-items-center">
                  <i class="icofont-email mr-3"></i>
                  <span class="h6 mb-0">Support Available for 24/7</span>
                </div>
                <h4 class="mt-2" style={{ fontSize: "15px" }}>
                  <a href="mailto:contact@photondiagnostics.in">
                    contact@photondiagnostics.in
                  </a>
                </h4>
              </div>

              <div class="footer-contact-block">
                <div class="icon d-flex align-items-center">
                  <i class="icofont-support mr-3"></i>
                  <span class="h6 mb-0">Mon to Sat : 08:00 AM - 08:00 PM</span>
                </div>
                <h4 class="mt-2" style={{ fontSize: "15px" }}>
                  <a href="tel:+918402030401">+918402030401</a>
                  <br />
                  <a href="tel:+919864400230">+919864400230</a>
                  <br />
                  <a href="tel:+918902083308">+918902083308</a>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="copyright">
            Copyright &copy; 2023, Designed &amp; Developed by
            <a
              href="https://jyotiranjan1997.github.io"
              target="_blank"
              rel="noreferrer">
              {" "}
              Jyotiranjan Mohanty
            </a>
          </div>
        </div>
      </div>
      <Offer />
    </footer>
  );
}
