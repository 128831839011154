import {
  DEPARTMENT_LIST_FAILED,
  DEPARTMENT_LIST_REQ,
  DEPARTMENT_LIST_SUCCESS,
  CREATE_DEPARTMENT_FAILED,
  CREATE_DEPARTMENT_REQ,
  CREATE_DEPARTMENT_RESET,
  CREATE_DEPARTMENT_SUCCESS,
  REMOVE_DEPARTMENT_FAILED,
  REMOVE_DEPARTMENT_REQ,
  REMOVE_DEPARTMENT_RESET,
  REMOVE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILED,
  UPDATE_DEPARTMENT_REQ,
  UPDATE_DEPARTMENT_RESET,
  UPDATE_DEPARTMENT_SUCCESS,
} from "../Constant/DepartmentConstant";

export const DEPARTMENT_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case DEPARTMENT_LIST_REQ: {
      return { Department_List_Loading: true };
    }

    case DEPARTMENT_LIST_SUCCESS: {
      return {
        Department_List_Loading: false,
        Department_List: payload.Result,
        total_Departments: payload.total_Departments,
      };
    }
    case DEPARTMENT_LIST_FAILED: {
      return {
        Department_List_Loading: false,
        Department_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const DEPARTMENT_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_DEPARTMENT_REQ: {
      return { Create_Department_Loading: true };
    }
    case CREATE_DEPARTMENT_SUCCESS: {
      return {
        Create_Department_Loading: false,
        Create_Department_Success: payload,
      };
    }

    case CREATE_DEPARTMENT_FAILED: {
      return {
        Create_Department_Loading: false,
        Create_Department_Error: payload,
      };
    }

    case CREATE_DEPARTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const DEPARTMENT_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_DEPARTMENT_REQ: {
      return { Update_Department_Loading: true };
    }

    case UPDATE_DEPARTMENT_SUCCESS: {
      return {
        Update_Department_Loading: false,
        Update_Department_Success: payload,
      };
    }

    case UPDATE_DEPARTMENT_FAILED: {
      return {
        Update_Department_Loading: false,
        Update_Department_Error: payload,
      };
    }

    case UPDATE_DEPARTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_DEPARTMENT_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_DEPARTMENT_REQ: {
      return { Remove_Department_Loading: true };
    }

    case REMOVE_DEPARTMENT_SUCCESS: {
      return {
        Remove_Department_Loading: false,
        Remove_Department_Success: payload,
      };
    }

    case REMOVE_DEPARTMENT_FAILED: {
      return {
        Remove_Department_Loading: false,
        Remove_Department_Error: payload,
      };
    }

    case REMOVE_DEPARTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};
