import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Components/Pagination";
import { useDisclosure } from "@chakra-ui/react";
import { LIST_APPOINTMENT } from "../../Action/AppointmentAction";
import { useCallback } from "react";
import { Appointment_Query } from "../../Model/DepartmentModel";
import { LIST_REFERAL } from "../../Action/ReferalAction";

export default function ReferTable({
  memberId,
  Referal_List_Loading,
  total_Referals,
  Referal_List_Error,
  Referal_List,
  PAYOUT_List,
}) {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [ReferConfirmAmount, setReferConfirmAmount] = useState("0");
  const [AppointmentData, setAppointmentData] = useState([]);
  const [AppointmentEdit, setAppointmentEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [AppointmentQuery, setAppointmentQuery] = useState(Appointment_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const dispatch = useDispatch();

  const GET_Appointment = useCallback(() => {
    dispatch(LIST_REFERAL(memberId));
  }, [dispatch, AppointmentQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setAppointmentQuery({
      ...AppointmentQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setAppointmentQuery({ ...AppointmentQuery, Appointment_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setAppointmentQuery({ ...AppointmentQuery, Appointment_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setAppointmentQuery({
        ...AppointmentQuery,
        Appointment_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (ID) => {
    setAppointmentEdit(true);
  };

  const handleReferConfirm = () => {
    if (Referal_List) {
      let PRICE = 0;
      Referal_List?.forEach((el) => {
        if (el.ReferPayment) {
          PRICE = PRICE + +el.MemberPrice;
        }
      });
      setReferConfirmAmount(PRICE);
    }
  };
  console.log(PAYOUT_List);

  useEffect(() => {
    setAppointmentQuery((AppointmentQuery) => ({
      ...AppointmentQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    GET_Appointment();
  }, [AppointmentQuery, GET_Appointment]);

  useEffect(() => {
    if (Referal_List) {
      setAppointmentData(Referal_List);
      handleReferConfirm();
    } else {
      setAppointmentData([]);
    }

    if (Referal_List_Error) {
      setAlert({ Error: Referal_List_Error, validator: false });
    }
  }, [Referal_List, Referal_List_Error]);

  return (
    <>
      {
        <div className="home-content">
          <h1
            style={{
              marginTop: "20px",
              textAlign: "center",
              textDecoration: "underline",
            }}>
            ALL REFER LIST
          </h1>
          <h5>
            {" "}
            Referal Confirm Amount :
            <span style={{ color: "blue" }}>
              {" "}
              Rs. {ReferConfirmAmount ? ReferConfirmAmount : "00"}{" "}
            </span>
          </h5>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Last Amount Withdraw Pending:{" "}
            </span>
            <span style={{ color: "red", fontWeight: "bold" }}>
              {PAYOUT_List
                ? `${
                    PAYOUT_List[0]?.PayOutAmount
                      ? "Rs." + PAYOUT_List[0]?.PayOutAmount
                      : "N/A"
                  } on  ${
                    PAYOUT_List[0]?.PayOutDate
                      ? PAYOUT_List[0]?.PayOutDate + " Pending."
                      : ""
                  }`
                : "N/A"}{" "}
            </span>
          </p>
          <p>
            {" "}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              Last Amount Withdraw Success:{" "}
            </span>
            <span style={{ color: "green", fontWeight: "bold" }}>
              {PAYOUT_List
                ? `${
                    PAYOUT_List[1]?.PayOutAmount
                      ? "Rs." + PAYOUT_List[0]?.PayOutAmount
                      : "N/A"
                  } on  ${
                    PAYOUT_List[1]?.PayOutDate ? PAYOUT_List[1]?.PayOutDate : ""
                  }`
                : "N/A"}{" "}
            </span>
          </p>
          <div>
            <div className="table-responsive">
              <table
                style={{
                  whiteSpace: "nowrap",
                  width: "auto",
                  overflow: "scroll",
                }}
                className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Email </th>
                    <th scope="col">Age </th>
                    {/* <th scope="col">Doctor</th>
                    <th scope="col">Test</th>
                    <th scope="col">Refer by</th>
                    <th scope="col">Time</th>
              <th scope="col">PromoCode</th>*/}
                    <th scope="col">Total Price</th>
                    <th scope="col">Member Price</th>
                    <th scope="col">Refer Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Referal_List_Loading ? <p>Loading....</p> : ""}
                  {Referal_List_Error && <h2>{Referal_List_Error}</h2>}
                  {AppointmentData?.map((el) => (
                    <tr key={el._id}>
                      <td scope="row">{el.Name}</td>
                      <td scope="row">{el.Phone}</td>
                      <td scope="row">{el.Email}</td>
                      <td scope="row">{el.Age}</td>

                      {/* <td scope="row">{el.Doctor?.Name}</td>
                      <td scope="row">
                        {el.Department?.map((el, i) => (
                          <span key={i + 1}>
                            {el.TestName}

                            {" ,"}
                          </span>
                        ))}
                      </td>
                      <td scope="row">
                        {el.ReferId ? el.ReferId?.MemberId : "No Refer.."}{" "}
                      </td>
                      <td scope="row">{el.Time} </td>
                      <td scope="col">
                        {el.PromoCode ? el.PromoCode : "No PromoCode"}
                        </td>*/}
                      <td scope="row">₹ {el.TotalPrice} </td>
                      <td
                        scope="row"
                        style={
                          el.ReferPayment
                            ? { color: "green" }
                            : { color: "red" }
                        }>
                        ₹ {el.isMember ? el.MemberPrice : "N/A"}{" "}
                      </td>
                      <td
                        scope="row"
                        style={
                          el.ReferPayment
                            ? { color: "green" }
                            : { color: "red" }
                        }>
                        {el.ReferPayment ? "Refer Confirmed" : "Refer Pending"}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPage={Math.ceil(
              total_Referals ? total_Referals / AppointmentQuery?.page_size : 0
            )}
            total_record={total_Referals}
            handlePaginationPage={handlePaginationPage}
            handlePageSize={handlePageSize}
          />
        </div>
      }
    </>
  );
}
