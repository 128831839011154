import { Helmet } from "react-helmet";

export default function Meta({ MetaDes }) {
  return (
    <Helmet>
      <title>{MetaDes.title}</title>
      <meta name="description" content={MetaDes.desc} />
      <meta
        property="og:url"
        content="https://www.photondiagnostics.in/services"
      />
      <meta
        property="og:site_name"
        content="https://www.photondiagnostics.in/services"
      />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      <meta
        name="keywords"
        content="Best Diagnosis, doctors in Barpeta, Best Diagnostic in assam, Best healthcare centre, 
      Best Diagnostic, Best Pharmacy, All doctors, 
      Best Diagnostics, Doctors, Radiologist, Radiology, Best X-Ray, ECG, Doctors, Fast laboratory, fast health reports, Affordable health services, quality health services, Trusted test and Treatment, trusted tests, trusted treatment, Pregnancy test, 
      Top diagnostic
      Top diagnostics
      Top Diagnosis
      Best laboratory
      Best blood tests centre
      Best diagnosis centre
      Best Diagnostic centre
      Best Diagnostics centre
      Top diagnostic centre
      Top diagnostics centre
      Quality laboratory
      Ultrasound centre
      Sonography
      Best ultrasound
      Best doctors
      Top doctor
      Mueculoskeletal USG - Knee, Elbow, Wrist, Shoulder, Pediatric Hip joints
      Fluid Tapping - Therapeutic/Diagnostic Ascitic fluid, Pleural fluid, USG guided drainage
      USG, CT SCAN, DIGITAL X-RAY, USG WHOLE ABDOMEN, USG ANOMALY SCAN,
      "
      />
    </Helmet>
  );
}
