import {
  DOCTOR_LIST_FAILED,
  DOCTOR_LIST_REQ,
  DOCTOR_LIST_SUCCESS,
  CREATE_DOCTOR_FAILED,
  CREATE_DOCTOR_REQ,
  CREATE_DOCTOR_RESET,
  CREATE_DOCTOR_SUCCESS,
  REMOVE_DOCTOR_REQ,
  REMOVE_DOCTOR_RESET,
  REMOVE_DOCTOR_SUCCESS,
  UPDATE_DOCTOR_FAILED,
  UPDATE_DOCTOR_REQ,
  UPDATE_DOCTOR_RESET,
  UPDATE_DOCTOR_SUCCESS,
} from "../Constant/DoctorConstant";

import axiosConfig from "../Core/axiosConfig";

export const DOCTOR_CREATE = (DOCTOR_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DOCTOR_REQ });
    const { data } = await axiosConfig.post("/DOCTOR", DOCTOR_Data);
    dispatch({ type: CREATE_DOCTOR_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_DOCTOR_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DOCTOR_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_DOCTOR_RESET });
};

export const LIST_DOCTOR = (doctor_query) => async (dispatch, getState) => {
  console.log(doctor_query);
  try {
    dispatch({ type: DOCTOR_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(
      `/doctor?page_no=${doctor_query.page_no}&Doctor_name=${doctor_query.Doctor_name}&page_size=${doctor_query.page_size}`,
      {
        headers: {
          Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
        },
      }
    );
    dispatch({ type: DOCTOR_LIST_SUCCESS, payload: data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: DOCTOR_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const UPDATE_DOCTOR = (DOCTORData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_DOCTOR_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.put("/DOCTOR", DOCTORData, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: UPDATE_DOCTOR_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: UPDATE_DOCTOR_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const RESET_DOCTOR_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_DOCTOR_RESET });
};

export const DELETE_DOCTOR = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_DOCTOR_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/DOCTOR/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_DOCTOR_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_DOCTOR_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_DOCTOR_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_DOCTOR_RESET });
};
