export const CREATE_MEMBER_REQ = "CREATE_MEMBER_REQ";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_FAILED = "CREATE_MEMBER_FAILED";
export const CREATE_MEMBER_RESET = "CREATE_MEMBER_RESET";

export const UPDATE_MEMBER_REQ = "UPDATE_MEMBER_REQ";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_REQ";
export const UPDATE_MEMBER_FAILED = "UPDATE_MEMBER_REQ";
export const UPDATE_MEMBER_RESET = "UPDATE_MEMBER_REQ";

export const MEMBER_LIST_REQ = "MEMBER_LIST_REQ";
export const MEMBER_LIST_SUCCESS = "MEMBER_LIST_SUCCESS";
export const MEMBER_LIST_FAILED = "MEMBER_LIST_FAILED";
export const MEMBER_LIST_RESET = "MEMBER_LIST_RESET";

export const SINGLE_MEMBER_REQ = "SINGLE_MEMBER_REQ";
export const SINGLE_MEMBER_SUCCESS = "SINGLE_MEMBER_SUCCESS";
export const SINGLE_MEMBER_FAILED = "SINGLE_MEMBER_FAILED";
export const SINGLE_MEMBER_RESET = "SINGLE_MEMBER_RESET";

export const REMOVE_MEMBER_REQ = "REMOVE_MEMBER_REQ";
export const REMOVE_MEMBER_SUCCESS = "REMOVE_MEMBER_SUCCESS";
export const REMOVE_MEMBER_FAILED = "REMOVE_MEMBER_FAILED";
export const REMOVE_MEMBER_RESET = "REMOVE_MEMBER_RESET";

export const LOGIN_MEMBER_REQ = "LOGIN_MEMBER_REQ";
export const LOGIN_MEMBER_SUCCESS = "LOGIN_MEMBER_SUCCESS";
export const LOGIN_MEMBER_FAILED = "LOGIN_MEMBER_FAILED";
export const LOGIN_MEMBER_RESET = "LOGIN_MEMBER_RESET";
