import {
  PAYOUT_LIST_FAILED,
  PAYOUT_LIST_REQ,
  PAYOUT_LIST_SUCCESS,
  CREATE_PAYOUT_FAILED,
  CREATE_PAYOUT_REQ,
  CREATE_PAYOUT_RESET,
  CREATE_PAYOUT_SUCCESS,
  REMOVE_PAYOUT_FAILED,
  REMOVE_PAYOUT_REQ,
  REMOVE_PAYOUT_RESET,
  REMOVE_PAYOUT_SUCCESS,
  UPDATE_PAYOUT_FAILED,
  UPDATE_PAYOUT_REQ,
  UPDATE_PAYOUT_RESET,
  UPDATE_PAYOUT_SUCCESS,
} from "../Constant/PayOutConstant";

export const PAYOUT_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case PAYOUT_LIST_REQ: {
      return { PAYOUT_List_Loading: true };
    }

    case PAYOUT_LIST_SUCCESS: {
      console.log(payload);
      return {
        PAYOUT_List_Loading: false,
        PAYOUT_List: payload,
      };
    }
    case PAYOUT_LIST_FAILED: {
      return {
        PAYOUT_List_Loading: false,
        PAYOUT_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const PAYOUT_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_PAYOUT_REQ: {
      return { Create_PAYOUT_Loading: true };
    }
    case CREATE_PAYOUT_SUCCESS: {
      return {
        Create_PAYOUT_Loading: false,
        Create_PAYOUT_Success: payload,
      };
    }

    case CREATE_PAYOUT_FAILED: {
      return {
        Create_PAYOUT_Loading: false,
        Create_PAYOUT_Error: payload,
      };
    }

    case CREATE_PAYOUT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const PAYOUT_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_PAYOUT_REQ: {
      return { Update_PAYOUT_Loading: true };
    }

    case UPDATE_PAYOUT_SUCCESS: {
      return {
        Update_PAYOUT_Loading: false,
        Update_PAYOUT_Success: payload,
      };
    }

    case UPDATE_PAYOUT_FAILED: {
      return {
        Update_PAYOUT_Loading: false,
        Update_PAYOUT_Error: payload,
      };
    }

    case UPDATE_PAYOUT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_PAYOUT_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_PAYOUT_REQ: {
      return { Remove_PAYOUT_Loading: true };
    }

    case REMOVE_PAYOUT_SUCCESS: {
      return {
        Remove_PAYOUT_Loading: false,
        Remove_PAYOUT_Success: payload,
      };
    }

    case REMOVE_PAYOUT_FAILED: {
      return {
        Remove_PAYOUT_Loading: false,
        Remove_PAYOUT_Error: payload,
      };
    }

    case REMOVE_PAYOUT_RESET: {
      return {};
    }

    default:
      return state;
  }
};
