export const CREATE_APPOINTMENT_REQ = "CREATE_APPOINTMENT_REQ";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILED = "CREATE_APPOINTMENT_FAILED";
export const CREATE_APPOINTMENT_RESET = "CREATE_APPOINTMENT_RESET";

export const UPDATE_APPOINTMENT_REQ = "UPDATE_APPOINTMENT_REQ";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAILED = "UPDATE_APPOINTMENT_FAILED";
export const UPDATE_APPOINTMENT_RESET = "UPDATE_APPOINTMENT_RESET";

export const APPOINTMENT_LIST_REQ = "APPOINTMENT_LIST_REQ";
export const APPOINTMENT_LIST_SUCCESS = "APPOINTMENT_LIST_SUCCESS";
export const APPOINTMENT_LIST_FAILED = "APPOINTMENT_LIST_FAILED";
export const APPOINTMENT_LIST_RESET = "APPOINTMENT_LIST_RESET";

export const SINGLE_APPOINTMENT_REQ = "SINGLE_APPOINTMENT_REQ";
export const SINGLE_APPOINTMENT_SUCCESS = "SINGLE_APPOINTMENT_SUCCESS";
export const SINGLE_APPOINTMENT_FAILED = "SINGLE_APPOINTMENT_FAILED";
export const SINGLE_APPOINTMENT_RESET = "SINGLE_APPOINTMENT_RESET";

export const REMOVE_APPOINTMENT_REQ = "REMOVE_APPOINTMENT_REQ";
export const REMOVE_APPOINTMENT_SUCCESS = "REMOVE_APPOINTMENT_SUCCESS";
export const REMOVE_APPOINTMENT_FAILED = "REMOVE_APPOINTMENT_FAILED";
export const REMOVE_APPOINTMENT_RESET = "REMOVE_APPOINTMENT_RESET";
