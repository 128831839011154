import html2canvas from "html2canvas";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function MyCard() {
  const { Login_Member } = useSelector((state) => state.MEMBER_LOGIN_REDUCER);
  function setUpDownloadPageAsImage() {
    const MyCard = document.getElementById("download-page-as-image");
    html2canvas(document.body).then(function (canvas) {
      console.log(canvas);
      simulateDownloadImageClick(canvas.toDataURL(), "file-name.png");
    });
  }

  function simulateDownloadImageClick(uri, filename) {
    var link = document.createElement("a");
    if (typeof link.download !== "string") {
      window.open(uri);
    } else {
      link.href = uri;
      link.download = filename;
      accountForFirefox(clickLink, link);
    }
  }

  function clickLink(link) {
    link.click();
  }

  function accountForFirefox(click) {
    // wrapper function
    let link = arguments[1];
    document.body.appendChild(link);
    click(link);
    document.body.removeChild(link);
  }
  return (
    <>
      <button
        style={{
          borderRadius: "2px",
          padding: "5px 10px",
          backgroundColor: "green",
          color: "white",
          margin: "20px",
        }}
        onClick={setUpDownloadPageAsImage}>
        Download
      </button>
      <div className="card_Id" id="download-page-as-image">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <img style={{ height: "50px" }} src="../assets/Logo.png" alt="logo" />
          <h6>PHOTON DIAGNOSTICS AND RESEARCH CENTER</h6>
        </div>
        <p style={{ textAlign: "center", margin: "0", padding: "0" }}>
          Truly Trusted Tests And Treatment
        </p>
        <p style={{ textAlign: "center" }}>PHOTON HEALTH CARD</p>

        <div style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              marginLeft: "10px",
              fontSize: "24px",
              marginBottom: "10px",
            }}>
            <span>
              <FaUserCircle />
            </span>
            <span>{Login_Member?.MemberName}</span>
          </div>

          <p
            style={{
              padding: "0",
              margin: "0",
              marginLeft: "20px",
            }}>
            Phone: {Login_Member?.Phone}
          </p>
          <p
            style={{
              padding: "0",
              margin: "0",
              marginLeft: "21px",
            }}>
            ID NO: {Login_Member?.MemberId}
          </p>
          <p
            style={{
              padding: "0",
              margin: "0",
              marginLeft: "21px",
            }}>
            Member Exp Date: {Login_Member?.MemberExpiryDate}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0",
            padding: "0",
            color: "white",
            marginTop: "20px",
            fontSize: "12px",
          }}>
          <span>
            ID CARD VALID IN <Link to="/member"> www.photondiagnostics.in</Link>
          </span>
        </div>
      </div>
    </>
  );
}
