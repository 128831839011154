import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

export default function Booking() {
  const navigate = useNavigate();
  const mbl_screen = window.innerWidth;

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div class="Book">
      {mbl_screen >= 480 && (
        <div class="booking-container1">
          <div
            class="booking-item1"
            data-aos="fade-down"
            data-aos-duration="300"
            onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Blood_Test.jpg" alt="Image 1" />
              <h3>Book a Blood Test</h3>
            </figure>
          </div>
          <div
            class="booking-item1"
            data-aos="fade-down"
            data-aos-duration="500"
            onClick={() => navigate("/appointment")}>
            <figure>
              <img
                src="../assets/images/Book/Radiodignosis.jpg"
                alt="Image 2"
              />
              <h3>Book a Radiodiagnosis</h3>{" "}
            </figure>
          </div>
          <div
            class="booking-item1"
            data-aos="fade-down"
            data-aos-duration="700"
            onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Doctor.png" alt="Image 2" />
              <h3>Book a Docotor</h3>{" "}
            </figure>
          </div>
          <div
            class="booking-item1"
            data-aos="fade-down"
            data-aos-duration="900"
            onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Medicine.jpg" alt="Image 2" />
              <h3>Order Medicine</h3>{" "}
            </figure>
          </div>
        </div>
      )}

      {mbl_screen < 480 && (
        <Slider {...settings2}>
          <div class="booking-item1" onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Blood_Test.jpg" alt="Image 1" />
              <h3>Book a Blood Test</h3>
            </figure>
          </div>
          <div class="booking-item1" onClick={() => navigate("/appointment")}>
            <figure>
              <img
                src="../assets/images/Book/Radiodignosis.jpg"
                alt="Image 2"
              />
              <h3>Book a Radiodiagnosis</h3>{" "}
            </figure>
          </div>
          <div class="booking-item1" onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Doctor.jpg" alt="Image 2" />
              <h3>Book a Docotor</h3>{" "}
            </figure>
          </div>
          <div class="booking-item1" onClick={() => navigate("/appointment")}>
            <figure>
              <img src="../assets/images/Book/Medicine.jpg" alt="Image 2" />
              <h3>Order Medicine</h3>{" "}
            </figure>
          </div>
        </Slider>
      )}
    </div>
  );
}
