import {
  REFERAL_LIST_FAILED,
  REFERAL_LIST_REQ,
  REFERAL_LIST_SUCCESS,
} from "../Constant/ReferalConstant";
import axiosConfig from "../Core/axiosConfig";
export const LIST_REFERAL = (ID) => async (dispatch, getState) => {
  console.log(ID);
  try {
    dispatch({ type: REFERAL_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(`/appointment/referal/${ID}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REFERAL_LIST_SUCCESS, payload: data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: REFERAL_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};
