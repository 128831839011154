import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { REGISTER_USER, REGISTER_USER_RESET } from "../../../Action/UserAction";
import {
  DEPARTMENT_CREATE,
  DEPARTMENT_CREATE_RESET,
  RESET_DEPARTMENT_UPDATE,
  UPDATE_DEPARTMENT,
} from "../../../Action/DepartmentAction";

export default function RegisterDepartment({
  Get_Department,
  isOpen,
  onOpen,
  onClose,
  DepartmentEdit,
  setDepartmentEdit,
}) {
  const [DepartmentFormData, setDepartmentFormData] = useState({
    TestName: "",
    TestPrice: "",
    TestDiscount: "",
    TestMemberDiscount: "",
  });
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const toast = useToast();

  const {
    Create_Department_Loading,
    Create_Department_Success,
    Create_Department_Error,
  } = useSelector((state) => state.DEPARTMENT_CREATE_REDUCER);
  const {
    Update_Department_Loading,
    Update_Department_Error,
    Update_Department_Success,
  } = useSelector((state) => state.DEPARTMENT_UPDATE_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setDepartmentFormData({
      ...DepartmentFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(DEPARTMENT_CREATE(DepartmentFormData));
  };

  const handleDepartmentSubmit = (e) => {
    e.preventDefault();
    dispatch(UPDATE_DEPARTMENT(DepartmentFormData));
  };

  useEffect(() => {
    if (Create_Department_Success) {
      toast({
        title: "Success.",
        description: Create_Department_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Department();
      dispatch(DEPARTMENT_CREATE_RESET());
      setDepartmentFormData({
        TestName: "",
        TestPrice: "",
        TestDiscount: "",
        TestMemberDiscount: "",
      });
    }

    if (Create_Department_Error) {
      toast({
        title: "Failed.",
        description: Create_Department_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(DEPARTMENT_CREATE_RESET());
    }
  }, [Create_Department_Success, Create_Department_Error]);

  useEffect(() => {
    if (DepartmentEdit) {
      setDepartmentFormData({ ...DepartmentEdit });
    }
  }, [DepartmentEdit]);

  useEffect(() => {
    if (Update_Department_Success) {
      toast({
        title: "Success.",
        description: Update_Department_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Department();
      dispatch(RESET_DEPARTMENT_UPDATE());
      setDepartmentEdit({});
    }
    if (Update_Department_Error) {
      toast({
        title: "Failed.",
        description: Update_Department_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(RESET_DEPARTMENT_UPDATE());
    }
  }, [Update_Department_Error, Update_Department_Success]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();

          setDepartmentEdit({});
        }}>
        <ModalOverlay />

        <ModalContent>
          <span
            onClick={() => {
              onClose();

              setDepartmentEdit({});
            }}
            className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>{DepartmentEdit ? "EDIT TEST" : "CREATE TEST"}</h1>
                </div>
                <div className="form">
                  <form
                    onSubmit={
                      DepartmentEdit ? handleDepartmentSubmit : handleUserSubmit
                    }>
                    <input
                      type="text"
                      name="TestName"
                      id="MovieName"
                      placeholder="Test Name"
                      value={DepartmentFormData.TestName}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="TestPrice"
                      placeholder="Test Price"
                      value={DepartmentFormData.TestPrice}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="text"
                      name="TestDiscount"
                      placeholder="Test general Discount "
                      value={DepartmentFormData.TestDiscount}
                      onChange={handleFormChange}
                      id="TestDiscount"
                      required
                    />
                    <input
                      type="text"
                      name="TestMemberDiscount"
                      placeholder="Member Discount"
                      value={DepartmentFormData.TestMemberDiscount}
                      onChange={handleFormChange}
                      id="year"
                      required
                    />

                    {DepartmentEdit ? (
                      <button type="submit">
                        {Update_Department_Loading ? "Loading..." : "UPDATE"}
                      </button>
                    ) : (
                      <button type="submit">
                        {Create_Department_Loading ? "Loading..." : "CREATE"}
                      </button>
                    )}
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
