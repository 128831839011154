import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import { useDisclosure } from "@chakra-ui/react";
import RegisterDepartment from "./RegisterDepartment";
import {
  DELETE_DEPARTMENT,
  LIST_DEPARTMENT,
} from "../../../Action/DepartmentAction";
import { useCallback } from "react";
import { Department_Query } from "../../../Model/DepartmentModel";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

export default function Department() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [TestData, setTestData] = useState([]);
  const [DepartmentEdit, setDepartmentEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [DepartmentQuery, setDepartmentQuery] = useState(Department_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const {
    Department_List_Loading,
    Department_List_Error,
    total_Departments,
    Department_List,
  } = useSelector((state) => state.DEPARTMENT_LIST_REDUCER);
  const {
    Remove_Department_Loading,
    Remove_Department_Error,
    Remove_Department_Success,
  } = useSelector((state) => state.REMOVE_DEPARTMENT_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const Get_Department = useCallback(() => {
    dispatch(LIST_DEPARTMENT(DepartmentQuery));
  }, [dispatch, DepartmentQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setDepartmentQuery({
      ...DepartmentQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setDepartmentQuery({ ...DepartmentQuery, Department_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setDepartmentQuery({ ...DepartmentQuery, Department_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setDepartmentQuery({
        ...DepartmentQuery,
        Department_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (el) => {
    setDepartmentEdit(el);
    onOpen();
  };

  const handleDeleteBranch = (ID) => {
    dispatch(DELETE_DEPARTMENT(ID));
  };

  useEffect(() => {
    setDepartmentQuery((DepartmentQuery) => ({
      ...DepartmentQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    Get_Department();
  }, [DepartmentQuery, Get_Department]);

  useEffect(() => {
    if (Department_List) {
      setTestData(Department_List);
    } else {
      setTestData([]);
    }

    if (Department_List_Error) {
      setAlert({ Error: Department_List_Error, validator: false });
    }
  }, [Department_List, Department_List_Error]);

  useEffect(() => {
    if (Remove_Department_Error) {
      setAlert({ Error: Remove_Department_Error, validator: false });
    }

    if (Remove_Department_Success) {
      Get_Department();
      setAlert({ Error: Remove_Department_Success, validator: true });
    }
  }, [Remove_Department_Error, Remove_Department_Success]);

  return (
    <>
      <AdminPanelSidebar page={"DEPARTMENT"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"DEPARTMENT"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>CREATE TEST</button>
            </div>

            <RegisterDepartment
              Get_Department={Get_Department}
              DepartmentEdit={DepartmentEdit}
              setDepartmentEdit={setDepartmentEdit}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th scope="col">Edit/Delete</th>
                      <th scope="col">Test Name</th>
                      <th scope="col">Price</th>
                      <th scope="col">Member Discount</th>
                      <th scope="col">User Discount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Department_List_Loading ? <h2>Loading....</h2> : ""}

                    {Department_List_Error && <h2>{Department_List_Error}</h2>}

                    {TestData?.map((el) => (
                      <tr key={el._id}>
                        <td
                          scope="row"
                          style={{
                            display: "flex",
                            gap: "15px",
                          }}>
                          <span
                            style={{ color: "blue" }}
                            onClick={() => handleEditBranch(el)}>
                            <BiEdit />{" "}
                          </span>
                          <span
                            style={{ color: "red" }}
                            onClick={() => handleDeleteBranch(el._id)}>
                            <MdDelete />
                          </span>
                        </td>
                        <td scope="row">{el.TestName}</td>
                        <td scope="row">{el.TestPrice}</td>
                        <td scope="row">{el.TestMemberDiscount}</td>
                        <td scope="row">{el.TestDiscount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Departments
                  ? total_Departments / DepartmentQuery?.page_size
                  : 0
              )}
              total_record={total_Departments}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
