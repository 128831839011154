import {
  CONTACT_LIST_FAILED,
  CONTACT_LIST_REQ,
  CONTACT_LIST_SUCCESS,
  CREATE_CONTACT_FAILED,
  CREATE_CONTACT_REQ,
  CREATE_CONTACT_RESET,
  CREATE_CONTACT_SUCCESS,
} from "../Constant/ContactConstant";

import axiosConfig from "../Core/axiosConfig";

export const CONTACT_CREATE = (CONTACT_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CONTACT_REQ });
    const { data } = await axiosConfig.post("/CONTACT", CONTACT_Data);
    dispatch({ type: CREATE_CONTACT_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_CONTACT_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const CONTACT_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_CONTACT_RESET });
};

export const LIST_CONTACT = (contact_query) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(
      `/contact?page_no=${contact_query.page_no}&contact_name=${contact_query.contact_name}&page_size=${contact_query.page_size}`,
      {
        headers: {
          Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
        },
      }
    );
    dispatch({ type: CONTACT_LIST_SUCCESS, payload: data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CONTACT_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};
