import { useNavigate } from "react-router-dom";

export default function Offer() {
  const navigate = useNavigate();

  return (
    <div className="Offer" onClick={() => navigate("/offers")}>
      <img src="../../assets/images/Offer.png" alt="offer" />
    </div>
  );
}
