import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import RegisterAppointment from "./RegisterAppointment";
import { MdDownloadForOffline } from "react-icons/md";

import { useDisclosure } from "@chakra-ui/react";
import {
  DELETE_APPOINTMENT,
  LIST_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from "../../../Action/AppointmentAction";
import { useCallback } from "react";
import { Appointment_Query } from "../../../Model/DepartmentModel";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

export default function AppointmentAdmin() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [AppointmentData, setAppointmentData] = useState([]);
  const [AppointmentEdit, setAppointmentEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [AppointmentQuery, setAppointmentQuery] = useState(Appointment_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const {
    APPOINTMENT_List_Loading,
    total_Appointments,
    APPOINTMENT_List_Error,
    APPOINTMENT_List,
  } = useSelector((state) => state.APPOINTMENT_LIST_REDUCER);
  const {
    Remove_Appointment_Loading,
    Remove_Appointment_Success,
    Remove_Appointment_Error,
  } = useSelector((state) => state.REMOVE_APPOINTMENT_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const GET_Appointment = useCallback(() => {
    dispatch(LIST_APPOINTMENT(AppointmentQuery));
  }, [dispatch, AppointmentQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setAppointmentQuery({
      ...AppointmentQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setAppointmentQuery({ ...AppointmentQuery, Appointment_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setAppointmentQuery({ ...AppointmentQuery, Appointment_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setAppointmentQuery({
        ...AppointmentQuery,
        Appointment_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (el) => {
    setAppointmentEdit(el);
    onOpen();
  };

  const handleDeleteBranch = (ID) => {
    dispatch(DELETE_APPOINTMENT(ID));
  };

  useEffect(() => {
    setAppointmentQuery((AppointmentQuery) => ({
      ...AppointmentQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    GET_Appointment();
  }, [AppointmentQuery, GET_Appointment, Remove_Appointment_Success]);

  useEffect(() => {
    if (APPOINTMENT_List) {
      setAppointmentData(APPOINTMENT_List);
    } else {
      setAppointmentData([]);
    }

    if (APPOINTMENT_List_Error) {
      setAlert({ Error: APPOINTMENT_List_Error, validator: false });
    }
  }, [APPOINTMENT_List, APPOINTMENT_List_Error]);

  return (
    <>
      <AdminPanelSidebar page={"APPOINTMENT"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"APPOINTMENT"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>Create Appointment</button>
            </div>

            <RegisterAppointment
              GET_Appointment={GET_Appointment}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
              AppointmentEdit={AppointmentEdit}
              setAppointmentEdit={setAppointmentEdit}
            />

            <div>
              <div
                // style={{ width: "100%", overflow: "scroll" }}
                className="table-responsive">
                <table
                  style={{
                    whiteSpace: "nowrap",
                    height: "400px",
                    overflow: "scroll",
                  }}
                  className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Edit/Delete</th>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email </th>
                      <th scope="col">Age </th>
                      <th scope="col">Doctor</th>
                      <th scope="col">Test</th>
                      <th scope="col">Refer by</th>
                      <th scope="col">Time</th>
                      <th scope="col">PromoCode</th>
                      <th scope="col">Actual Price</th>
                      <th scope="col">Test Price</th>
                      <th scope="col">Message</th>
                      <th scope="col">Member Price</th>
                      <th scope="col">Report</th>
                      <th scope="col"> Refer Confirm </th>
                    </tr>
                  </thead>
                  <tbody>
                    {APPOINTMENT_List_Loading ? <p>Loading....</p> : ""}

                    {APPOINTMENT_List_Error && (
                      <h2>{APPOINTMENT_List_Error}</h2>
                    )}

                    {AppointmentData?.map((el) => (
                      <tr key={el._id}>
                        <td
                          scope="row"
                          style={{
                            display: "flex",
                            gap: "15px",
                          }}>
                          <span
                            style={{ color: "blue" }}
                            onClick={() => handleEditBranch(el)}>
                            <BiEdit />{" "}
                          </span>
                          <span
                            style={{ color: "red" }}
                            onClick={() => handleDeleteBranch(el._id)}>
                            <MdDelete />
                          </span>
                        </td>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.Age}</td>
                        <td scope="row">{el.Doctor?.Name}</td>
                        <td scope="row">
                          {el.Department?.map((el) => (
                            <span>
                              {el.TestName}
                              {" ,"}
                            </span>
                          ))}
                        </td>
                        <td scope="row">
                          {el.ReferId?.MemberId
                            ? el.ReferId?.MemberId
                            : "No Refer.."}{" "}
                        </td>
                        <td scope="row">{el.Time} </td>
                        <th scope="col">
                          {el.PromoCode ? el.PromoCode : "No PromoCode"}
                        </th>
                        <td scope="row">₹ {el.TotalPrice} </td>
                        <td scope="row">₹ {el.TestPrice} </td>
                        <td scope="row">{el.Message} </td>
                        <td
                          scope="row"
                          style={
                            el.ReferPayment
                              ? { color: "green" }
                              : { color: "red" }
                          }>
                          ₹ {el.isMember ? el.MemberPrice : "N/A"}{" "}
                        </td>
                        <td>
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "10px",
                              color: el.Report ? "green" : "grey",
                            }}
                            href={el.Report}
                            target="_blank"
                            rel="noreferrer"
                            download={true}>
                            <span>
                              {el.Report ? <MdDownloadForOffline /> : ""}
                            </span>
                            <span>{el.Report ? "Download Report" : "N/A"}</span>
                          </a>
                        </td>
                        <td scope="row">
                          <button
                            onClick={() =>
                              dispatch(
                                UPDATE_APPOINTMENT({
                                  ...el,
                                  ReferPayment: !el.ReferPayment,
                                })
                              )
                            }
                            style={
                              el?.isPaid
                                ? {
                                    padding: "10px",
                                    background: "grey",
                                    borderRadius: "6px",
                                    color: "white",
                                  }
                                : el.ReferPayment
                                ? {
                                    padding: "10px",
                                    background: "red",
                                    borderRadius: "6px",
                                    color: "white",
                                  }
                                : {
                                    padding: "10px",
                                    background: !el.ReferId ? "grey" : "green",
                                    borderRadius: "6px",
                                    color: "white",
                                  }
                            }
                            disabled={!el.ReferId || el.isPaid}>
                            {!el.ReferId
                              ? "No Refer"
                              : el.isPaid
                              ? "PAID"
                              : el.ReferPayment
                              ? "STOP PAYMENT"
                              : "CONFIRM PAYMENT"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Appointments
                  ? total_Appointments / AppointmentQuery?.page_size
                  : 0
              )}
              total_record={total_Appointments}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
