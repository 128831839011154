import { Link, Navigate, useNavigate } from "react-router-dom";
import { MdFamilyRestroom } from "react-icons/md";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Slider from "react-slick";
import { HiBadgeCheck, HiOutlineLocationMarker } from "react-icons/hi";
import { BsFillHeartPulseFill, BsStarFill } from "react-icons/bs";
import { LuPhoneCall } from "react-icons/lu";
import { GiMedicalPack } from "react-icons/gi";
import { TbReportMedical } from "react-icons/tb";
import { RiDirectionFill } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { useEffect } from "react";
import Aos from "aos";
import Booking from "./Booking";
import { Icon } from "@iconify/react";
import Meta from "../../Components/Meta";
import HearAbout from "./HearAbout";
export default function Home() {
  const mbl_screen = window.innerWidth;

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);
  const MetaDes = {
    title:
      "PHOTON DIAGNOSTICS AND RESEARCH CENTRE| Dr. Sahidul Islam | MBBS,MD Radiodiagnosis",
    desc: "We offer 24/7 emergency support and have implemented the concept of family medicine. Feel free to reach out to us for any urgent medical assistance.",
  };

  return (
    <div id="top">
      <Meta MetaDes={MetaDes} />
      <Header Page={"Home"} />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xl-7">
              <div className="block">
                <div className="divider mb-3"></div>
                <span
                  className="text-sm letter-spacing Hand_Text"
                  data-aos="fade-down"
                  data-aos-duration="500">
                  PHOTON DIAGNOSTICS AND RESEARCH CENTRE
                </span>
                <h1
                  className="mb-3 mt-3 Hand_Text"
                  data-aos="fade-down"
                  data-aos-duration="700"
                  style={{ color: "#00632a", textDecoration: "none" }}>
                  <span style={{ fontWeight: "500", textDecoration: "none" }}>
                    Your health home for
                  </span>
                  <br />
                  "Truly Trusted Tests and Treatment"
                </h1>

                <p
                  className="mb-4 pr-5"
                  data-aos="fade-down"
                  data-aos-duration="900">
                  Welcome to our cutting edge diagnostics and clinic. Health is
                  Wealth. Trustworthiness matters most in terms of healthcare.
                  We provide Fast, Accurate & Affordable healthcare facilities
                  all at one place with Expert Doctors, Experienced Technician
                  and High end Machines. Your Hassle free Health Journey Starts
                  Here.
                </p>
                <div
                  style={
                    mbl_screen <= 480
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "15px",
                        }
                      : { alignItems: "center" }
                  }
                  className="btn-container ">
                  <Link
                    to="/appointment"
                    target="_blank"
                    style={{ fontSize: "14px" }}
                    className="btn btn-main-2 btn-icon btn-round-full">
                    Start your Health Transformation Today
                    {/*<i className="icofont-simple-right ml-2  "></i>*/}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  mbl_screen > 480 && (
        <section className="features">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="feature-block d-lg-flex">
                  <div
                    className="feature-item mb-5 mb-lg-0"
                    data-aos="fade-down"
                    data-aos-duration="400"
                    data-aos-easing="linear">
                    <div className="feature-icon mb-4">
                      <i className="icofont-surgeon-alt"></i>
                    </div>
                    <span>24 Hours Service</span>
                    <h4 className="mb-3">Online Appoinment</h4>
                    <p className="mb-4">
                      We offer 24/7 emergency support and have implemented the
                      concept of family medicine. Feel free to reach out to us
                      for any urgent medical assistance.
                    </p>
                    <Link
                      to="/appointment"
                      className="btn btn-main btn-round-full">
                      Make a appoinment
                    </Link>
                  </div>

                  <div
                    className="feature-item mb-5 mb-lg-0"
                    data-aos="fade-down"
                    data-aos-duration="550">
                    <div className="feature-icon mb-4">
                      <i className="icofont-ui-clock"></i>
                    </div>
                    <span>Timing schedule</span>
                    <h4 className="mb-3">Working Hours</h4>
                    <ul className="w-hours list-unstyled">
                      <li className="d-flex justify-content-between">
                        Mon - Sat : <span>9:00 - 15:00</span>
                      </li>
                      <li className="d-flex justify-content-between">
                        only - sun : <span>9:00 - 14:00</span>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="feature-item mb-5 mb-lg-0"
                    data-aos="fade-down"
                    data-aos-duration="800">
                    <div className="feature-icon mb-4">
                      <i className="icofont-support"></i>
                    </div>
                    <span>Emegency Cases</span>
                    <h4 className="mb-3">+91-840-2030-401</h4>
                    <p>
                      We provide round-the-clock support for emergencies. We
                      have implemented the concept of family medicine. Contact
                      us immediately for any urgent medical needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )*/}

      {mbl_screen <= 480 && (
        <section>
          <div>
            <div className="feature-block d-lg-flex">
              <Slider {...settings2}>
                <div className="feature-item">
                  <div className="feature-icon mb-4">
                    <i className="icofont-surgeon-alt"></i>
                  </div>
                  <span>24 Hours Service</span>
                  <h4 className="mb-3">Online Appointment</h4>
                  <p className="mb-4">
                    We offer 24/7 emergency support and have implemented the
                    concept of family medicine. Feel free to reach out to us for
                    any urgent medical assistance.
                  </p>
                  <Link
                    to="/appointment"
                    style={{ color: "#033f7c" }}
                    className="btn btn-main btn-round-full">
                    Book an appointment
                  </Link>
                </div>
                <div>
                  <video
                    style={{ width: "100%", height: "400px" }}
                    loop
                    autoPlay
                    unmuted>
                    <source
                      src={"../assets/Photon_Video.mp4"}
                      type="video/mp4"
                    />
                  </video>
                </div>

                <div className="feature-item ">
                  <div className="feature-icon mb-4">
                    <i className="icofont-support"></i>
                  </div>
                  <span>Emergency Cases</span>
                  <h4 className="mb-3">+918402030401</h4>
                  <p>
                    We provide round-the-clock support for emergencies. We have
                    implemented the concept of family medicine. Contact us
                    immediately for any urgent medical needs.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      )}
      {mbl_screen > 480 && (
        <section>
          <div className="container">
            <video
              style={{ width: "100%", height: "400px" }}
              loop
              autoPlay
              muted>
              <source src={"../assets/Photon_Video.mp4"} type="video/mp4" />
            </video>
          </div>
        </section>
      )}

      <section className="section about">
        <div
          className="container Book_section"
          style={{ display: "flex", justifyContent: "space-between" }}>
          <a href="https://maps.app.goo.gl/JqQ3aNBmmRhmtgAy6">
            <span>
              <HiOutlineLocationMarker />
            </span>
            <p>Location</p>
          </a>
          <a href="tel:+918402030401">
            <span>
              <LuPhoneCall />
            </span>
            <p>Call Us</p>
          </a>
          <Link to="/appointment">
            <span>
              <GiMedicalPack />
            </span>
            <p>Book Now</p>
          </Link>
          <Link to="/member">
            <span>
              <MdFamilyRestroom />
            </span>
            <p>Membership</p>
          </Link>
          <a href="#">
            <span>
              <BsFillHeartPulseFill />
            </span>
            <p>Health Image</p>
          </a>
          <a href="#">
            <span>
              <TbReportMedical />
            </span>
            <p>Reports</p>
          </a>
        </div>
      </section>

      {/* Scrolled Section 2 */}
      <section style={{ width: "100%", margin: "auto", overflow: "hidden" }}>
        <div className={"Rotation"}>
          <div className={"Loop_text marqueeStyle"}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Free Membership</span>
          </div>
          <div className={"Loop_text marqueeStyle"}>
            <span>
              <HiBadgeCheck />
            </span>
            <span>Online Booking Doctors</span>
          </div>
          {mbl_screen >= 600 ? (
            <>
              {" "}
              <div
                onClick={() => Navigate("/member")}
                className="Loop_text marqueeStyle">
                <span>
                  <HiBadgeCheck />
                </span>
                <span>Free Membership</span>
              </div>
              <div
                onClick={() => Navigate("/appointment")}
                className="Loop_text marqueeStyle">
                <span>
                  <HiBadgeCheck />
                </span>
                <span>Online Booking Doctors</span>
              </div>
              <div className="Loop_text marqueeStyle">
                <span>
                  <HiBadgeCheck />
                </span>
                <span>Truly Trusted Center</span>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </section>

      {/* Doctors */}

      {mbl_screen <= 480 && (
        <section className="section about">
          <div className="section-title">
            <h2 style={{ textAlign: "center" }}>Our Experts Doctors</h2>
            <div className="divider mx-auto my-4"></div>
            <p style={{ textAlign: "center", padding: "0", margin: "0" }}>
              Expert Doctors Utilize Cutting-Edge Ultrasound and CT Scans for
              Comprehensive Diagnostics. Experience Personalized Care and
              Unparalleled Expertise at PHOTON DIAGNOSTICS AND RESEARCH CENTER.
              Your Well-Being is Our Priority!
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div className="container">
              <Slider
                {...settings2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <div
                  class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
                  data-groups='["cat2"]'
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <div class="position-relative doctor-inner-box">
                    <div class="doctor-profile">
                      <div class="doctor-img">
                        <img
                          src="../assets/images/Doctor/Dt1.jpeg"
                          alt="doctor-image"
                          class="img-fluid w-100"
                        />
                      </div>
                    </div>
                    <div class="content mt-3" style={{ textAlign: "center" }}>
                      <h4 class="mb-0">
                        <a href="doctor-single.html">DR.SAHIDUL ISLAM</a>
                      </h4>
                      <p>MBBS,MD RADIODIAGNOSIS</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
                  data-groups='["cat2"]'>
                  <div class="position-relative doctor-inner-box">
                    <div class="doctor-profile">
                      <div class="doctor-img">
                        <img
                          src="../assets/images/Doctor/Dt2.jpeg"
                          alt="doctor-image"
                          class="img-fluid w-100"
                        />
                      </div>
                    </div>
                    <div class="content mt-3" style={{ textAlign: "center" }}>
                      <h4 class="mb-0">
                        <a href="doctor-single.html">Dr Emdad Hussain</a>
                      </h4>
                      <p>MBBS, MD, F.Diab (Diabetes)</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-sm-6 col-md-6 mb-4 shuffle-item"
                  data-groups='["cat2"]'>
                  <div class="position-relative doctor-inner-box">
                    <div class="doctor-profile">
                      <div class="doctor-img">
                        <img
                          src="../assets/images/Doctor/Dt3.jpeg"
                          alt="doctor-image"
                          class="img-fluid w-100"
                        />
                      </div>
                    </div>
                    <div class="content mt-3" style={{ textAlign: "center" }}>
                      <h4 class="mb-0">
                        <a href="doctor-single.html">DR.ARIFUL ISLAM</a>
                      </h4>
                      <p>MBBS,MD MEDICINE</p>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
      )}

      {mbl_screen > 480 && (
        <section className="section about">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-4 col-sm-6"
                data-aos="fade-right"
                data-aos-duration="400">
                <div className="about-img">
                  <img
                    src="../assets/images/service/xRay.png"
                    alt=""
                    className="img-fluid"
                  />
                  <img
                    src="./assets/images/service/Blood_test.jpg"
                    alt=""
                    className="img-fluid mt-4"
                  />
                </div>
              </div>
              <div
                className="col-lg-4 col-sm-6"
                data-aos="fade-right"
                data-aos-duration="600">
                <div className="about-img mt-4 mt-lg-0">
                  <img
                    src="./assets/images/service/Skilled_nurse.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-left"
                data-aos-duration="300">
                <div className="about-content pl-4 mt-4 mt-lg-0">
                  <h2 className="title-color">
                    Personal care <br />& healthy living
                  </h2>
                  <p className="mt-4 mb-5">
                    We offer the best and leading medical services, ensuring
                    excellent patient care with unmatched expertise and
                    compassionate approach.
                  </p>

                  <Link
                    to="/services"
                    className="btn btn-main-2 btn-round-full btn-icon">
                    Services<i className="icofont-simple-right ml-3"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <section className="section appoinment">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 "
              data-aos="fade-right"
              data-aos-duration="600"
            >
              <div className="appoinment-content">
                <img
                  src="images/about/img-3.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="emergency">
                  <h2 className="text-lg">
                    <i className="icofont-phone-circle text-lg"></i>
                    +918402030401
                  </h2>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-10">
              <div className="appoinment-wrap mt-5 mt-lg-0">
                <h2
                  className="mb-2 title-color"
                  data-aos="fade-down"
                  data-aos-duration="400"
                >
                  Book appoinment
                </h2>
                <p
                  className="mb-4"
                  data-aos="fade-down"
                  data-aos-duration="300"
                >
                  Mollitia dicta commodi est recusandae iste, natus eum
                  asperiores corrupti qui velit . Iste dolorum atque similique
                  praesentium soluta.
                </p>
                <form
                  id="#"
                  className="appoinment-form"
                  method="post"
                  action="#"
                >
                  <div className="row">
                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="300"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                        >
                          <option>Choose Department</option>
                          <option>Software Design</option>
                          <option>Development cycle</option>
                          <option>Software Development</option>
                          <option>Maintenance</option>
                          <option>Process Query</option>
                          <option>Cost and Duration</option>
                          <option>Modal Delivery</option>
                        </select>
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="500"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="exampleFormControlSelect2"
                        >
                          <option>Select Doctors</option>
                          <option>Software Design</option>
                          <option>Development cycle</option>
                          <option>Software Development</option>
                          <option>Maintenance</option>
                          <option>Process Query</option>
                          <option>Cost and Duration</option>
                          <option>Modal Delivery</option>
                        </select>
                      </div>
                    </div>

                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="600"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <input
                          name="date"
                          id="date"
                          type="text"
                          className="form-control"
                          placeholder="dd/mm/yyyy"
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <input
                          name="time"
                          id="time"
                          type="text"
                          className="form-control"
                          placeholder="Time"
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="850"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                        />
                      </div>
                    </div>

                    <div
                      className="col-lg-6"
                      data-aos="fade-down"
                      data-aos-duration="900"
                      data-aos-easing="linear"
                    >
                      <div className="form-group">
                        <input
                          name="phone"
                          id="phone"
                          type="Number"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-group-2 mb-4"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                    data-aos-easing="linear"
                  >
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="6"
                      placeholder="Your Message"
                    ></textarea>
                  </div>

                  <a
                    className="btn btn-main btn-round-full"
                    href="appoinment.html"
                    data-aos="fade-down"
                    data-aos-duration="1300"
                  >
                    Make Appoinment{" "}
                    <i className="icofont-simple-right ml-2  "></i>
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="cta-section ">
        <div className="container">
          <div className="cta position-relative">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-doctor"></i>
                  <span className="h3 counter" data-count="58">
                    0
                  </span>
                  k<p>Happy People</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-flag"></i>
                  <span className="h3 counter" data-count="700">
                    0
                  </span>
                  +<p>Surgery Comepleted</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-badge"></i>
                  <span className="h3 counter" data-count="40">
                    0
                  </span>
                  +<p>Expert Doctors</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="counter-stat">
                  <i className="icofont-globe"></i>
                  <span className="h3 counter" data-count="20">
                    0
                  </span>
                  <p>Worldwide Branch</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section data-aos="zoom-out" style={{ marginTop: "20px" }}>
        <div className="section-title">
          <h2 style={{ textAlign: "center" }}>Book an Appointment</h2>
          <div className="divider mx-auto my-4"></div>

          {mbl_screen < 480 ? (
            <div className="Book2">
              <Slider {...settings2}>
                <div
                  class="booking-item1"
                  onClick={() => navigate("/appointment")}>
                  <figure>
                    <img
                      src="../assets/images/Book/Blood_Test.jpg"
                      alt="Image_1"
                    />
                    <h3>Book a Blood Test</h3>
                  </figure>
                </div>
                <div
                  class="booking-item1"
                  onClick={() => navigate("/appointment")}>
                  <figure>
                    <img
                      src="../assets/images/Book/Radiodignosis.jpg"
                      alt="Image_2"
                    />
                    <h3>Book a Radiodiagnosis</h3>{" "}
                  </figure>
                </div>
                <div
                  class="booking-item1"
                  onClick={() => navigate("/appointment")}>
                  <figure>
                    <img
                      style={{ height: "170px" }}
                      src="../assets/images/Book/Doctor.png"
                      alt="Image_2"
                    />
                    <h3>Book a Docotor</h3>{" "}
                  </figure>
                </div>
                <div
                  class="booking-item1"
                  onClick={() => navigate("/appointment")}>
                  <figure>
                    <img
                      src="../assets/images/Book/Medicine.jpg"
                      alt="Image_2"
                    />
                    <h3>Order Medicine</h3>{" "}
                  </figure>
                </div>
              </Slider>
            </div>
          ) : (
            <Booking />
          )}
        </div>
      </section>

      <section className="Map">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0",
            padding: "0",
          }}
          className="section-title ">
          <h2 style={{ margin: "0", padding: "0" }}>Locate Us</h2>
          <div className="divider mx-auto my-4"></div>
        </div>
        <div style={{ position: "relative" }}>
          <div
            style={{
              width: "100%",
              padding: "10px 10px 5px 15px",

              position: "absolute",
              top: "0px",
              backgroundColor: "#00632a",
              color: "white",
            }}>
            <h6 style={{ color: "white", fontSize: "14px" }}>
              PHOTON DIAGNOSTICS AND RESEARCH CENTRE - ASSAM
            </h6>
            <p style={{ color: "white", fontSize: "12px" }}>
              Near FAAMCH, Barpeta-Hospital-Jania Rd,
              <br /> Bhera Gaon, Jaher Pam,
              <br /> Assam 781314
            </p>
          </div>
          <div>
            <img src="../assets/images/map.jpg" alt="map" />
          </div>
          <div
            style={{
              width: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 15px",
              alignItems: "center",
              bottom: "20px",
            }}>
            <a href="https://maps.app.goo.gl/JqQ3aNBmmRhmtgAy6">
              <button>
                <span>
                  <RiDirectionFill />
                </span>
                <span>Direction </span>
              </button>
            </a>

            <a href="tel:+8402030401">
              <button>
                <span>
                  <FiPhoneCall />
                </span>
                <span>Call </span>
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="section service gray-bg" data-aos="zoom-out">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <div className="section-title">
                <h2>Why Patient Choose Us ?</h2>
                <div className="divider mx-auto my-4"></div>
                <p>
                  We are leading Diagnostics and Healthcare service provider. We
                  provide One Stop Solution for your health
                  issues-DOCTORS-DIAGNOSTICS-MEDICINE.
                </p>
                <div> </div>

                {/* */}
                <div className="Choose_me" style={{ marginTop: "0px" }}>
                  <div class="content">
                    {/*<div class="content__container">
            
                      <ul class="content__container__list">
                        <li class="content__container__list__item">
                          Fast, Accurate & Affordable Tests Result
                        </li>
                        <li class="content__container__list__item">
                          Expert Doctors almost in all Departments
                        </li>
                        <li class="content__container__list__item">
                          In House Radiologist
                        </li>
                        <li class="content__container__list__item">
                          State-of-the-art Technology
                        </li>
                      </ul>
          </div>*/}
                  </div>{" "}
                  <img
                    style={{ width: "100%" }}
                    src="./assets/why_Choose.jpg"
                    alt="logo"
                  />{" "}
                </div>

                {/* */}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "0",
              padding: "0",
              marginTop: "40px",
            }}
            className="section-title ">
            <h2 style={{ margin: "0", padding: "0" }}>Our Popular Tests</h2>
            <div className="divider mx-auto my-4"></div>
          </div>

          {mbl_screen > 480 && (
            <div className="row">
              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="500">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-laboratory text-lg"></i>
                    <h4 className="mt-3 mb-3">X-Ray</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">
                      Experience the latest in X-ray imaging at our diagnostic
                      center, where our skilled radiologists use advanced
                      technology for accurate and efficient medical evaluations.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="800">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Ecg Testing</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Trust our proficient medical team to conduct reliable ECG
                      testing, providing valuable insights into cardiac health
                      and aiding in the diagnosis of heart-related conditions.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="900">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-Xray text-lg"></i>
                    <h4 className="mt-3 mb-3">Pharmacy</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Safe and efficient imaging to diagnose medical conditions
                      accurately. State-of-the-art X-ray services at Photon
                      Diagnostic and research center.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="900">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <Icon icon="fontisto:blood-test" />
                    <h4 className="mt-3 mb-3">Blood tests</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">
                      Vital assessments of your health with quick and accurate
                      results. Experience top-notch diagnostics at Photon
                      Diagnostic and research center.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="1100">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-brain-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Ultrasound</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Non-invasive imaging for visualizing internal organs and
                      pregnancies. Precise diagnostics at Photon Diagnostic and
                      research center.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="1300">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Thyroid Test</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Get your thyroid health assessed with our specialized
                      thyroid testing, providing crucial insights for proper
                      diagnosis and personalized treatment plans.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {mbl_screen <= 480 && (
            <Slider {...settings2}>
              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                data-aos-duration="500">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-laboratory text-lg"></i>
                    <h4 className="mt-3 mb-3">X-Ray</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">
                      Experience the latest in X-ray imaging at our diagnostic
                      center, where our skilled radiologists use advanced
                      technology for accurate and efficient medical evaluations.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                data-aos-duration="800">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Thyroid Test</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Get your thyroid health assessed with our specialized
                      thyroid testing, providing crucial insights for proper
                      diagnosis and personalized treatment plans.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                style={{ boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px" }}
                data-aos-duration="900">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Ecg Testing</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Trust our proficient medical team to conduct reliable ECG
                      testing, providing valuable insights into cardiac health
                      and aiding in the diagnosis of heart-related conditions.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="900">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <Icon icon="fontisto:blood-test" />
                    <h4 className="mt-3 mb-3">Blood tests</h4>
                  </div>

                  <div className="content">
                    <p className="mb-4">
                      Vital assessments of your health with quick and accurate
                      results. Experience top-notch diagnostics at Photon
                      Diagnostic and research center.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="1100">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-brain-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Ultrasound</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Non-invasive imaging for visualizing internal organs and
                      pregnancies. Precise diagnostics at Photon Diagnostic and
                      research center.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 col-sm-6"
                data-aos="fade-down-right"
                data-aos-duration="1300">
                <div className="service-item mb-4">
                  <div className="icon d-flex align-items-center">
                    <i className="icofont-heart-beat-alt text-lg"></i>
                    <h4 className="mt-3 mb-3">Pharmacy</h4>
                  </div>
                  <div className="content">
                    <p className="mb-4">
                      Advanced imaging for detailed cross-sectional views of the
                      body. High-quality diagnostics available at Photon
                      Diagnostic and research center.
                    </p>
                  </div>
                </div>
              </div>
            </Slider>
          )}
        </div>
      </section>

      {/* <section className="section testimonial-2 gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>We served over 5000+ Patients</h2>
                <div className="divider mx-auto my-4"></div>
                <p>
                  Let us know more about the necessary pains, difficulties, and
                  troubles that we may encounter in life. We all face challenges
                  and sufferings.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 testimonial-wrap-2">
              <Slider {...settings2}>
                <div className="testimonial-block style-2  gray-bg">
                  <i className="icofont-quote-right"></i>

                  <div className="testimonial-thumb">
                    <img
                      src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="">
                    <h4>Rustam Ali Khan</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px 0",
                        alignItems: "center",
                      }}>
                      <span>Rating: </span>{" "}
                      <span
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: "rgb(255,215,0)",
                        }}>
                        {" "}
                        <BsStarFill /> <BsStarFill />
                        <BsStarFill /> <BsStarFill /> <BsStarFill />
                      </span>{" "}
                    </div>

                    <p>
                      Best diagnostic centre in Barpeta and entire lower Assam.
                      Staff, service all are outstanding.
                    </p>
                  </div>
                </div>

                <div className="testimonial-block style-2  gray-bg">
                  <div className="testimonial-thumb">
                    <img
                      src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="client-info">
                    <h4>jakir Hussain</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px 0",
                        alignItems: "center",
                      }}>
                      <span>Rating: </span>{" "}
                      <span
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: "rgb(255,215,0)",
                        }}>
                        {" "}
                        <BsStarFill /> <BsStarFill />
                        <BsStarFill /> <BsStarFill /> <BsStarFill />
                      </span>{" "}
                    </div>
                    <p>
                      World class testing equipments with a top most Radiologist
                      implies the centre a most reliable for all of us. All the
                      staff's behaviours are so good and polite And overall
                      prices are also reasonable. Photon diagnostic is better
                      than any other private laboratories in Barpeta as I have
                      checked more than several other laboratories for test
                      before going to there. Highly recommended. A++.
                    </p>
                  </div>

                  <i className="icofont-quote-right"></i>
                </div>

                <div className="testimonial-block style-2  gray-bg">
                  <div className="testimonial-thumb">
                    <img
                      src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="client-info">
                    <h4>Rahul Thakuria</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px 0",
                        alignItems: "center",
                      }}>
                      <span>Rating: </span>{" "}
                      <span
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: "rgb(255,215,0)",
                        }}>
                        {" "}
                        <BsStarFill /> <BsStarFill />
                        <BsStarFill /> <BsStarFill /> <BsStarFill />
                      </span>{" "}
                    </div>
                    <p>
                      It's a one stop solution for your doctor consultations and
                      diagnostics.. Highly experienced doctors and support
                      staffs.Excellent service and support from the doctors &
                      staffs.. Fantastic infrastructure. Highly recommended...
                    </p>
                  </div>

                  <i className="icofont-quote-right"></i>
                </div>

                <div className="testimonial-block style-2  gray-bg">
                  <div className="testimonial-thumb">
                    <img
                      src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="client-info">
                    <h4>Selima Akhtar</h4>
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        padding: "10px 0",
                        alignItems: "center",
                      }}>
                      <span>Rating: </span>{" "}
                      <span
                        style={{
                          display: "flex",
                          gap: "8px",
                          color: "rgb(255,215,0)",
                        }}>
                        {" "}
                        <BsStarFill /> <BsStarFill />
                        <BsStarFill /> <BsStarFill /> <BsStarFill />
                      </span>{" "}
                    </div>
                    <p className="mt-4">
                      All the staff,Services are really good.Thank you Photon
                      Diagnostic centre team for such a delightful services.keep
                      up the good work.
                    </p>
                  </div>
                  <i className="icofont-quote-right"></i>
                </div>

                <div className="testimonial-block style-2  gray-bg">
                  <div className="testimonial-thumb">
                    <img
                      src="./assets/images/team/test-thumb1.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>

                  <div className="client-info">
                    <h4>Modern Service!</h4>
                    <span>Kolis Mullar</span>
                    <p>
                      Let us know more about the necessary pains, difficulties,
                      and troubles that we may encounter in life. We all face
                      challenges and sufferings.
                    </p>
                  </div>
                  <i className="icofont-quote-right"></i>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> <section className="section clients">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2>Partners who support us</h2>
                <div className="divider mx-auto my-4"></div>
                <p>
                  Lets know moreel necessitatibus dolor asperiores illum
                  possimus sint voluptates incidunt molestias nostrum
                  laudantium. Maiores porro cumque quaerat.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row clients-logo">
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/1.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/2.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/3.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/4.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/5.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/6.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/3.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/4.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/5.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="client-thumb">
                <img
                  src="./assets/images/about/6.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <HearAbout />
      <Footer />
    </div>
  );
}
