import {
  PAYOUT_LIST_FAILED,
  PAYOUT_LIST_REQ,
  PAYOUT_LIST_SUCCESS,
  CREATE_PAYOUT_FAILED,
  CREATE_PAYOUT_REQ,
  CREATE_PAYOUT_RESET,
  CREATE_PAYOUT_SUCCESS,
  REMOVE_PAYOUT_REQ,
  REMOVE_PAYOUT_RESET,
  REMOVE_PAYOUT_SUCCESS,
  UPDATE_PAYOUT_FAILED,
  UPDATE_PAYOUT_REQ,
  UPDATE_PAYOUT_RESET,
  UPDATE_PAYOUT_SUCCESS,
  LOGIN_PAYOUT_REQ,
  LOGIN_PAYOUT_SUCCESS,
  LOGIN_PAYOUT_FAILED,
  LOGIN_PAYOUT_RESET,
} from "../Constant/PayOutConstant";

import axiosConfig from "../Core/axiosConfig";

export const PAYOUT_CREATE = (PAYOUT_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PAYOUT_REQ });
    const { data } = await axiosConfig.post("/payout", PAYOUT_Data);
    dispatch({ type: CREATE_PAYOUT_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_PAYOUT_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const PAYOUT_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_PAYOUT_RESET });
};

export const LIST_PAYOUT = (memberId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYOUT_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(`/payout/${memberId}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: PAYOUT_LIST_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: PAYOUT_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const ALL_PAYOUT = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYOUT_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(`/payout/`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    console.log(data);
    dispatch({ type: PAYOUT_LIST_SUCCESS, payload: data?.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: PAYOUT_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const UPDATE_PAYOUT = (PAYOUTData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PAYOUT_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.put("/PAYOUT", PAYOUTData, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: UPDATE_PAYOUT_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: UPDATE_PAYOUT_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const RESET_PAYOUT_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_PAYOUT_RESET });
};

export const DELETE_PAYOUT = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_PAYOUT_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/PAYOUT/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_PAYOUT_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_PAYOUT_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_PAYOUT_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_PAYOUT_RESET });
};
