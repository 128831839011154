import { useEffect } from "react";
import { useState } from "react";

export default function Pagination({
  currentPage,
  setCurrentPage,
  totalPage,
  handlePaginationPage,
  total_record,
  handlePageSize,
}) {
  const [Pages, setPages] = useState([]);
  useEffect(() => {
    const arr = Array.from({ length: totalPage }, (_, index) => index + 1);
    setPages(arr);
  }, [totalPage]);

  return (
    <div className="pagination">
      <button
        onClick={() => handlePaginationPage(false)}
        disabled={currentPage === 1 || totalPage === 0}>
        Prev
      </button>

      {Pages?.map((el, i) => (
        <span
          className={
            i >= currentPage - 3 && i <= currentPage + 2 ? "item" : "ItemNone"
          }
          style={
            i + 1 === currentPage
              ? { background: "rgb(57, 51, 85)", color: "white" }
              : { background: "white" }
          }
          key={i}
          onClick={() => setCurrentPage(i + 1)}>
          {i + 1}
        </span>
      ))}

      <button
        onClick={() => handlePaginationPage(true)}
        disabled={currentPage === Pages?.length || totalPage === 0}>
        Next
      </button>
    </div>
  );
}
