import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { DOCTOR_CREATE_RESET } from "../../../Action/DoctorAction";
import { MEMBER_CREATE } from "../../../Action/MemberAction";

export default function RegisterMember({
  isOpen,
  onOpen,
  Get_Doctor,
  onClose,
  DoctorEdit,
  setDoctorEdit,
}) {
  const [MemberFormData, setMemberFormData] = useState({
    MemberName: "",
    MemberEmail: "",
    Phone: "",
    Password: "",
    Discount: "",
  });
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const toast = useToast();

  const { Create_Member_Loading, Create_Member_Success, Create_Member_Error } =
    useSelector((state) => state.MEMBER_CREATE_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setMemberFormData({ ...MemberFormData, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(MEMBER_CREATE(MemberFormData));
  };

  useEffect(() => {
    if (Create_Member_Success) {
      toast({
        title: "Success.",
        description: Create_Member_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Doctor();
      dispatch(DOCTOR_CREATE_RESET());
      setMemberFormData({
        MemberName: "",
        MemberEmail: "",
        Phone: "",
        Password: "",
        Discount: "",
      });
    }

    if (Create_Member_Error) {
      toast({
        title: "Failed.",
        description: Create_Member_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(DOCTOR_CREATE_RESET());
    }
  }, [Create_Member_Success, Create_Member_Error]);

  useEffect(() => {
    if (DoctorEdit) {
      setMemberFormData({ ...DoctorEdit });
    }
  }, [DoctorEdit]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setDoctorEdit({});
        }}>
        <ModalOverlay />

        <ModalContent>
          <span
            onClick={() => {
              onClose();
              setDoctorEdit({});
            }}
            className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1> {DoctorEdit ? "UPDATE MEMBER" : "CREATE MEMBER"}</h1>
                </div>
                <div className="form">
                  <form onSubmit={handleUserSubmit}>
                    <input
                      type="text"
                      name="MemberName"
                      id="MovieName"
                      placeholder="Member Name"
                      value={MemberFormData.MemberName}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="Phone"
                      placeholder="Member Phone No"
                      value={MemberFormData.Phone}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="Number"
                      name="Discount"
                      placeholder="Member Discount"
                      value={MemberFormData.Discount}
                      onChange={handleFormChange}
                      id="MovieHeading"
                    />
                    <input
                      type="email"
                      name="MemberEmail"
                      placeholder="Email"
                      value={MemberFormData.MemberEmail}
                      onChange={handleFormChange}
                      id="MovieHeading"
                    />

                    <input
                      type="Password"
                      name="Password"
                      placeholder="Password"
                      value={MemberFormData.Password}
                      onChange={handleFormChange}
                      id="Rating"
                      required
                    />

                    {DoctorEdit ? (
                      <button type="submit">
                        {Create_Member_Loading ? "Loading..." : "UPDATE"}
                      </button>
                    ) : (
                      <button type="submit">
                        {Create_Member_Loading ? "Loading..." : "CREATE"}
                      </button>
                    )}
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
