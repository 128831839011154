// import Offer from "./Components/Offer";
import NavigationRoute from "./Routes/NavigationRoute";
import "./Css/style.css";
import "./Css/admin.css";
import "./Css/new.scss";

function App() {
  return (
    <div>
      <meta
        property="og:title"
        content="PHOTON DIAGNOSTICS AND RESEARCH CENTRE| Dr. Sahidul Islam | MBBS,MD
  Radiodiagnosis"
      />
      <meta
        name="PHOTON DIAGNOSTICS | Barpetal |"
        content="PHOTON DIAGNOSTICS AND RESEARCH CENTRE| Dr. Sahidul Islam | MBBS,MD Radiodiagnosis"
      />
      <NavigationRoute />
    </div>
  );
}

export default App;
