import { useEffect, useRef } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  APPOINTMENT_CREATE,
  APPOINTMENT_CREATE_RESET,
} from "../../Action/AppointmentAction";
import { LIST_DOCTOR } from "../../Action/DoctorAction";
import { LIST_DEPARTMENT } from "../../Action/DepartmentAction";
import { Appointment_Query } from "../../Model/DepartmentModel";
import Multiselect from "multiselect-react-dropdown";
import { useToast } from "@chakra-ui/react";

export default function Appoinment() {
  const [AppointmentForm, setAppointmentForm] = useState({
    Name: "",
    Age: "",
    Doctor: undefined,
    Time: "",
    isMember: false,
    Phone: "",
    TestPrice: 0,
    Department: [],
    Message: "",
    PromoCode: "",
  });
  const [SelectTest, setSelectTest] = useState([]);
  const toast = useToast();
  const dispatch = useDispatch();
  const multiselectRefTracker = useRef();
  const handleFormChange = (e) => {
    setAppointmentForm({ ...AppointmentForm, [e.target.name]: e.target.value });
  };

  const { Doctor_List } = useSelector((state) => state.DOCTOR_LIST_REDUCER);
  const { Department_List } = useSelector(
    (state) => state.DEPARTMENT_LIST_REDUCER
  );
  const {
    Create_APPOINTMENT_Loading,
    Create_APPOINTMENT_Success,
    Create_APPOINTMENT_Error,
  } = useSelector((state) => state.APPOINTMENT_CREATE_REDUCER);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (AppointmentForm.Name === "" || AppointmentForm.Phone === "") {
      toast({
        title: "Failed.",
        description: "Name & Phone number required to book!",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      if (AppointmentForm.Doctor === "") {
        AppointmentForm.Doctor = null;
      }
      let TotalPrice = 0;
      if (SelectTest.length) {
        SelectTest.map((el) => (TotalPrice += TotalPrice + el?.TestPrice));
      }
      dispatch(
        APPOINTMENT_CREATE({
          ...AppointmentForm,
          Department: SelectTest,
          TotalPrice,
        })
      );
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (Create_APPOINTMENT_Success) {
      toast({
        title: "Success",
        description: Create_APPOINTMENT_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch(APPOINTMENT_CREATE_RESET());
      setAppointmentForm({
        Name: "",
        Age: "",
        Doctor: undefined,
        Time: "",
        Phone: "",
        TestPrice: 0,
        Department: [],
        Message: "",
        PromoCode: "",
      });
    }

    if (Create_APPOINTMENT_Error) {
      toast({
        title: "Failed.",
        description: Create_APPOINTMENT_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(APPOINTMENT_CREATE_RESET());
    }
  }, [Create_APPOINTMENT_Success, Create_APPOINTMENT_Error]);

  useEffect(() => {
    dispatch(LIST_DOCTOR({ page_no: "", page_size: "", Doctor_name: "" }));
    dispatch(
      LIST_DEPARTMENT({ page_no: "", page_size: "", Department_name: "" })
    );
  }, [dispatch]);

  return (
    <div>
      <Header Page={"Appointment"} />
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">Book your Seat</span>
                <h1 class="text-capitalize mb-5 text-lg">Appointment</h1>

                {/*<!-- <ul class="list-inline breadcumb-nav">
            <li class="list-inline-item"><a href="index.html" class="text-white">Home</a></li>
            <li class="list-inline-item"><span class="text-white">/</span></li>
            <li class="list-inline-item"><a href="#" class="text-white-50">Book your Seat</a></li>
  </ul> -->*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="appoinment section">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="mt-3">
                <div class="feature-icon mb-3">
                  <i class="icofont-support text-lg"></i>
                </div>
                <span class="h3">Call for an Emergency Service!</span>
                <h2 class="text-color mt-3">+918402030401</h2>
              </div>
            </div>

            <div class="col-lg-8">
              <div class="appoinment-wrap mt-5 mt-lg-0 pl-lg-5">
                <h2 class="mb-2 title-color">Book an appointment</h2>
                <p class="mb-4">
                  Welcome to our online appointment booking system! We strive to
                  make scheduling your visit as convenient as possible. Whether
                  you need a routine check-up, specialized medical consultation,
                  or any of our other services, booking an appointment has never
                  been easier.
                </p>
                <form
                  id="#"
                  class="appoinment-form"
                  onSubmit={handleFormSubmit}>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <Multiselect
                          options={Department_List}
                          placeholder="Select Test"
                          displayValue="TestName"
                          multiselectRefTracker={multiselectRefTracker}
                          onSelect={(e) => {
                            setSelectTest(e);
                          }}
                          onRemove={(e) => {
                            setSelectTest(e);
                          }}
                          showCheckbox={true}
                          // selectedValues={PreSeSelectedCampuses}
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <select
                          class="form-control"
                          name="Doctor"
                          value={AppointmentForm.Doctor}
                          onChange={handleFormChange}
                          id="exampleFormControlSelect2">
                          <option>Select Doctors</option>
                          {Doctor_List?.map((el) => (
                            <option value={el._id} key={el._id}>
                              {el.Name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Time"
                          value={AppointmentForm.Time}
                          onChange={handleFormChange}
                          id="date"
                          type="datetime-local"
                          class="form-control"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Name"
                          value={AppointmentForm.Name}
                          onChange={handleFormChange}
                          id="name"
                          type="text"
                          class="form-control"
                          placeholder="Full Name"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Age"
                          value={AppointmentForm.Age}
                          onChange={handleFormChange}
                          id="time"
                          type="text"
                          class="form-control"
                          placeholder="age"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="Phone"
                          value={AppointmentForm.Phone}
                          onChange={handleFormChange}
                          id="phone"
                          type="Number"
                          class="form-control"
                          placeholder="Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <input
                          name="PromoCode"
                          value={AppointmentForm.PromoCode}
                          onChange={handleFormChange}
                          id="phone"
                          type="text"
                          class="form-control"
                          placeholder="Promo Code"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group-2 mb-4">
                    <textarea
                      name="Message"
                      value={AppointmentForm.Message}
                      onChange={handleFormChange}
                      id="message"
                      class="form-control"
                      rows="6"
                      placeholder="Your Message"></textarea>
                  </div>

                  <button
                    class="btn btn-main btn-round-full"
                    onClick={handleFormSubmit}>
                    {Create_APPOINTMENT_Loading
                      ? "Loading..."
                      : "Make Appointment"}
                    <i class="icofont-simple-right ml-2"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
