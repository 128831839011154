import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Register_User_Model } from "../../../Model/AdminModel";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { REGISTER_USER, REGISTER_USER_RESET } from "../../../Action/UserAction";
import {
  DOCTOR_CREATE,
  DOCTOR_CREATE_RESET,
} from "../../../Action/DoctorAction";

export default function RegisterDoctor({
  isOpen,
  onOpen,
  Get_Doctor,
  onClose,
}) {
  const [DoctorFormData, setDoctorFormData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    EndTime: "",
    StartTime: "",
    Specialist: "",
  });
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const toast = useToast();

  const { Create_Doctor_Loading, Create_Doctor_Success, Create_Doctor_Error } =
    useSelector((state) => state.DOCTOR_CREATE_REDUCER);

  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setDoctorFormData({ ...DoctorFormData, [e.target.name]: e.target.value });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    dispatch(DOCTOR_CREATE(DoctorFormData));
  };

  useEffect(() => {
    if (Create_Doctor_Success) {
      toast({
        title: "Success.",
        description: Create_Doctor_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      Get_Doctor();
      dispatch(DOCTOR_CREATE_RESET());
      setDoctorFormData({
        Name: "",
        Email: "",
        Phone: "",
        EndTime: "",
        StartTime: "",
        Specialist: "",
      });
    }

    if (Create_Doctor_Error) {
      toast({
        title: "Failed.",
        description: Create_Doctor_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(DOCTOR_CREATE_RESET());
    }
  }, [Create_Doctor_Success, Create_Doctor_Error]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <span onClick={onClose} className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>CREATE DOCTOR</h1>
                </div>
                <div className="form">
                  <form onSubmit={handleUserSubmit}>
                    <input
                      type="text"
                      name="Name"
                      id="MovieName"
                      placeholder="Doctor Name"
                      value={DoctorFormData.Name}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="Specialist"
                      id="Specialist"
                      placeholder="Specialist"
                      value={DoctorFormData.Specialist}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="Phone"
                      placeholder="Doctor Phone No"
                      value={DoctorFormData.Phone}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="email"
                      name="Email"
                      placeholder="Email"
                      value={DoctorFormData.Email}
                      onChange={handleFormChange}
                      id="MovieHeading"
                      required
                    />
                    <input
                      type="time"
                      name="StartTime"
                      placeholder="Start Time"
                      value={DoctorFormData.StartTime}
                      onChange={handleFormChange}
                      id="year"
                      required
                    />

                    <input
                      type="time"
                      name="EndTime"
                      placeholder="End Time"
                      value={DoctorFormData.EndTime}
                      onChange={handleFormChange}
                      id="Rating"
                      required
                    />

                    <button type="submit">
                      {Create_Doctor_Loading ? "Loading..." : "CREATE"}
                    </button>
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
