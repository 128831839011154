
import {
  UPDATE_USER_RESET,
  USER_REGISTER_FAILED,
  USER_REGISTER_REQ,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
} from "../Constant/UserLoginConstant";
import {
  UPDATE_USER_FAILED,
  UPDATE_USER_REQ,
  UPDATE_USER_SUCCESS,
  USER_LIST_FAILED,
  USER_LIST_REQ,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_REQ,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from "../Constant/UserLoginConstant";

export const LOGIN_USER_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_REQ: {
      return { User_Login_Loading: true };
    }

    case USER_LOGIN_SUCCESS: {
      return {
        User_Login_Loading: false,
        accessToken: payload.data.accesstoken,
        userDet: payload.data.Result,
        expTime: payload.Time,
      };
    }
    case USER_LOGIN_FAILED: {
      return { User_Login_Loading: false, User_Login_Error: payload };
    }

    case USER_LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export const USERS_LISTS_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_LIST_REQ: {
      return { User_List_Loading: true };
    }

    case USER_LIST_SUCCESS: {
      return { User_List_Loading: false, User_List: payload };
    }
    case USER_LIST_FAILED: {
      return { User_List_Loading: false, User_List_Error: payload };
    }

    default:
      return state;
  }
};

export const UPDATE_USER_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_REQ: {
      return { Update_User_Loading: true };
    }

    case UPDATE_USER_SUCCESS: {
      return { Update_User_Loading: false, Update_User_Success: payload };
    }

    case UPDATE_USER_FAILED: {
      return { Update_User_Loading: false, Update_User_Error: payload };
    }

    case UPDATE_USER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REGISTER_USER_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case USER_REGISTER_REQ: {
      return { User_Register_Loading: true };
    }

    case USER_REGISTER_SUCCESS: {
      return { User_Register_Loading: false, User_Register_Success: payload };
    }

    case USER_REGISTER_FAILED: {
      return { User_Register_Loading: false, User_Register_Error: payload };
    }

    case USER_REGISTER_RESET: {
      return {};
    }

    default:
      return state;
  }
};
