import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalContent, ModalOverlay, useToast } from "@chakra-ui/react";
import { LIST_DOCTOR } from "../../../Action/DoctorAction";
import { LIST_DEPARTMENT } from "../../../Action/DepartmentAction";
import Multiselect from "multiselect-react-dropdown";

import {
  APPOINTMENT_CREATE,
  APPOINTMENT_CREATE_RESET,
  RESET_APPOINTMENT_UPDATE,
  UPDATE_APPOINTMENT,
} from "../../../Action/AppointmentAction";

export default function RegisterAppointment({
  GET_Appointment,
  isOpen,
  onOpen,
  onClose,
  AppointmentEdit,
  setAppointmentEdit,
}) {
  const [AppointmentFormData, setAppointmentFormData] = useState({
    Name: "",
    Age: "",
    Doctor: "",
    Time: "",
    Phone: "",
    Department: "",
    Message: "",
    PromoCode: "",
  });
  const [Report, setReport] = useState("");
  const toast = useToast();
  const [SelectTest, setSelectTest] = useState([]);
  const [selectBox, setSelectBox] = useState(false);

  const {
    Create_APPOINTMENT_Loading,
    Create_APPOINTMENT_Success,
    Create_APPOINTMENT_Error,
  } = useSelector((state) => state.APPOINTMENT_CREATE_REDUCER);

  const multiselectRefTracker = useRef();
  const { Doctor_List, Doctor_List_Error } = useSelector(
    (state) => state.DOCTOR_LIST_REDUCER
  );
  const {
    Update_Appointment_Success,
    Update_Appointment_Error,
    Update_Appointment_Loading,
  } = useSelector((state) => state.APPOINTMENT_UPDATE_REDUCER);
  const { Department_List } = useSelector(
    (state) => state.DEPARTMENT_LIST_REDUCER
  );
  const dispatch = useDispatch();

  const handleFormChange = (e) => {
    setAppointmentFormData({
      ...AppointmentFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUserSubmit = (e) => {
    e.preventDefault();
    if (AppointmentFormData.Doctor === "") {
      AppointmentFormData.Doctor = null;
    }
    let TotalPrice = 0;
    if (SelectTest.length) {
      SelectTest.map((el) => (TotalPrice = TotalPrice + el?.TestPrice));
    }

    const formData = new FormData();
    formData.append("image", Report);

    AppointmentEdit
      ? dispatch(
          UPDATE_APPOINTMENT(
            {
              ...AppointmentFormData,
              Department: SelectTest,
              TotalPrice,
            },
            Report ? formData : ""
          )
        )
      : dispatch(
          APPOINTMENT_CREATE({
            ...AppointmentFormData,
            Department: SelectTest,
            TotalPrice,
          })
        );
  };

  useEffect(() => {
    if (Create_APPOINTMENT_Success) {
      toast({
        title: "Success",
        description: Create_APPOINTMENT_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      GET_Appointment();
      dispatch(APPOINTMENT_CREATE_RESET());
      setAppointmentFormData({
        Name: "",
        Age: "",
        Doctor: "",
        Time: "",
        Phone: "",
        Department: "",
        Message: "",
        PromoCode: "",
      });
    }

    if (Create_APPOINTMENT_Error) {
      toast({
        title: "Failed.",
        description: Create_APPOINTMENT_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(APPOINTMENT_CREATE_RESET());
    }
  }, [Create_APPOINTMENT_Success, Create_APPOINTMENT_Error]);

  useEffect(() => {
    if (Update_Appointment_Success) {
      toast({
        title: "Success",
        description: Update_Appointment_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      GET_Appointment();
      dispatch(RESET_APPOINTMENT_UPDATE());
      setAppointmentFormData({
        Name: "",
        Age: "",
        Doctor: "",
        Time: "",
        Phone: "",
        Department: "",
        Message: "",
        PromoCode: "",
      });
      setAppointmentEdit(false);
    }

    if (Update_Appointment_Error) {
      toast({
        title: "Failed.",
        description: Update_Appointment_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(RESET_APPOINTMENT_UPDATE());
    }
  }, [Update_Appointment_Success, Update_Appointment_Error]);

  useEffect(() => {
    dispatch(LIST_DOCTOR({ page_no: "", page_size: "", Doctor_name: "" }));
    dispatch(
      LIST_DEPARTMENT({ page_no: "", page_size: "", Department_name: "" })
    );
  }, [dispatch]);

  useEffect(() => {
    if (AppointmentEdit) {
      setAppointmentFormData(AppointmentEdit);
    }
  }, [AppointmentEdit]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <span onClick={onClose} className="close_modal">
            X
          </span>
          <div>
            <div className="Register_container">
              <div className="card">
                <div className="card_title">
                  <h1>
                    {AppointmentEdit
                      ? "UPDATE APPOINTMENT"
                      : " CREATE APPOINTMENT"}{" "}
                  </h1>
                </div>
                <div className="form">
                  <form onSubmit={handleUserSubmit}>
                    <input
                      type="text"
                      name="Name"
                      id="Name"
                      placeholder="Full Name"
                      value={AppointmentFormData.Name}
                      onChange={handleFormChange}
                      required
                    />
                    <input
                      type="text"
                      name="Phone"
                      placeholder="Phone"
                      value={AppointmentFormData.Phone}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <input
                      type="text"
                      name="Age"
                      placeholder="Age"
                      value={AppointmentFormData.Age}
                      onChange={handleFormChange}
                      id="poster"
                      required
                    />
                    <select
                      style={{
                        padding: "10px",
                        width: "100%",
                        border: "0.2px grey",
                        backgroundColor: "#dfdfdf",
                        marginBottom: "10px",
                      }}
                      name="Doctor"
                      value={AppointmentFormData.Doctor}
                      onChange={handleFormChange}>
                      <option>Select Doctor</option>
                      {Doctor_List?.map((el) => (
                        <option key={el._id} value={el._id}>
                          {el.Name}
                        </option>
                      ))}
                    </select>
                    <div>
                      <Multiselect
                        options={Department_List}
                        placeholder="Select Test"
                        displayValue="TestName"
                        multiselectRefTracker={multiselectRefTracker}
                        onSelect={(e) => {
                          setSelectTest(e);
                          setSelectBox(true);
                        }}
                        onRemove={(e) => {
                          setSelectTest(e);
                          setSelectBox(true);
                        }}
                        showCheckbox={true}
                        // selectedValues={PreSeSelectedCampuses}
                      />
                    </div>

                    <input
                      type="datetime-local"
                      name="Time"
                      value={AppointmentFormData.Time}
                      onChange={handleFormChange}
                      id="year"
                      required
                    />

                    <input
                      type="text"
                      name="Message"
                      placeholder="Message"
                      value={AppointmentFormData.Message}
                      onChange={handleFormChange}
                      id="message"
                    />

                    <input
                      type="file"
                      name="Report"
                      file={Report}
                      placeholder="Upload Report"
                      onChange={(e) => setReport(e.target.files[0])}
                      id="Report"
                    />

                    {AppointmentEdit ? (
                      <button type="submit">
                        {Update_Appointment_Loading
                          ? "Loading..."
                          : "UPDATE APPOINTMENT"}
                      </button>
                    ) : (
                      <button type="submit">
                        {Create_APPOINTMENT_Loading ? "Loading..." : "CREATE"}
                      </button>
                    )}
                  </form>
                </div>
                {/*<div className="card_terms">
          <input type="checkbox" name="" id="terms" />{" "}
          <span>
            I have read and agree to the{" "}
            <a href="">Terms of Service</a>
</span>
        </div>*/}
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
