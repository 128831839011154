export const Department_Query = {
  page_no: 1,
  page_size: 10,
  Department_name: "",
};

export const Appointment_Query = {
  Appointment_name: "",
  page_no: 1,
  page_size: 10,
};

export const Contact_Query = {
  page_no: 1,
  page_size: 10,
  contact_name: "",
};

export const Member_Query = {
  page_no: 1,
  page_size: 20,
  Member_name: "",
};
export const Doctor_Query = {
  page_no: 1,
  page_size: 10,
  Doctor_name: "",
};

export const Offer_Query = {
  page_no: 1,
  page_size: 10,
  OfferTitle: "",
};
