export const CREATE_DOCTOR_REQ = "CREATE_DOCTOR_REQ";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAILED = "CREATE_DOCTOR_FAILED";
export const CREATE_DOCTOR_RESET = "CREATE_DOCTOR_RESET";

export const UPDATE_DOCTOR_REQ = "UPDATE_DOCTOR_REQ";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_REQ";
export const UPDATE_DOCTOR_FAILED = "UPDATE_DOCTOR_REQ";
export const UPDATE_DOCTOR_RESET = "UPDATE_DOCTOR_REQ";

export const DOCTOR_LIST_REQ = "DOCTOR_LIST_REQ";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAILED = "DOCTOR_LIST_FAILED";
export const DOCTOR_LIST_RESET = "DOCTOR_LIST_RESET";

export const SINGLE_DOCTOR_REQ = "SINGLE_DOCTOR_REQ";
export const SINGLE_DOCTOR_SUCCESS = "SINGLE_DOCTOR_SUCCESS";
export const SINGLE_DOCTOR_FAILED = "SINGLE_DOCTOR_FAILED";
export const SINGLE_DOCTOR_RESET = "SINGLE_DOCTOR_RESET";

export const REMOVE_DOCTOR_REQ = "REMOVE_DOCTOR_REQ";
export const REMOVE_DOCTOR_SUCCESS = "REMOVE_DOCTOR_SUCCESS";
export const REMOVE_DOCTOR_FAILED = "REMOVE_DOCTOR_FAILED";
export const REMOVE_DOCTOR_RESET = "REMOVE_DOCTOR_RESET";
