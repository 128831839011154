export const USER_LOGIN_REQ = "USER_LOGIN_REQ";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQ = "USER_REGISTER_REQ";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILED = "USER_REGISTER_FAILED";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const UPDATE_USER_REQ = "UPDATE_USER_REQ";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

export const SINGLE_USER_REQ = "SINGLE_USER_REQ";
export const SINGLE_USER_SUCCESS = "SINGLE_USER_SUCCESS";
export const SINGLE_USER_FAILED = "SINGLE_USER_FAILED";
export const SINGLE_USER_RESET = "SINGLE_USER_RESET";

export const USER_LIST_REQ = "USER_LIST_REQ";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILED = "USER_LIST_FAILED";
