import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import { useDisclosure } from "@chakra-ui/react";
import RegisterPayOut from "./RegisterPayOut";
import {
  DELETE_DEPARTMENT,
  LIST_DEPARTMENT,
} from "../../../Action/DepartmentAction";
import { useCallback } from "react";
import { Department_Query } from "../../../Model/DepartmentModel";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import {
  ALL_PAYOUT,
  DELETE_PAYOUT,
  DELETE_PAYOUT_RESET,
  RESET_PAYOUT_UPDATE,
  UPDATE_PAYOUT,
} from "../../../Action/PayOutAction";

export default function PayOut() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [TestData, setTestData] = useState([]);
  const [DepartmentEdit, setDepartmentEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [DepartmentQuery, setDepartmentQuery] = useState(Department_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const { PAYOUT_List_Loading, PAYOUT_List_Error, PAYOUT_List } = useSelector(
    (state) => state.PAYOUT_LIST_REDUCER
  );
  const { Update_PAYOUT_Loading, Update_PAYOUT_Success, Update_PAYOUT_Error } =
    useSelector((state) => state.PAYOUT_UPDATE_REDUCER);

  const { Remove_PAYOUT_Loading, Remove_PAYOUT_Error, Remove_PAYOUT_Success } =
    useSelector((state) => state.REMOVE_PAYOUT_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const Get_Department = useCallback(() => {
    dispatch(ALL_PAYOUT());
  }, [dispatch]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setDepartmentQuery({
      ...DepartmentQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setDepartmentQuery({ ...DepartmentQuery, Department_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setDepartmentQuery({ ...DepartmentQuery, Department_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setDepartmentQuery({
        ...DepartmentQuery,
        Department_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (el) => {
    setDepartmentEdit(el);
    onOpen();
  };

  const handleDeleteBranch = (ID) => {
    dispatch(DELETE_PAYOUT(ID));
  };

  useEffect(() => {
    setDepartmentQuery((DepartmentQuery) => ({
      ...DepartmentQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  const handlePayment = (data) => {
    dispatch(
      UPDATE_PAYOUT({
        _id: data._id,
        MemberId: data?.MemberId?._id,
        PayOutStatus: data.PayOutStatus,
      })
    );
  };

  /* --- Handle Pagination end--- */
  useEffect(() => {
    Get_Department();
  }, [DepartmentQuery, Get_Department]);

  useEffect(() => {
    if (PAYOUT_List) {
      setTestData(PAYOUT_List);
    } else {
      setTestData([]);
    }

    if (PAYOUT_List_Error) {
      setAlert({ Error: PAYOUT_List_Error, validator: false });
    }
  }, [PAYOUT_List, PAYOUT_List_Error]);

  useEffect(() => {
    if (Remove_PAYOUT_Error) {
      setAlert({ Error: Remove_PAYOUT_Error, validator: false });
      setTimeout(() => {
        DELETE_PAYOUT_RESET();
      }, 4000);
    }

    if (Remove_PAYOUT_Success) {
      Get_Department();
      setAlert({ Error: Remove_PAYOUT_Success, validator: true });

      DELETE_PAYOUT_RESET();
    }
  }, [Remove_PAYOUT_Error, Remove_PAYOUT_Success]);

  useEffect(() => {
    if (Update_PAYOUT_Success) {
      Get_Department();
      setAlert({ Error: Update_PAYOUT_Success, validator: true });
      RESET_PAYOUT_UPDATE();
    }

    if (Update_PAYOUT_Error) {
      setAlert({ Error: Update_PAYOUT_Error, validator: false });
      setTimeout(() => {
        RESET_PAYOUT_UPDATE();
      }, 4000);
    }
  }, [Update_PAYOUT_Success, Update_PAYOUT_Error]);

  return (
    <>
      <AdminPanelSidebar page={"PayOut"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"PAYOUT"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>CREATE PAYOUT</button>
            </div>

            <RegisterPayOut
              Get_Department={Get_Department}
              DepartmentEdit={DepartmentEdit}
              setDepartmentEdit={setDepartmentEdit}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th scope="col">Edit/Delete</th>
                      <th scope="col">Member Id</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Date Req</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Pay Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {PAYOUT_List_Loading ? <h2>Loading....</h2> : ""}

                    {PAYOUT_List_Error && <h2>{PAYOUT_List_Error}</h2>}

                    {TestData?.map((el) => (
                      <tr key={el._id}>
                        <td
                          scope="row"
                          style={{
                            display: "flex",
                            gap: "15px",
                          }}>
                          <span
                            style={{ color: "blue" }}
                            onClick={() => handleEditBranch(el)}>
                            <BiEdit />
                          </span>
                          <span
                            style={{ color: "red" }}
                            onClick={() => handleDeleteBranch(el._id)}>
                            <MdDelete />
                          </span>
                        </td>
                        <td scope="row">{el.MemberId?.MemberId}</td>
                        <td scope="row">{el.MemberId?.MemberName}</td>
                        <td scope="row">{el.MemberId?.Phone}</td>
                        <td scope="row">Rs.{el?.PayOutAmount}</td>
                        <td scope="row">{el?.PayOutDate}</td>
                        <td scope="row">
                          {el?.UpiId ? "UPI:- " : "Account:- "}{" "}
                          {el?.UpiId ? el?.UpiId : el?.AccountDet}
                        </td>
                        <td scope="row">
                          <button
                            onClick={() => handlePayment(el)}
                            style={
                              el?.PayOutStatus
                                ? {
                                    padding: "6px 18px",
                                    background: "grey",
                                    color: "white",
                                  }
                                : {
                                    padding: "6px 18px",
                                    background: "green",
                                    color: "white",
                                  }
                            }
                            disabled={el?.PayOutStatus}>
                            {el?.PayOutStatus ? "PAID" : "PAY"}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                PAYOUT_List?.length
                  ? PAYOUT_List?.length / DepartmentQuery?.page_size
                  : 0
              )}
              total_record={PAYOUT_List?.length}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
