import { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_CREATE } from "../../Action/ContactAction";

export default function Contact() {
  const [ContactForm, setContactForm] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: "",
    QueryType: "",
  });

  const {
    Create_Contact_Loading,
    Create_Contact_Error,
    Create_Contact_Success,
  } = useSelector((state) => state.CONTACT_CREATE_REDUCER);

  const toast = useToast();
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setContactForm({
      ...ContactForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (ContactForm.Name === "" || ContactForm.Name === null) {
      toast({
        title: "Failed.",
        description: "Name is required !",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (ContactForm.Phone === "" || ContactForm.Phone === null) {
      toast({
        title: "Failed.",
        description: "Phone is required !",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (ContactForm.Message === "" || ContactForm.Message === "") {
      toast({
        title: "Failed.",
        description: "Message is required !",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      dispatch(CONTACT_CREATE(ContactForm));
    }
  };

  useEffect(() => {
    if (Create_Contact_Error) {
      toast({
        title: "Failed.",
        description: Create_Contact_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (Create_Contact_Success) {
      toast({
        title: "Success",
        description: "Message send Successfully we will get back to you soon.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setContactForm({
        Name: "",
        Email: "",
        Phone: "",
        Message: "",
        QueryType: "",
      });
    }
  }, [Create_Contact_Error, Create_Contact_Success]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Header Page={"Contact"} />
      <section className="page-title bg-1">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Contact Us</span>
                <h1 className="text-capitalize mb-5 text-lg">Get in Touch</h1>

                {/*<!-- <ul className="list-inline breadcumb-nav">
            <li className="list-inline-item"><a href="index.html" className="text-white">Home</a></li>
            <li className="list-inline-item"><span className="text-white">/</span></li>
            <li className="list-inline-item"><a href="#" className="text-white-50">Contact Us</a></li>
          </ul> -->*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- contact form start -->*/}

      <section className="section contact-info pb-0">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6"
              style={{ padding: "10px" }}
              data-aos="fade-down"
              data-aos-duration="300">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-live-support"></i>
                <h5>Call Us</h5>
                <p style={{ fontSize: "13px" }}> +918402030401 </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              style={{ padding: "10px" }}
              data-aos="fade-down"
              data-aos-duration="600">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-support-faq"></i>
                <h5>Email Us</h5>
                <p style={{ fontSize: "13px" }}>contact@photondiagnostics.in</p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-12"
              style={{ padding: "10px" }}
              data-aos="fade-down"
              data-aos-duration="900">
              <div className="contact-block mb-4 mb-lg-0">
                <i className="icofont-location-pin"></i>
                <h5>Location</h5>
                <p style={{ fontSize: "13px" }}>
                  Near FAAMCH, Barpeta-Hospital-Jania Rd, Bhera Gaon, Jaher Pam,
                  Assam 781314
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <img src="./assets/UPI.jpeg" alt="logo" />
      </div>
      <section
        className="contact-form-wrap section"
        data-aos="fade-down"
        data-aos-duration="500">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h2 className="text-md mb-2">Contact us</h2>
                <div className="divider mx-auto my-4"></div>
                <p className="mb-5">
                  For any general inquiries or if you need more information
                  about our diagnostics center's services, kindly send us an
                  email or fill out the contact form provided below. We will
                  make sure to respond to your queries at the earliest
                  convenience.we will get back to you as soon as possible.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <form
                id="contact-form"
                className="contact__form "
                onSubmit={handleSubmit}>
                {/*<!-- form message -->*/}
                <div className="row">
                  <div className="col-12">
                    <div
                      className="alert alert-success contact__msg"
                      style={{ display: "none" }}
                      role="alert">
                      Your message was sent successfully.
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="Name"
                        value={ContactForm.Name}
                        onChange={handleChange}
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Your Full Name"
                      />
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="Email"
                        value={ContactForm.Email}
                        onChange={handleChange}
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="Your Email Address"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="QueryType"
                        value={ContactForm.QueryType}
                        onChange={handleChange}
                        id="subject"
                        type="text"
                        className="form-control"
                        placeholder="Your Query Topic"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        name="Phone"
                        value={ContactForm.Phone}
                        onChange={handleChange}
                        id="phone"
                        type="text"
                        className="form-control"
                        placeholder="Your Phone Number"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group-2 mb-4">
                  <textarea
                    name="Message"
                    value={ContactForm.Message}
                    onChange={handleChange}
                    id="message"
                    className="form-control"
                    rows="8"
                    placeholder="Your Message"
                    required></textarea>
                </div>

                <div>
                  <input
                    className="btn btn-main btn-round-full"
                    name="submit"
                    type="submit"
                    value={
                      Create_Contact_Loading ? "Loading..." : "Send Message"
                    }></input>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
