import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Meta from "../../Components/Meta";

export default function Services() {
  const TopScreen = useRef();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const MetaDes = {
    title: "Services - Photon Diagnostics and Research Center",
    desc: "Experience the latest in X-ray imaging at our diagnostic center, where our skilled radiologists use advanced technology for accurate and efficient medical evaluations.",
  };

  return (
    <div>
      <Meta MetaDes={MetaDes} />
      <div ref={TopScreen}></div>
      <Header Page={"Services"} />
      <section class="page-title bg-1">
        <div class="overlay"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="block text-center">
                <span class="text-white">Our services</span>
                <h1 class="text-capitalize mb-5 text-lg">What We Do</h1>

                {/*<!-- <ul class="list-inline breadcumb-nav">
            <li class="list-inline-item"><a href="index.html" class="text-white">Home</a></li>
            <li class="list-inline-item"><span class="text-white">/</span></li>
            <li class="list-inline-item"><a href="#" class="text-white-50">Our services</a></li>
  </ul> -->*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="section service-2">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="300">
              <div class="service-block mb-5">
                <img
                  src="../assets/images/service/Ultrasound.jpg"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2 title-color">Ultrasound</h4>
                  <p class="mb-2" style={{ fontSize: "13px" }}>
                    We provide advanced ultrasound services for precise medical
                    imaging and pregnancy monitoring, delivered by our skilled
                    and experienced team.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="500">
              <div class="service-block mb-5">
                <img
                  src="../assets/images/service/Xray.jpg"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2  title-color">X-Ray</h4>
                  <p class="mb-4" style={{ fontSize: "13px" }}>
                    Experience the latest in X-ray imaging at our diagnostic
                    center, where our skilled radiologists use advanced
                    technology for accurate and efficient medical evaluations.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="700">
              <div class="service-block mb-5">
                <img
                  src="../assets/images/service/CT_Scan_Mri.jpg"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2 title-color">Pharmacy</h4>
                  <p class="mb-4" style={{ fontSize: "13px" }}>
                    Experience precise and rapid medical imaging with our CT
                    scan services, performed by our expert team of radiologists
                    using state-of-the-art equipment.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="900">
              <div class="service-block mb-5 mb-lg-0">
                <img
                  src="../assets/images/service/Ecg_Test.jpg"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2 title-color">Ecg Testing</h4>
                  <p class="mb-4" style={{ fontSize: "13px" }}>
                    Trust our proficient medical team to conduct reliable ECG
                    testing, providing valuable insights into cardiac health and
                    aiding in the diagnosis of heart-related conditions.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="1000">
              <div class="service-block mb-5 mb-lg-0">
                <img
                  src="../assets/images/service/Blood_Test.png"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2 title-color">Blood Test</h4>
                  <p class="mb-4" style={{ fontSize: "13px" }}>
                    Our diagnostic center offers comprehensive blood testing
                    services, facilitating timely and accurate assessments of
                    various health markers for informed medical evaluations.
                  </p>
                </div>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 col-sm-6"
              data-aos="fade-down"
              data-aos-duration="1200">
              <div class="service-block mb-5 mb-lg-0">
                <img
                  src="../assets/images/service/Thyroid_Test.webp"
                  alt=""
                  class="img-fluid service_Image"
                />
                <div class="content">
                  <h4 class="mt-4 mb-2 title-color">Thyroid Test</h4>
                  <p class="mb-4" style={{ fontSize: "13px" }}>
                    Get your thyroid health assessed with our specialized
                    thyroid testing, providing crucial insights for proper
                    diagnosis and personalized treatment plans.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section cta-page">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div class="cta-content">
                <div class="divider mb-4"></div>
                <h2 class="mb-5 text-lg">
                  We are pleased to offer you the{" "}
                  <span class="title-color">chance to have the healthy</span>
                </h2>
                <Link to="/appointment" class="btn btn-main-2 btn-round-full">
                  Get appointment<i class="icofont-simple-right  ml-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
