export const CREATE_OFFER_REQ = "CREATE_OFFER_REQ";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILED = "CREATE_OFFER_FAILED";
export const CREATE_OFFER_RESET = "CREATE_OFFER_RESET";

export const UPDATE_OFFER_REQ = "UPDATE_OFFER_REQ";
export const UPDATE_OFFER_SUCCESS = "UPDATE_OFFER_REQ";
export const UPDATE_OFFER_FAILED = "UPDATE_OFFER_REQ";
export const UPDATE_OFFER_RESET = "UPDATE_OFFER_REQ";

export const OFFER_LIST_REQ = "OFFER_LIST_REQ";
export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAILED = "OFFER_LIST_FAILED";
export const OFFER_LIST_RESET = "OFFER_LIST_RESET";

export const SINGLE_OFFER_REQ = "SINGLE_OFFER_REQ";
export const SINGLE_OFFER_SUCCESS = "SINGLE_OFFER_SUCCESS";
export const SINGLE_OFFER_FAILED = "SINGLE_OFFER_FAILED";
export const SINGLE_OFFER_RESET = "SINGLE_OFFER_RESET";

export const REMOVE_OFFER_REQ = "REMOVE_OFFER_REQ";
export const REMOVE_OFFER_SUCCESS = "REMOVE_OFFER_SUCCESS";
export const REMOVE_OFFER_FAILED = "REMOVE_OFFER_FAILED";
export const REMOVE_OFFER_RESET = "REMOVE_OFFER_RESET";
