export const CREATE_CONTACT_REQ = "CREATE_CONTACT_REQ";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const CREATE_CONTACT_FAILED = "CREATE_CONTACT_FAILED";
export const CREATE_CONTACT_RESET = "CREATE_CONTACT_RESET";

export const UPDATE_CONTACT_REQ = "UPDATE_CONTACT_REQ";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_REQ";
export const UPDATE_CONTACT_FAILED = "UPDATE_CONTACT_REQ";
export const UPDATE_CONTACT_RESET = "UPDATE_CONTACT_REQ";

export const CONTACT_LIST_REQ = "CONTACT_LIST_REQ";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAILED = "CONTACT_LIST_FAILED";
export const CONTACT_LIST_RESET = "CONTACT_LIST_RESET";

export const SINGLE_CONTACT_REQ = "SINGLE_CONTACT_REQ";
export const SINGLE_CONTACT_SUCCESS = "SINGLE_CONTACT_SUCCESS";
export const SINGLE_CONTACT_FAILED = "SINGLE_CONTACT_FAILED";
export const SINGLE_CONTACT_RESET = "SINGLE_CONTACT_RESET";
