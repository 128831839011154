import {
  APPOINTMENT_LIST_FAILED,
  APPOINTMENT_LIST_REQ,
  APPOINTMENT_LIST_SUCCESS,
  CREATE_APPOINTMENT_FAILED,
  CREATE_APPOINTMENT_REQ,
  CREATE_APPOINTMENT_RESET,
  CREATE_APPOINTMENT_SUCCESS,
  REMOVE_APPOINTMENT_FAILED,
  REMOVE_APPOINTMENT_REQ,
  REMOVE_APPOINTMENT_RESET,
  REMOVE_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_FAILED,
  UPDATE_APPOINTMENT_REQ,
  UPDATE_APPOINTMENT_RESET,
  UPDATE_APPOINTMENT_SUCCESS,
} from "../Constant/AppointmentConstant";

export const APPOINTMENT_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case APPOINTMENT_LIST_REQ: {
      return { APPOINTMENT_List_Loading: true };
    }

    case APPOINTMENT_LIST_SUCCESS: {
      return {
        APPOINTMENT_List_Loading: false,
        APPOINTMENT_List: payload.Result,
        total_Appointments: payload.total_Appointments,
      };
    }
    case APPOINTMENT_LIST_FAILED: {
      return {
        APPOINTMENT_List_Loading: false,
        APPOINTMENT_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const APPOINTMENT_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_APPOINTMENT_REQ: {
      return { Create_APPOINTMENT_Loading: true };
    }
    case CREATE_APPOINTMENT_SUCCESS: {
      return {
        Create_APPOINTMENT_Loading: false,
        Create_APPOINTMENT_Success: payload,
      };
    }

    case CREATE_APPOINTMENT_FAILED: {
      return {
        Create_APPOINTMENT_Loading: false,
        Create_APPOINTMENT_Error: payload,
      };
    }

    case CREATE_APPOINTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const APPOINTMENT_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_APPOINTMENT_REQ: {
      return { Update_Appointment_Loading: true };
    }

    case UPDATE_APPOINTMENT_SUCCESS: {
      return {
        Update_Appointment_Loading: false,
        Update_Appointment_Success: payload,
      };
    }

    case UPDATE_APPOINTMENT_FAILED: {
      return {
        Update_Appointment_Loading: false,
        Update_Appointment_Error: payload,
      };
    }

    case UPDATE_APPOINTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_APPOINTMENT_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_APPOINTMENT_REQ: {
      return { Remove_Appointment_Loading: true };
    }

    case REMOVE_APPOINTMENT_SUCCESS: {
      return {
        Remove_Appointment_Loading: false,
        Remove_Appointment_Success: payload,
      };
    }

    case REMOVE_APPOINTMENT_FAILED: {
      return {
        Remove_Appointment_Loading: false,
        Remove_Appointment_Error: payload,
      };
    }

    case REMOVE_APPOINTMENT_RESET: {
      return {};
    }

    default:
      return state;
  }
};
