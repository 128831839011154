import { useRef } from "react";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaRegUserCircle,
  FaStar,
} from "react-icons/fa";
import Slider from "react-slick";

export default function HearAbout() {
  const slider = useRef();

  const Mobile_Screen = window.innerWidth;
  const Student_Review = [
    {
      Desc: "All the staff,Services are really good.Thank you Photon Diagnostic centre team for such a delightful services.keep up the good work.",
      Image: "",
      Name: "Selima Akhtar",
    },
    {
      Desc: "Best diagnostic centre in Barpeta and entire lower Assam. Staff, service all are outstanding.",
      Image: "",
      Name: "Rustam Ali Khan",
    },
    {
      Desc: " World class testing equipments with a top most Radiologist implies the centre a most reliable for all of us. All the staff's behaviours are so good and polite And overall prices are also reasonable. A++.",
      Image: "",
      Name: "Jakir Hussain",
    },
    {
      Desc: "It's a one stop solution for your doctor consultations and diagnostics.. Highly experienced doctors and support staffs.Excellent service and support from the doctors & staffs.. Fantastic infrastructure. Highly recommended...",
      Image: "",
      Name: "Rahul Thakuria",
    },
    {
      Desc: "All the staff,Services are really good.Thank you Photon Diagnostic centre team for such a delightful services.keep up the good work.",
      Image: "",
      Name: "Selima Akhtar",
    },
    {
      Desc: "Best diagnostic centre in Barpeta and entire lower Assam. Staff, service all are outstanding.",
      Image: "",
      Name: "Rustam Ali Khan",
    },
    {
      Desc: " World class testing equipments with a top most Radiologist implies the centre a most reliable for all of us. All the staff's behaviours are so good and polite And overall prices are also reasonable. Photon diagnostic is better than any other private laboratories in Barpeta.",
      Image: "",
      Name: "Jakir Hussain",
    },
    {
      Desc: "It's a one stop solution for your doctor consultations and diagnostics.. Highly experienced doctors and support staffs.Excellent service and support from the doctors & staffs.. Fantastic infrastructure. Highly recommended...",
      Image: "",
      Name: "Rahul Thakuria",
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    className: "center",
    centerMode: true,
    centerPadding: "30px",
    loop: true,
    autoplay: true,
    speed: 400,
    slidesToShow: Mobile_Screen <= 600 ? 1 : Mobile_Screen <= 780 ? 2 : 4,
    slidesToScroll: Mobile_Screen <= 600 ? 1 : Mobile_Screen <= 780 ? 2 : 4,
    nextArrow: <h1 style={{ color: "black" }}>{">"}</h1>,
    prevArrow: <h1>{"<"}</h1>,
  };
  return (
    <section className="hear_from_student">
      <div className="container" style={{ marginBottom: "-40px" }}>
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2>We served over 5000+ Patients</h2>
              <div className="divider mx-auto my-4"></div>
              <p>
                Let us know more about the necessary pains, difficulties, and
                troubles that we may encounter in life. We all face challenges
                and sufferings.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="std_main_box ">
          <Slider ref={slider} {...settings}>
            {Student_Review?.map((el, i) => (
              <div className="std_box" style={{ position: "relative" }} key={i}>
                <div
                  className="Fancy_Box2"
                  style={{
                    position: "absolute",
                    borderBottom: "120px solid #00632a",
                    borderRight: "200px solid transparent",
                    borderBottomLeftRadius: " 15px ",
                  }}></div>

                <div>
                  {" "}
                  <p className="TextHeight" style={{ paddingBottom: "10px" }}>
                    {" "}
                    <span>{el.Desc} </span>
                  </p>
                  <p
                    style={{
                      margin: "0",
                      padding: "0",
                      position: "absolute",
                      bottom: "120px",
                      left: "60px",
                    }}>
                    <span
                      style={{
                        color: "#ffd700",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "0px",
                      }}>
                      Rating : <FaStar /> <FaStar /> <FaStar /> <FaStar />{" "}
                      <FaStar />
                    </span>
                  </p>
                  <div
                    style={{
                      padding: "10px 0",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "center",
                      }}>
                      <div className="std_logo">
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}>
                          {" "}
                          <img src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png" />
                          <h6>{el.Name}</h6>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
            fontSize: "30px",
            color: "#00632a",
          }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => slider?.current?.slickPrev()}>
            <FaLongArrowAltLeft />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => slider?.current?.slickNext()}>
            {<FaLongArrowAltRight />}
          </span>{" "}
        </div>
      </div>
    </section>
  );
}
