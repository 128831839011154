import { useEffect } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MEMBER_CREATE,
  MEMBER_CREATE_RESET,
  MEMBER_LOGIN,
  MEMBER_LOGIN_RESET,
} from "../../Action/MemberAction";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Meta from "../../Components/Meta";

export default function MemberPage() {
  const [MemberForm, setMemberForm] = useState({
    MemberName: "",
    Phone: "",
    Password: "",
    MemberEmail: "",
  });
  const toast = useToast();

  const { Create_Member_Loading, Create_Member_Success, Create_Member_Error } =
    useSelector((state) => state.MEMBER_CREATE_REDUCER);
  const { Login_Member_Loading, Login_Member, Login_Member_Error } =
    useSelector((state) => state.MEMBER_LOGIN_REDUCER);

  const [MemberLogin, setMemberLogin] = useState(false);
  const [LoginForm, setLoginForm] = useState({ Phone: "", Password: "" });
  const dispatch = useDispatch();
  const mbl_screen = window.innerWidth;
  const navigate = useNavigate();
  const handleFormChange = (e) => {
    setMemberForm({ ...MemberForm, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (MemberForm.Password.length >= 6) {
      dispatch(MEMBER_CREATE(MemberForm));
    } else {
      toast({
        title: "failed.",
        description:
          "Error - Password should be more than 6 characters & one uppercase letter !",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleFormSubmit2 = (e) => {
    e.preventDefault();

    dispatch(MEMBER_LOGIN(LoginForm));
  };

  useEffect(() => {
    if (Login_Member) {
      toast({
        title: "Success.",
        description: "Member logged in Successfully !",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setLoginForm({ Phone: "", Password: "" });
      setTimeout(() => {
        navigate("/memberlogin");
      }, 4000);
    }

    if (Login_Member_Error) {
      toast({
        title: "Failed.",
        description: Login_Member_Error,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(MEMBER_LOGIN_RESET());
      }, 4000);
    }
  }, [Login_Member, Login_Member_Error]);

  useEffect(() => {
    if (Create_Member_Success) {
      toast({
        title: "Success.",
        description: Create_Member_Success,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
      setMemberForm({
        MemberName: "",
        Phone: "",
        Password: "",
        Email: "",
      });

      setTimeout(() => {
        setMemberLogin(true);
        dispatch(MEMBER_CREATE_RESET());
      }, 4000);
    }

    if (Create_Member_Error) {
      toast({
        title: "Failed.",
        description: Create_Member_Error,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      setTimeout(() => {
        dispatch(MEMBER_CREATE_RESET());
      }, 4000);
    }
  }, [Create_Member_Success, Create_Member_Error]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const MetaDes = {
    title: "Member - Photon Diagnostics and Research Center",
    desc: "Be a member to avail existing offers and give some referal to be paid. Get paid if you refer some one for booking appointment.",
  };

  return (
    <div>
      <Meta MetaDes={MetaDes} />
      <Header Page={"Member"} />

      {mbl_screen > 480 && (
        <section class="page-title bg-1">
          <div class="overlay"></div>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="block text-center">
                  <span class="text-white">Be a Member</span>
                  <h1 class="text-capitalize mb-5 text-lg">Member</h1>

                  {/*<!-- <ul class="list-inline breadcumb-nav">
            <li class="list-inline-item"><a href="index.html" class="text-white">Home</a></li>
            <li class="list-inline-item"><span class="text-white">/</span></li>
            <li class="list-inline-item"><a href="#" class="text-white-50">Book your Seat</a></li>
  </ul> -->*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img
          style={{ width: "100%" }}
          src="../assets/member_benefit.jpg"
          alt="membership"
        />{" "}
      </div>

      {!MemberLogin && (
        <section
          className="contact-form-wrap section"
          data-aos="fade-down"
          data-aos-duration="500">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2 className="text-md mb-2">Register Member</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p className="mb-5">
                    Be a member to avail existing offers and give some referal
                    to be paid.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <form
                  id="contact-form"
                  className="contact__form "
                  onSubmit={handleFormSubmit}>
                  {/*<!-- form message -->*/}
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="alert alert-success contact__msg"
                        style={{ display: "none" }}
                        role="alert">
                        Your message was sent successfully.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="MemberName"
                          value={MemberForm.MemberName}
                          onChange={handleFormChange}
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder="Your Full Name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="Phone"
                          value={MemberForm.Phone}
                          onChange={handleFormChange}
                          id="phone"
                          type="text"
                          className="form-control"
                          placeholder="Your Phone Number"
                          required
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="Password"
                          value={MemberForm.Password}
                          onChange={handleFormChange}
                          id="subject"
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="MemberEmail"
                          value={MemberForm.MemberEmail}
                          onChange={handleFormChange}
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Your Email Address"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p style={{ color: "blue" }}>
                      If You are already a member{" "}
                      <span
                        onClick={() => setMemberLogin(!MemberLogin)}
                        className="btn btn-main btn-round-full">
                        Login
                      </span>
                    </p>
                  </div>
                  <div>
                    <input
                      className="btn btn-main btn-round-full"
                      name="submit"
                      type="submit"
                      disabled={Create_Member_Loading}
                      value={
                        Create_Member_Loading ? "Loading..." : "Register Member"
                      }></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}

      {MemberLogin && (
        <section
          className="contact-form-wrap section"
          data-aos="fade-down"
          data-aos-duration="500">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2 className="text-md mb-2">Login Member</h2>
                  <div className="divider mx-auto my-4"></div>
                  <p className="mb-5">
                    Be a member to access your member page see exciting offers
                    and referals payment.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <form
                  id="contact-form"
                  className="contact__form "
                  onSubmit={handleFormSubmit2}>
                  {/*<!-- form message -->*/}
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="alert alert-success contact__msg"
                        style={{ display: "none" }}
                        role="alert">
                        Your message was sent successfully.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="Phone"
                          value={LoginForm.Phone}
                          onChange={(e) =>
                            setLoginForm({
                              ...LoginForm,
                              [e.target.name]: e.target.value,
                            })
                          }
                          id="phone"
                          type="text"
                          className="form-control"
                          placeholder="Your Phone Number"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          name="Password"
                          value={LoginForm.Password}
                          onChange={(e) =>
                            setLoginForm({
                              ...LoginForm,
                              [e.target.name]: e.target.value,
                            })
                          }
                          id="subject"
                          type="text"
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p style={{ color: "blue" }}>
                      If You are not a member
                      <span
                        onClick={() => setMemberLogin(!MemberLogin)}
                        className="btn btn-main btn-round-full">
                        Register
                      </span>
                    </p>
                  </div>
                  <div>
                    <input
                      className="btn btn-main btn-round-full"
                      name="submit"
                      type="submit"
                      disabled={Login_Member_Loading}
                      value={
                        Login_Member_Loading ? "Loading..." : "Login member"
                      }></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
}
