import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { FaRegUser } from "react-icons/fa";
import { AiOutlineEdit } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { LOGOUT, UPDATE_USER, USER_UPDATE_RESET } from "../Action/UserAction";
import { useNavigate } from "react-router-dom";

export default function AdminHeader({
  SidebarOpener,
  handleSidebar,
  page,
  handleSearch,
  handleSearchChange,
}) {
  const [User, setUser] = useState({});
  const [SearchName, setSearchName] = useState("");
  const [UserFormData, setUserFormData] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userDet, expTime, User_Login_Error } = useSelector(
    (state) => state.LOGIN_USER_REDUCER
  );
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const toast = useToast();
  const { Update_User_Loading, Update_User_Success, Update_User_Error } =
    useSelector((state) => state.UPDATE_USER_REDUCER);

  const handleFormChange = (e) => {
    setUserFormData({ ...UserFormData, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    setSearchName(e.target.value);
    handleSearchChange(e);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (SearchName) {
      handleSearch(SearchName);
    }
  };

  const handleUserUpdate = (e) => {
    e.preventDefault();
    dispatch(UPDATE_USER(UserFormData));
  };

  useEffect(() => {
    if (userDet) {
      setUser(userDet);
      setUserFormData(userDet);
    }
  }, [userDet]);

  useEffect(() => {
    if (Update_User_Error) {
      toast({
        title: "Failed.",
        description: Update_User_Error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      dispatch(USER_UPDATE_RESET());
    }

    if (Update_User_Success) {
      toast({
        title: "Success.",
        description: Update_User_Success,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
      dispatch(USER_UPDATE_RESET());
    }
  }, [Update_User_Success, Update_User_Error]);

  useEffect(() => {
    const d = new Date();
    let time = d.getTime();
    let Exp_Time = expTime + 10 * (60 * 60 * 1000);
    if (time > Exp_Time) {
      dispatch(LOGOUT());
      setTimeout(() => {
        window.location.reload();
        Navigate("/login");
      }, 100);
    }
  });

  return (
    <nav>
      <div className="sidebar-button">
        <i
          onClick={handleSidebar}
          className={
            SidebarOpener
              ? "bx bx-menu-alt-right sidebarBtn"
              : "bx bx-menu sidebarBtn"
          }></i>
        <span className="dashboard">{page}</span>
      </div>
      <div className="search-box">
        <input
          type="text"
          id="search"
          name="SearchName"
          onChange={(e) => handleChange(e)}
          placeholder="Search for Name..."
        />
        <i onClick={handleClick} className="bx bx-search"></i>
      </div>
      <Popover>
        <PopoverTrigger>
          <div className="profile-details">
            <FaRegUser />
            <span className="admin_name">{User?.Name?.split(" ")[0]}</span>
            <i className="bx bx-chevron-down"></i>
          </div>
        </PopoverTrigger>
        <PopoverContent w="190px">
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>
            <Flex
              cursor={"pointer"}
              p="10px 10px"
              gap="10px"
              fontSize={"18px"}
              alignItems={"center"}
              onClick={onOpen}>
              <Text>
                <AiOutlineEdit />
              </Text>
              <Text> Edit Profile </Text>
            </Flex>
          </PopoverHeader>
          {/*<PopoverBody>
            Are you sure you want to have that milkshake?
        </PopoverBody>*/}
        </PopoverContent>
      </Popover>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div>
              <div className="Register_container">
                <div className="card">
                  <div className="card_title">
                    <h1>Update User</h1>
                  </div>
                  <div className="form">
                    <form onSubmit={handleUserUpdate}>
                      <input
                        type="text"
                        name="Name"
                        id="username"
                        placeholder="Name"
                        value={UserFormData.Name}
                        onChange={handleFormChange}
                        required
                      />
                      <input
                        type="email"
                        name="Email"
                        placeholder="Email"
                        value={UserFormData.Email}
                        onChange={handleFormChange}
                        id="email"
                        required
                      />
                      <input
                        type="phone"
                        name="Phone"
                        placeholder="Phone"
                        value={UserFormData.Phone}
                        onChange={handleFormChange}
                        id="phone"
                        required
                      />

                      <button type="submit">
                        {Update_User_Loading ? "Loading..." : "Update"}
                      </button>
                    </form>
                  </div>
                  {/*<div className="card_terms">
        <input type="checkbox" name="" id="terms" />{" "}
        <span>
          I have read and agree to the{" "}
          <a href="">Terms of Service</a>
</span>
      </div>*/}
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/*<div className=" user_dropdown">
          <AiOutlineEdit /> Edit
        </div>*/}
    </nav>
  );
}
