import {
  REFERAL_LIST_FAILED,
  REFERAL_LIST_REQ,
  REFERAL_LIST_RESET,
  REFERAL_LIST_SUCCESS,
} from "../Constant/ReferalConstant";

export const REFERAL_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REFERAL_LIST_REQ: {
      return { Referal_List_Loading: true };
    }

    case REFERAL_LIST_SUCCESS: {
      return { Referal_List_Loading: false, Referal_List: payload?.Result };
    }

    case REFERAL_LIST_FAILED: {
      return { Referal_List_Loading: false, Referal_List_Error: payload };
    }

    case REFERAL_LIST_RESET: {
      return {};
    }

    default:
      return state;
  }
};
