import {
  OFFER_LIST_FAILED,
  OFFER_LIST_REQ,
  OFFER_LIST_SUCCESS,
  CREATE_OFFER_FAILED,
  CREATE_OFFER_REQ,
  CREATE_OFFER_RESET,
  CREATE_OFFER_SUCCESS,
  REMOVE_OFFER_REQ,
  REMOVE_OFFER_RESET,
  REMOVE_OFFER_SUCCESS,
  UPDATE_OFFER_FAILED,
  UPDATE_OFFER_REQ,
  UPDATE_OFFER_RESET,
  UPDATE_OFFER_SUCCESS,
} from "../Constant/OfferConstant";

import axiosConfig from "../Core/axiosConfig";

export const OFFER_CREATE = (OFFER_Data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_OFFER_REQ });
    const { data } = await axiosConfig.post("/OFFER", OFFER_Data);
    dispatch({ type: CREATE_OFFER_SUCCESS, payload: data.Result });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: CREATE_OFFER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const OFFER_CREATE_RESET = () => async (dispatch) => {
  dispatch({ type: CREATE_OFFER_RESET });
};

export const LIST_OFFER = (OFFER_query) => async (dispatch, getState) => {
  console.log(OFFER_query);
  try {
    dispatch({ type: OFFER_LIST_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.get(
      `/OFFER?page_no=${OFFER_query.page_no}&OFFER_name=${OFFER_query.OFFER_name}&page_size=${OFFER_query.page_size}`,
      {
        headers: {
          Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
        },
      }
    );
    dispatch({ type: OFFER_LIST_SUCCESS, payload: data });
  } catch (ex) {
    console.log(ex);
    dispatch({
      type: OFFER_LIST_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const UPDATE_OFFER = (OFFERData) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_OFFER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.put("/OFFER", OFFERData, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: UPDATE_OFFER_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: UPDATE_OFFER_FAILED,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const RESET_OFFER_UPDATE = () => (dispatch) => {
  dispatch({ type: UPDATE_OFFER_RESET });
};

export const DELETE_OFFER = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_OFFER_REQ });
    let { LOGIN_USER_REDUCER } = getState();
    let { data } = await axiosConfig.delete(`/OFFER/${id}`, {
      headers: {
        Authorization: `Bearer ${LOGIN_USER_REDUCER.accessToken}`,
      },
    });
    dispatch({ type: REMOVE_OFFER_SUCCESS, payload: data.Result });
  } catch (ex) {
    dispatch({
      type: REMOVE_OFFER_SUCCESS,
      payload: ex.response?.data?.Result
        ? ex.response?.data.Result
        : ex.message,
    });
  }
};

export const DELETE_OFFER_RESET = () => (dispatch) => {
  dispatch({ type: REMOVE_OFFER_RESET });
};
