import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import axiosConfig from "../Core/axiosConfig";

export default function MemberRoute({ children }) {
  const { Login_Member } = useSelector((state) => state.MEMBER_LOGIN_REDUCER);
  const location = useLocation();

  if (!Login_Member) {
    return <Navigate to="/member" state={location.pathname} replace />;
  }

  return children;
}
