import { Route, Routes } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Services from "../Screens/Services/Index";
import About from "../Screens/About/Index";
import Appoinment from "../Screens/Appointment/Index";
import Contact from "../Screens/Contact/Index";
import Doctor from "../Screens/Doctor/Index";
import Offer from "../Screens/Offer/Index";
import Login from "../Screens/Admin/Login/Index";
import AdminContact from "../Screens/Admin/Contact/Index";
import User from "../Screens/Admin/User/Index";
import AppointmentAdmin from "../Screens/Admin/Appointment/Index";
import PrivateRoute from "./PrivateRoute";
import MemberPage from "../Screens/MemberPage/Index";
import Department from "../Screens/Admin/Department/Index";
import AdminDoctor from "../Screens/Admin/Doctor/Index";
import MemberLoginPage from "../Screens/MemberPage/MemberLogin";
import MemberRoute from "./MemberPrivate";
import MemberAdmin from "../Screens/Admin/Member/Index";
import OfferAdmin from "../Screens/Admin/Offer/Index";
import MemberReferal from "../Screens/Admin/MemberReferalAdmin/Index";
import MyCard from "../Screens/MemberPage/MyCard";
import TermsPolicy from "../Screens/TermPolicy/Index";
import PayOut from "../Screens/Admin/PayOut/Index";

export default function NavigationRoute() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/appointment" element={<Appoinment />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact_us" element={<Contact />} />
      <Route path="/doctors" element={<Doctor />} />
      <Route path="/offers" element={<Offer />} />
      <Route path="/member" element={<MemberPage />} />
      <Route path="/terms-and-condition" element={<TermsPolicy />} />
      <Route
        path="/admin"
        element={<PrivateRoute> {<Contact />}</PrivateRoute>}
      />
      <Route path="/admin/login" element={<Login />} />
      <Route
        path="/admin/contact"
        element={<PrivateRoute>{<AdminContact />}</PrivateRoute>}
      />
      <Route
        path="/admin/payout"
        element={<PrivateRoute>{<PayOut />}</PrivateRoute>}
      />
      <Route
        path="/admin/user"
        element={<PrivateRoute>{<User />}</PrivateRoute>}
      />
      <Route
        path="/admin/appointment"
        element={<PrivateRoute>{<AppointmentAdmin />}</PrivateRoute>}
      />
      <Route
        path="/admin/department"
        element={<PrivateRoute>{<Department />}</PrivateRoute>}
      />
      <Route
        path="/admin/doctor"
        element={<PrivateRoute>{<AdminDoctor />}</PrivateRoute>}
      />
      <Route
        path="/admin/member"
        element={<PrivateRoute>{<MemberAdmin />}</PrivateRoute>}
      />
      <Route
        path="/admin/offer"
        element={<PrivateRoute>{<OfferAdmin />}</PrivateRoute>}
      />
      <Route
        path="/admin/member/:memberId"
        element={<PrivateRoute> {<MemberReferal />}</PrivateRoute>}
      />

      <Route
        path="/memberlogin"
        element={<MemberRoute> {<MemberLoginPage />}</MemberRoute>}
      />
      <Route
        path="/mycard"
        element={<MemberRoute> {<MyCard />}</MemberRoute>}
      />
    </Routes>
  );
}
