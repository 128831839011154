import {
  CONTACT_LIST_FAILED,
  CONTACT_LIST_REQ,
  CONTACT_LIST_SUCCESS,
  CREATE_CONTACT_FAILED,
  CREATE_CONTACT_REQ,
  CREATE_CONTACT_RESET,
  CREATE_CONTACT_SUCCESS,
} from "../Constant/ContactConstant";

export const CONTACT_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CONTACT_LIST_REQ: {
      return { CONTACT_List_Loading: true };
    }

    case CONTACT_LIST_SUCCESS: {
      return {
        CONTACT_List_Loading: false,
        CONTACT_List: payload.Result,
        total_Contacts: payload.total_contacts,
      };
    }
    case CONTACT_LIST_FAILED: {
      return {
        CONTACT_List_Loading: false,
        CONTACT_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const CONTACT_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_CONTACT_REQ: {
      return { Create_Contact_Loading: true };
    }
    case CREATE_CONTACT_SUCCESS: {
      return { Create_Contact_Loading: false, Create_Contact_Success: payload };
    }

    case CREATE_CONTACT_FAILED: {
      return { Create_Contact_Loading: false, Create_Contact_Error: payload };
    }

    case CREATE_CONTACT_RESET: {
      return {};
    }

    default:
      return state;
  }
};
