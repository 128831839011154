import Aos from "aos";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ Page }) {
  const [Navbar, setNavbar] = useState(false);
  const [NavScroll, setNavScroll] = useState(false);

  const [HedaerView, setHeaderView] = useState(true);
  const mbl_screen = window.innerWidth;
  useEffect(() => {
    Aos.init();
  }, [Navbar]);

  return (
    <header
      style={
        HedaerView
          ? { postion: "absolute" }
          : { position: "sticky", top: "0", zIndex: "34" }
      }>
      {/*HedaerView && (
        <div
          className="header-top-bar"
          data-aos="fade-right"
          data-aos-offset="400"
          data-aos-easing="ease-in-sine"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="">
                <ul className="top-bar-info list-inline-item pl-0 mb-0">
                  <li className="list-inline-item">
                    <a href="mailto:contact@photondiagnostics.in">
                      <i className="icofont-support-faq mr-2"></i>
                      contact@photondiagnostics.in
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <i className="icofont-location-pin mr-2"></i>
                    Bhera Gaon, Jaher Pam, Assam 781314
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <div className="text-lg-right top-right-bar mt-2 mt-lg-0">
                  <a href="tel:+23-345-67890">
                    <span>Call Now : </span>
                    <span className="h4">+91-840-203-0401</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )*/}

      {mbl_screen > 480 && (
        <nav className="navbar navbar-expand-lg navigation" id="navbar">
          <div className="container" style={{ padding: "20px 0 0 0" }}>
            <Link
              className=""
              to="/"
              data-aos={Navbar ? "fasde-right" : "fade-up"}
              data-aos-anchor-placement="bottom-bottom"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                textDecoration: "none",
                fontWeight: "600",
                color: "green",
                fontSize: "16px",
              }}>
              <img
                src="../assets/Logo.png"
                alt=""
                className="img-fluid"
                style={{ height: "100px" }}
              />{" "}
            </Link>

            <button
              onClick={() => setNavbar(!Navbar)}
              className={Navbar ? "navbar-toggler" : "navbar-toggler collapsed"}
              type="button"
              data-toggle="collapse"
              data-target="#navbarmain"
              aria-controls="navbarmain"
              aria-expanded={Navbar ? true : "false"}
              aria-label="Toggle navigation">
              <span className="icofont-navigation-menu"></span>
            </button>

            <div
              className={
                Navbar
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse"
              }
              data-aos={Navbar ? "fasde-right" : ""}
              id="navbarmain">
              <h2
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: "123",
                  right: "10%",
                  padding: "10px",
                  color: "#00632a",
                }}>
                PHOTON DIAGNOSTICS AND RESEARCH CENTRE
              </h2>
              <ul className="navbar-nav ml-auto">
                <li
                  className={Page === "Home" ? "nav-item active" : "nav-item"}
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="250">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li
                  className={Page === "About" ? "nav-item active" : "nav-item"}
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="300">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li
                  className={
                    Page === "Services" ? "nav-item active" : "nav-item"
                  }
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="400">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li
                  className={
                    Page === "Appointment" ? "nav-item active" : "nav-item"
                  }
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="500">
                  <Link className="nav-link" to="/appointment">
                    Appointment
                  </Link>
                </li>
                <li
                  className={
                    Page === "Doctors" ? "nav-item active" : "nav-item"
                  }
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="600">
                  <Link className="nav-link" to="/doctors">
                    Doctors
                  </Link>
                </li>

                {/*<li className="nav-item ">
            <Link className="nav-link" to="/appontment">
              Appontemnt <i className="icofont-thin-down"></i>
            </Link><ul className="dropdown-menu" aria-labelledby="dropdown02">
              <li>
                <Link className="dropdown-item" to="/appointment">
                  Appoinment
                </Link>
              </li>
              <li>
                <a className="dropdown-item" href="department-single.html">
                  Department Single
                </a>
              </li>

              <li className="dropdown dropdown-submenu dropright">
                <a
                  className="dropdown-item dropdown-toggle"
                  href="#!"
                  id="dropdown0301"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Sub Menu
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdown0301">
                  <li>
                    <a className="dropdown-item" href="index.html">
                      Submenu 01
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="index.html">
                      Submenu 02
                    </a>
                  </li>
                </ul>
              </li>
</ul></li>*/}

                {/* <li className="nav-item">
            <a
              className="nav-link"
              href="/"
              id="dropdown03"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Doctors <i className="icofont-thin-down"></i>
            </a> */}
                {/* <ul className="dropdown-menu" aria-labelledby="dropdown03">
              <li>
                <a className="dropdown-item" href="/">
                  Doctors
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  Doctor Single
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="/">
                  Appoinment
                </a>
              </li>

              {/*<li className="dropdown dropdown-submenu dropleft">
                <a
                  className="dropdown-item dropdown-toggle"
                  href="#!"
                  id="dropdown0501"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Sub Menu
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdown0501">
                  <li>
                    <a className="dropdown-item" href="/">
                      Submenu 01
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Submenu 02
                    </a>
                  </li>
                </ul>
</li>
            </ul> */}
                {/* </li> */}

                {/*<li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="blog-sidebar.html"
              id="dropdown05"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false">
              Blog <i className="icofont-thin-down"></i>
            </a>
            <ul className="dropdown-menu" aria-labelledby="dropdown05">
              <li>
                <a className="dropdown-item" href="blog-sidebar.html">
                  Blog with Sidebar
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="blog-single.html">
                  Blog Single
                </a>
              </li>
            </ul>
</li>*/}

                <li
                  className={Page === "Blogs" ? "nav-item active" : "nav-item"}
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="700">
                  <Link className="nav-link" to="/contact_us">
                    Blogs
                  </Link>
                </li>
                <li
                  className={
                    Page === "Contact" ? "nav-item active" : "nav-item"
                  }
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="700">
                  <Link className="nav-link" to="/contact_us">
                    Contact
                  </Link>
                </li>

                <li
                  className={Page === "Member" ? "nav-item active" : "nav-item"}
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="900">
                  <Link className="nav-link" to="/member">
                    Become Member
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}

      {mbl_screen <= 480 && (
        <nav className="navbar navbar-expand-lg navigation" id="navbar">
          <div className="container">
            <Link
              className=""
              to="/"
              data-aos={Navbar ? "fasde-right" : "fade-up"}
              data-aos-anchor-placement="bottom-bottom"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                textDecoration: "none",
                fontWeight: "600",
                color: "green",
                fontSize: "16px",
              }}>
              <img
                src="../assets/Logo.png"
                alt=""
                className="img-fluid"
                style={{ height: "100px" }}
              />{" "}
              PHOTON DIAGNOSTICS <br /> AND RESEARCH CENTRE
            </Link>

            <button
              onClick={() => setNavbar(!Navbar)}
              className={Navbar ? "navbar-toggler" : "navbar-toggler collapsed"}
              type="button"
              data-toggle="collapse"
              data-target="#navbarmain"
              aria-controls="navbarmain"
              aria-expanded={Navbar ? true : "false"}
              aria-label="Toggle navigation">
              <span className="icofont-navigation-menu"></span>
            </button>

            <div
              className={
                Navbar
                  ? "collapse navbar-collapse show"
                  : "collapse navbar-collapse"
              }
              data-aos={Navbar ? "fasde-right" : ""}
              id="navbarmain">
              <ul className="navbar-nav ml-auto">
                <li
                  className="nav-item active"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="250">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="300">
                  <Link className="nav-link" to="/about">
                    About
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="400">
                  <Link className="nav-link" to="/services">
                    Services
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="500">
                  <Link className="nav-link" to="/appointment">
                    Appointment
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="600">
                  <Link className="nav-link" to="/doctors">
                    Doctors
                  </Link>
                </li>

                {/*<li className="nav-item ">
                <Link className="nav-link" to="/appontment">
                  Appontemnt <i className="icofont-thin-down"></i>
                </Link><ul className="dropdown-menu" aria-labelledby="dropdown02">
                  <li>
                    <Link className="dropdown-item" to="/appointment">
                      Appoinment
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="department-single.html">
                      Department Single
                    </a>
                  </li>

                  <li className="dropdown dropdown-submenu dropright">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#!"
                      id="dropdown0301"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Sub Menu
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dropdown0301">
                      <li>
                        <a className="dropdown-item" href="index.html">
                          Submenu 01
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="index.html">
                          Submenu 02
                        </a>
                      </li>
                    </ul>
                  </li>
  </ul></li>*/}

                {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="/"
                  id="dropdown03"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Doctors <i className="icofont-thin-down"></i>
                </a> */}
                {/* <ul className="dropdown-menu" aria-labelledby="dropdown03">
                  <li>
                    <a className="dropdown-item" href="/">
                      Doctors
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Doctor Single
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      Appoinment
                    </a>
                  </li>

                  {/*<li className="dropdown dropdown-submenu dropleft">
                    <a
                      className="dropdown-item dropdown-toggle"
                      href="#!"
                      id="dropdown0501"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false">
                      Sub Menu
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dropdown0501">
                      <li>
                        <a className="dropdown-item" href="/">
                          Submenu 01
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/">
                          Submenu 02
                        </a>
                      </li>
                    </ul>
  </li>
                </ul> */}
                {/* </li> */}

                {/*<li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="blog-sidebar.html"
                  id="dropdown05"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  Blog <i className="icofont-thin-down"></i>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdown05">
                  <li>
                    <a className="dropdown-item" href="blog-sidebar.html">
                      Blog with Sidebar
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="blog-single.html">
                      Blog Single
                    </a>
                  </li>
                </ul>
  </li>*/}

                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="700">
                  <Link className="nav-link" to="/contact_us">
                    Blogs
                  </Link>
                </li>
                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="700">
                  <Link className="nav-link" to="/contact_us">
                    Contact
                  </Link>
                </li>

                <li
                  className="nav-item"
                  data-aos={Navbar ? "fasde-right" : "fade-up"}
                  data-aos-easing="linear"
                  data-aos-duration="900">
                  <Link className="nav-link" to="/member">
                    Become Member
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
}
