export const CREATE_DEPARTMENT_REQ = "CREATE_DEPARTMENT_REQ";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILED = "CREATE_DEPARTMENT_FAILED";
export const CREATE_DEPARTMENT_RESET = "CREATE_DEPARTMENT_RESET";

export const UPDATE_DEPARTMENT_REQ = "UPDATE_DEPARTMENT_REQ";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILED = "UPDATE_DEPARTMENT_FAILED";
export const UPDATE_DEPARTMENT_RESET = "UPDATE_DEPARTMENT_RESET";

export const DEPARTMENT_LIST_REQ = "DEPARTMENT_LIST_REQ";
export const DEPARTMENT_LIST_SUCCESS = "DEPARTMENT_LIST_SUCCESS";
export const DEPARTMENT_LIST_FAILED = "DEPARTMENT_LIST_FAILED";
export const DEPARTMENT_LIST_RESET = "DEPARTMENT_LIST_RESET";

export const SINGLE_DEPARTMENT_REQ = "SINGLE_DEPARTMENT_REQ";
export const SINGLE_DEPARTMENT_SUCCESS = "SINGLE_DEPARTMENT_SUCCESS";
export const SINGLE_DEPARTMENT_FAILED = "SINGLE_DEPARTMENT_FAILED";
export const SINGLE_DEPARTMENT_RESET = "SINGLE_DEPARTMENT_RESET";

export const REMOVE_DEPARTMENT_REQ = "REMOVE_DEPARTMENT_REQ";
export const REMOVE_DEPARTMENT_SUCCESS = "REMOVE_DEPARTMENT_SUCCESS";
export const REMOVE_DEPARTMENT_FAILED = "REMOVE_DEPARTMENT_FAILED";
export const REMOVE_DEPARTMENT_RESET = "REMOVE_DEPARTMENT_RESET";
