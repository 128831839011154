import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import { useDisclosure } from "@chakra-ui/react";
import { useCallback } from "react";
import { Doctor_Query, Offer_Query } from "../../../Model/DepartmentModel";
import RegisterOffer from "./RegisterOffer";
import { LIST_OFFER } from "../../../Action/OfferAction";

export default function OfferAdmin() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [OfferData, setOfferData] = useState([]);
  const [DoctorEdit, setDoctorEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [OfferQuery, setOfferQuery] = useState(Offer_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const { Offer_List_Loading, Offer_List_Error, total_Offers, Offer_List } =
    useSelector((state) => state.OFFER_LIST_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const Get_Offer = useCallback(() => {
    dispatch(LIST_OFFER(OfferQuery));
  }, [dispatch, OfferQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setOfferQuery({
      ...OfferQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setOfferQuery({ ...OfferQuery, Doctor_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setOfferQuery({ ...OfferQuery, Doctor_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setOfferQuery({
        ...OfferQuery,
        Doctor_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (ID) => {
    setDoctorEdit(true);
  };

  const handleDeleteBranch = (ID) => {};

  useEffect(() => {
    setOfferQuery((OfferQuery) => ({
      ...OfferQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    Get_Offer();
  }, [OfferQuery, Get_Offer]);

  useEffect(() => {
    if (Offer_List) {
      setOfferData(Offer_List);
    } else {
      setOfferData([]);
    }

    if (Offer_List_Error) {
      setAlert({ Error: Offer_List_Error, validator: false });
    }
  }, [Offer_List, Offer_List_Error]);

  return (
    <>
      <AdminPanelSidebar page={"OFFER"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"DOCTOR"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>ADD OFFER</button>
            </div>

            <RegisterOffer
              Get_Offer={Get_Offer}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th scope="col">Offer Name</th>
                      <th scope="col">Offer for Test</th>
                      <th scope="col">Promo code</th>
                      <th scope="col">Offer expiry date</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Offer for</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Offer_List_Loading ? <h2>Loading....</h2> : ""}
                    {Offer_List_Error && <h2>{Offer_List_Error}</h2>}

                    {OfferData?.map((el) => (
                      <tr key={el._id}>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.StartTime}</td>
                        <td scope="row">{el.EndTime} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Offers ? total_Offers / OfferQuery?.page_size : 0
              )}
              total_record={total_Offers}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
