import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOGOUT } from "../Action/UserAction";
import { useEffect } from "react";

export default function AdminPanelSidebar({ SidebarOpener, page }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(LOGOUT());
    navigate("/admin/login");
  };

  return (
    <div className={SidebarOpener ? "sidebar active" : "sidebar"}>
      <div className="logo-details">
        <img
          src="../../assets/Logo.png"
          alt=""
          class="img-fluid"
          style={{ height: "50px", marginLeft: "10px" }}
        />
        <span style={{ fontSize: "12px", marginLeft: "15px", color: "white" }}>
          PHOTON DIAGNOSTICS <br /> AND RESEARCH CENTRE
        </span>
      </div>
      <ul className="nav-links">
        <li>
          <Link
            to="/admin/contact"
            className={page === "CONTACT" ? "active" : ""}>
            <i className="bx bx-grid-alt"></i>
            <span className="links_name">CONTACT</span>
          </Link>
        </li>

        <li>
          <Link
            to="/admin/appointment"
            className={page === "APPOINTMENT" ? "active" : ""}>
            <i className="bx bx-grid-alt"></i>
            <span className="links_name">APPOINTMENT</span>
          </Link>
        </li>
        {/*<li>
          <a href="#">
            <i className="bx bx-box"></i>
            <span className="links_name">Product</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-list-ul"></i>
            <span className="links_name">Order list</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="links_name">Analytics</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-coin-stack"></i>
            <span className="links_name">Stock</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-book-alt"></i>
            <span className="links_name">Total order</span>
          </a>
  </li>*/}
        <li>
          <Link to="/admin/user" className={page === "Team" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">USER</span>
          </Link>
        </li>
        <li>
          <Link
            to="/admin/department"
            className={page === "DEPARTMENT" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">DEPARTMENT</span>
          </Link>
        </li>

        <li>
          <Link
            to="/admin/doctor"
            className={page === "DOCTOR" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">DOCTOR</span>
          </Link>
        </li>
        <li>
          <Link
            to="/admin/member"
            className={page === "MEMBER" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">MEMBER</span>
          </Link>
        </li>
        <li>
          <Link to="/admin/offer" className={page === "OFFER" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">OFFER</span>
          </Link>
        </li>

        <li>
          <Link
            to="/admin/payout"
            className={page === "PayOut" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">Pay Out</span>
          </Link>
        </li>

        {/*<li>
          <Link
            to="/admin/register_user"
            className={page === "Register" ? "active" : ""}>
            <i className="bx bx-user"></i>
            <span className="links_name">Register User</span>
          </Link>
        </li> <li>
          <a href="#">
            <i className="bx bx-message"></i>
            <span className="links_name">Messages</span>
          </a>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-heart"></i>
            <span className="links_name">Favrorites</span>
          </a>
       </li>*/}
        {/*<li>
          <Link to="setting" className={page === "Setting" ? "active" : ""}>
            <i className="bx bx-cog"></i>
            <span className="links_name">Setting</span>
          </Link>
    </li>*/}
        <li className="log_out" onClick={handleLogout}>
          <a href="#">
            <i className="bx bx-log-out"></i>
            <span className="links_name">Log out</span>
          </a>
        </li>
      </ul>
    </div>
  );
}
