import {
  MEMBER_LIST_FAILED,
  MEMBER_LIST_REQ,
  MEMBER_LIST_SUCCESS,
  CREATE_MEMBER_FAILED,
  CREATE_MEMBER_REQ,
  CREATE_MEMBER_RESET,
  CREATE_MEMBER_SUCCESS,
  REMOVE_MEMBER_FAILED,
  REMOVE_MEMBER_REQ,
  REMOVE_MEMBER_RESET,
  REMOVE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILED,
  UPDATE_MEMBER_REQ,
  UPDATE_MEMBER_RESET,
  UPDATE_MEMBER_SUCCESS,
  LOGIN_MEMBER_REQ,
  LOGIN_MEMBER_SUCCESS,
  LOGIN_MEMBER_FAILED,
  LOGIN_MEMBER_RESET,
} from "../Constant/MemberConstant";
import { SET_LOCAL } from "../Model/LocalData";

export const MEMBER_LIST_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case MEMBER_LIST_REQ: {
      return { Member_List_Loading: true };
    }

    case MEMBER_LIST_SUCCESS: {
      return {
        Member_List_Loading: false,
        Member_List: payload.Result,
        total_Members: payload.total_Members,
      };
    }
    case MEMBER_LIST_FAILED: {
      return {
        Member_List_Loading: false,
        Member_List_Error: payload,
      };
    }

    default:
      return state;
  }
};

export const MEMBER_LOGIN_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case LOGIN_MEMBER_REQ: {
      return { Login_Member_Loading: true };
    }

    case LOGIN_MEMBER_SUCCESS: {
      SET_LOCAL("member", payload);
      return { Login_Member_Loading: false, Login_Member: payload };
    }

    case LOGIN_MEMBER_FAILED: {
      return { Login_Member_Loading: false, Login_Member_Error: payload };
    }

    case LOGIN_MEMBER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const MEMBER_CREATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case CREATE_MEMBER_REQ: {
      return { Create_Member_Loading: true };
    }
    case CREATE_MEMBER_SUCCESS: {
      return {
        Create_Member_Loading: false,
        Create_Member_Success: payload,
      };
    }

    case CREATE_MEMBER_FAILED: {
      return {
        Create_Member_Loading: false,
        Create_Member_Error: payload,
      };
    }

    case CREATE_MEMBER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const MEMBER_UPDATE_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_MEMBER_REQ: {
      return { Update_Member_Loading: true };
    }

    case UPDATE_MEMBER_SUCCESS: {
      return {
        Update_Member_Loading: false,
        Update_Member_Success: payload,
      };
    }

    case UPDATE_MEMBER_FAILED: {
      return {
        Update_Member_Loading: false,
        Update_Member_Error: payload,
      };
    }

    case UPDATE_MEMBER_RESET: {
      return {};
    }

    default:
      return state;
  }
};

export const REMOVE_MEMBER_REDUCER = (state = {}, { type, payload }) => {
  switch (type) {
    case REMOVE_MEMBER_REQ: {
      return { Remove_Member_Loading: true };
    }

    case REMOVE_MEMBER_SUCCESS: {
      return {
        Remove_Member_Loading: false,
        Remove_Member_Success: payload,
      };
    }

    case REMOVE_MEMBER_FAILED: {
      return {
        Remove_Member_Loading: false,
        Remove_Member_Error: payload,
      };
    }

    case REMOVE_MEMBER_RESET: {
      return {};
    }

    default:
      return state;
  }
};
