import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore,
} from "redux";
import thunk from "redux-thunk";
import {
  CONTACT_LIST_REDUCER,
  CONTACT_CREATE_REDUCER,
} from "./Reducer/ContactReducer";
import {
  LOGIN_USER_REDUCER,
  USERS_LISTS_REDUCER,
  UPDATE_USER_REDUCER,
  REGISTER_USER_REDUCER,
} from "./Reducer/UserReducer";
import {
  APPOINTMENT_LIST_REDUCER,
  APPOINTMENT_CREATE_REDUCER,
  APPOINTMENT_UPDATE_REDUCER,
  REMOVE_APPOINTMENT_REDUCER,
} from "./Reducer/AppointmentReducer";
import {
  DEPARTMENT_LIST_REDUCER,
  DEPARTMENT_CREATE_REDUCER,
  DEPARTMENT_UPDATE_REDUCER,
  REMOVE_DEPARTMENT_REDUCER,
} from "./Reducer/DepartmentReducer";
import {
  DOCTOR_LIST_REDUCER,
  DOCTOR_UPDATE_REDUCER,
  DOCTOR_CREATE_REDUCER,
  REMOVE_DOCTOR_REDUCER,
} from "./Reducer/DoctorReducer";
import {
  MEMBER_LIST_REDUCER,
  MEMBER_CREATE_REDUCER,
  MEMBER_UPDATE_REDUCER,
  REMOVE_MEMBER_REDUCER,
  MEMBER_LOGIN_REDUCER,
} from "./Reducer/MemberReducer";

import {
  OFFER_LIST_REDUCER,
  OFFER_CREATE_REDUCER,
  OFFER_UPDATE_REDUCER,
  REMOVE_OFFER_REDUCER,
} from "./Reducer/OfferReducer";
import {
  PAYOUT_LIST_REDUCER,
  PAYOUT_CREATE_REDUCER,
  PAYOUT_UPDATE_REDUCER,
  REMOVE_PAYOUT_REDUCER,
} from "./Reducer/PayOutReducer";
import { REFERAL_LIST_REDUCER } from "./Reducer/RefrealReducer";
import { GET_LOCAL } from "./Model/LocalData";

const rootReducers = combineReducers({
  CONTACT_LIST_REDUCER,
  CONTACT_CREATE_REDUCER,
  LOGIN_USER_REDUCER,
  USERS_LISTS_REDUCER,
  UPDATE_USER_REDUCER,
  REGISTER_USER_REDUCER,
  APPOINTMENT_LIST_REDUCER,
  APPOINTMENT_CREATE_REDUCER,
  APPOINTMENT_UPDATE_REDUCER,
  REMOVE_APPOINTMENT_REDUCER,
  DEPARTMENT_LIST_REDUCER,
  DEPARTMENT_CREATE_REDUCER,
  DEPARTMENT_UPDATE_REDUCER,
  REMOVE_DEPARTMENT_REDUCER,
  DOCTOR_LIST_REDUCER,
  DOCTOR_UPDATE_REDUCER,
  DOCTOR_CREATE_REDUCER,
  REMOVE_DOCTOR_REDUCER,
  MEMBER_LIST_REDUCER,
  MEMBER_CREATE_REDUCER,
  MEMBER_UPDATE_REDUCER,
  REMOVE_MEMBER_REDUCER,
  MEMBER_LOGIN_REDUCER,
  OFFER_LIST_REDUCER,
  OFFER_CREATE_REDUCER,
  OFFER_UPDATE_REDUCER,
  REMOVE_OFFER_REDUCER,
  REFERAL_LIST_REDUCER,
  PAYOUT_LIST_REDUCER,
  PAYOUT_CREATE_REDUCER,
  PAYOUT_UPDATE_REDUCER,
  REMOVE_PAYOUT_REDUCER,
});

const InitialState = {
  LOGIN_USER_REDUCER: {
    accessToken: GET_LOCAL("_stTkn"),
    userDet: GET_LOCAL("_userInf"),
    expTime: GET_LOCAL("_stExp"),
  },
  MEMBER_LOGIN_REDUCER: {
    Login_Member: GET_LOCAL("member"),
  },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = legacy_createStore(
  rootReducers,
  InitialState,
  composeEnhancers(applyMiddleware(thunk))
);

// CREATE_CONTACT_REDUCER
