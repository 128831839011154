import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { LIST_DOCTOR } from "../../../Action/DoctorAction";
import { useCallback } from "react";
import { Member_Query } from "../../../Model/DepartmentModel";
import RegisterMember from "./RegisterMember";
import { LIST_MEMBER } from "../../../Action/MemberAction";
import { BiEdit } from "react-icons/bi";
import { MdDelete } from "react-icons/md";

import axiosConfig from "../../../Core/axiosConfig";
import axios from "axios";

export default function MemberAdmin() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [DoctorData, setDoctorData] = useState([]);
  const [DoctorEdit, setDoctorEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [MemberQuery, setMemberQuery] = useState(Member_Query);
  const {
    isOpen: isOpening,
    onOpen: onOpening,
    onClose: onCLosing,
  } = useDisclosure();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });
  const [ChangePassword, setChangePassword] = useState({
    _id: "",
    Password: "",
  });
  const toast = useToast();
  const { Member_List_Loading, Member_List_Error, total_Members, Member_List } =
    useSelector((state) => state.MEMBER_LIST_REDUCER);
  const { accessToken } = useSelector((state) => state.LOGIN_USER_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
  };

  const Get_Doctor = useCallback(() => {
    dispatch(LIST_MEMBER(MemberQuery));
  }, [dispatch, MemberQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setMemberQuery({
      ...MemberQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setMemberQuery({ ...MemberQuery, name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setMemberQuery({ ...MemberQuery, name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setMemberQuery({
        ...MemberQuery,
        Doctor_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (el) => {
    setDoctorEdit(el);
    onOpening();
  };

  const handleDeleteBranch = (ID) => {};
  const handlePassword = async (e) => {
    e.preventDefault();
    console.log(ChangePassword);
    try {
      const { data } = await axiosConfig.put(
        "/member/change/password",
        ChangePassword,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast({
        title: "Success",
        description: data.Result,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (ex) {
      console.log(ex);
      toast({
        title: "Error",
        description: ex.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setMemberQuery((MemberQuery) => ({
      ...MemberQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    Get_Doctor();
  }, [MemberQuery, Get_Doctor]);

  useEffect(() => {
    if (Member_List) {
      setDoctorData(Member_List);
    } else {
      setDoctorData([]);
    }

    if (Member_List_Error) {
      setAlert({ Error: Member_List_Error, validator: false });
    }
  }, [Member_List, Member_List_Error]);

  return (
    <>
      <AdminPanelSidebar page={"MEMBER"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"DOCTOR"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpening}>ADD MEMBER</button>
            </div>

            <RegisterMember
              Get_Doctor={Get_Doctor}
              DoctorEdit={DoctorEdit}
              setDoctorEdit={setDoctorEdit}
              isOpen={isOpening}
              onClose={onCLosing}
              onOpen={onOpening}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{
                    whiteSpace: "nowrap",
                    height: "400px",
                    overflow: "scroll",
                  }}>
                  <thead>
                    <tr>
                      <th scope="col">Edit/Delete</th>
                      <th scope="col">Member Name</th>
                      <th scope="col">Member Id</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Expiry Date</th>
                      <th scope="col">Change Password</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Member_List_Loading ? <h2>Loading....</h2> : ""}
                    {Member_List_Error && <h2>{Member_List_Error}</h2>}

                    {DoctorData?.map((el) => (
                      <tr key={el._id}>
                        <td
                          scope="row"
                          style={{
                            display: "flex",
                            gap: "15px",
                          }}>
                          <span
                            style={{ color: "blue" }}
                            onClick={() => handleEditBranch(el)}>
                            <BiEdit />{" "}
                          </span>
                          <span
                            style={{ color: "red" }}
                            onClick={() => handleDeleteBranch(el._id)}>
                            <MdDelete />
                          </span>
                        </td>
                        <td
                          scope="row"
                          style={{ color: "blue" }}
                          onClick={() =>
                            window.open(`/admin/member/${el._id}`, "_blank")
                          }>
                          {el.MemberName}
                        </td>
                        <td scope="row">{el.MemberId}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.MemberStartDate} </td>
                        <td scope="row">{el.MemberExpiryDate} </td>
                        <td scope="row">
                          {" "}
                          <div className="">
                            <button
                              onClick={() => {
                                setChangePassword({
                                  ...ChangePassword,
                                  _id: el._id,
                                });
                                onOpen2();
                              }}
                              style={{
                                padding: "8px 14px",
                                background: "blue",
                                color: "white",
                              }}>
                              Change
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              isOpen={isOpen2}
              onClose={() => {
                onClose2();
              }}>
              <ModalOverlay />

              <ModalContent>
                <span
                  onClick={() => {
                    onClose2();
                  }}
                  className="close_modal">
                  X
                </span>
                <div>
                  <div className="Register_container">
                    <div className="card">
                      <div className="card_title">
                        <h1> UPDATE PASSWORD</h1>
                      </div>
                      <div className="form">
                        <form onSubmit={handlePassword}>
                          <input
                            type="Password"
                            name="Password"
                            placeholder="Enter New Password"
                            value={ChangePassword.Password}
                            onChange={(e) =>
                              setChangePassword({
                                ...ChangePassword,
                                Password: e.target.value,
                              })
                            }
                            id="Rating"
                            required
                          />
                          <input
                            style={{
                              background: "blue",
                              color: "white",
                              cursor: "pointer",
                            }}
                            type="submit"
                            value="Change Password"
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalContent>
            </Modal>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Members ? total_Members / MemberQuery?.page_size : 0
              )}
              total_record={total_Members}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
