import { useEffect, useState } from "react";
import AdminPanelSidebar from "../../../Components/AdminPanelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { LIST_CONTACT } from "../../../Action/ContactAction";
import AdminHeader from "../../../Components/AdminHeader";
import Pagination from "../../../Components/Pagination";
import { useDisclosure } from "@chakra-ui/react";
import RegisterDoctor from "./RegisterDoctor";
import { LIST_DOCTOR } from "../../../Action/DoctorAction";
import { useCallback } from "react";
import { Doctor_Query } from "../../../Model/DepartmentModel";

export default function AdminDoctor() {
  const [SidebarOpener, setSidebarOpener] = useState(false);
  const [DoctorData, setDoctorData] = useState([]);
  const [DoctorEdit, setDoctorEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [DoctorQuery, setDoctorQuery] = useState(Doctor_Query);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Alert, setAlert] = useState({ Error: "", validator: "" });

  const { Doctor_List_Loading, Doctor_List_Error, total_Doctors, Doctor_List } =
    useSelector((state) => state.DOCTOR_LIST_REDUCER);
  const dispatch = useDispatch();

  const handleSidebar = () => {
    setSidebarOpener(!SidebarOpener);
  };

  const Get_Doctor = useCallback(() => {
    dispatch(LIST_DOCTOR(DoctorQuery));
  }, [dispatch, DoctorQuery]);

  /* --- Handle Pagination start--- */
  const handlePaginationPage = (data) => {
    if (data) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageSize = (e) => {
    setDoctorQuery({
      ...DoctorQuery,
      page_size: e.target.value,
      page_no: 1,
    });
    setCurrentPage(1);
  };
  // Search FIlter
  const handleSearch = (name) => {
    setDoctorQuery({ ...DoctorQuery, Doctor_name: name });
    setCurrentPage(1);
  };
  const handleSearchChange = (e) => {
    if (e.target.value === "" || e.target.value === null) {
      setDoctorQuery({ ...DoctorQuery, Doctor_name: "" });
      setCurrentPage(1);
    }
    if (e.key === "Enter") {
      setDoctorQuery({
        ...DoctorQuery,
        Doctor_name: e.target.value,
      });
      setCurrentPage(1);
    }
  };

  const handleEditBranch = (ID) => {
    setDoctorEdit(true);
  };

  const handleDeleteBranch = (ID) => {};

  useEffect(() => {
    setDoctorQuery((DoctorQuery) => ({
      ...DoctorQuery,
      page_no: currentPage,
    }));
  }, [currentPage]);

  /* --- Handle Pagination end--- */
  useEffect(() => {
    Get_Doctor();
  }, [DoctorQuery, Get_Doctor]);

  useEffect(() => {
    if (Doctor_List) {
      setDoctorData(Doctor_List);
    } else {
      setDoctorData([]);
    }

    if (Doctor_List_Error) {
      setAlert({ Error: Doctor_List_Error, validator: false });
    }
  }, [Doctor_List, Doctor_List_Error]);

  return (
    <>
      <AdminPanelSidebar page={"DOCTOR"} SidebarOpener={SidebarOpener} />
      <section className="home-section">
        <AdminHeader
          handleSearch={handleSearch}
          handleSearchChange={handleSearchChange}
          SidebarOpener={SidebarOpener}
          handleSidebar={handleSidebar}
          page={"DOCTOR"}
        />

        {
          <div className="home-content">
            <div className="register_user_btn">
              <button onClick={onOpen}>ADD DOCTOR</button>
            </div>

            <RegisterDoctor
              Get_Doctor={Get_Doctor}
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
            />

            <div>
              <div className="table-responsive">
                <table
                  className="table table-striped"
                  style={{ overflowX: "scroll" }}>
                  <thead>
                    <tr>
                      <th scope="col">Doctor Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Time Start</th>
                      <th scope="col">Time End</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Doctor_List_Loading ? <h2>Loading....</h2> : ""}
                    {Doctor_List_Error && <h2>{Doctor_List_Error}</h2>}

                    {DoctorData?.map((el) => (
                      <tr key={el._id}>
                        <td scope="row">{el.Name}</td>
                        <td scope="row">{el.Phone}</td>
                        <td scope="row">{el.Email}</td>
                        <td scope="row">{el.StartTime}</td>
                        <td scope="row">{el.EndTime} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPage={Math.ceil(
                total_Doctors ? total_Doctors / DoctorQuery?.page_size : 0
              )}
              total_record={total_Doctors}
              handlePaginationPage={handlePaginationPage}
              handlePageSize={handlePageSize}
            />
          </div>
        }
      </section>
    </>
  );
}
