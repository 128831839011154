import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import axiosConfig from "../Core/axiosConfig";

export default function PrivateRoute({ children }) {
  const { accessToken, userDet } = useSelector(
    (state) => state.LOGIN_USER_REDUCER
  );
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/admin/login" state={location.pathname} replace />;
  }

  axiosConfig.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${accessToken}`;
  return children;
}
